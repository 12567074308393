import cn from 'classnames';

import { memo } from 'react';
import { useSelector } from 'react-redux';

import { getCitiesName } from '../../../lib/getCitiesName';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import styles from './CitiesName.module.scss';

interface CitiesNameProps {
	className?: string;
	back?: boolean;
}

export const CitiesName = memo((props: CitiesNameProps) => {
	const { className, back = false } = props;

	const list = useSelector(getInitialOffers);

	if (!list || !list.length) {
		return null;
	}

	const { cityArrival, cityDeparture } = getCitiesName(list[0]);

	return (
		<p className={cn(styles.cities, className)}>
			{back ? `${cityArrival} - ${cityDeparture}` : `${cityDeparture} - ${cityArrival}`}
		</p>
	);
});
