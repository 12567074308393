// import { useEffect } from 'react';
// import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import * as Sentry from '@sentry/react';

if (!__IS_DEV__) {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DSN,
		integrations: [
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			// Sentry.reactRouterV6BrowserTracingIntegration({
			// 	useEffect,
			// 	useLocation,
			// 	useNavigationType,
			// 	createRoutesFromChildren,
			// 	matchRoutes,
			// }),
			// Sentry.replayIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: [/biletix\.(ru|kz)/],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		// replaysSessionSampleRate: 0.1,
		// replaysOnErrorSampleRate: 1.0,
	});
}
