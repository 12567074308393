import { createApi } from '@reduxjs/toolkit/query/react';
import { appSettings } from '@shared/appSettings';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

import { IExtraSeat } from '../model/types/i.extra-seat';

export const extraSeatApi = createApi({
	reducerPath: 'extraSeatApi',
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		getExtraSeat: build.query<IExtraSeat, { session_token: string }>({
			query: ({ session_token }) => {
				return {
					url: `/get_seat_map/`,
					params: {
						newbackend: 1,
						session_token,
						altDomain: appSettings.appAltDomain,
						date: new Date().getMilliseconds(),
					},
				};
			},
		}),
	}),
});
export const { useGetExtraSeatQuery } = extraSeatApi;
