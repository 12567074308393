import { IOffer, ITrip } from '@entities/Offer';

import { IEndpointsFilter, TEndpoint } from '../../../model/slices/filters/endpointsSlice/endpointsSlice';
import { timeToSecs } from '../../timeToSecs';

export const filterEndpoints = (offer: IOffer, filter: IEndpointsFilter) => {
	const {
		current: { back, to },
	} = filter;

	if (!to) {
		return true;
	}

	const passTo = filterDirection(to, offer.grouped_trips[0].trips);

	if (!back) {
		return passTo;
	}

	const passBack = filterDirection(back, offer.grouped_trips[1].trips);

	return passTo && passBack;
};

const filterDirection = (direction: TEndpoint, trips: ITrip[]) => {
	for (const trip of trips) {
		const timeDepartureTo = timeToSecs(trip.segments[0].departure_time);
		const timeArrivalTo = timeToSecs(trip.segments.at(-1)?.arrival_time ?? '');

		if (
			timeDepartureTo >= direction.departure[0] &&
			timeDepartureTo <= direction.departure[1] &&
			timeArrivalTo >= direction.arrival[0] &&
			timeArrivalTo <= direction.arrival[1]
		) {
			return true;
		}
	}
	return false;
};
