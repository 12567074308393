import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { slantWords } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';
import IconSvg from '@shared/ui/IconSvg/IconSvg';

import { getFilteredOffers, getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { offersListActions } from '../../../model/slices/offerListSlice';
import styles from './NotOffersFilters.module.scss';
import SettingIcon from './setting.svg?react';

export const NotOffersFilters = memo(() => {
	const filteredOffers = useSelector(getFilteredOffers);
	const initialOffers = useSelector(getInitialOffers);
	const { t } = useTranslation('filters');

	const dispatch = useAppDispatch();
	const onChange = useCallback(() => dispatch(offersListActions.resetFilters()), [dispatch]);

	if (!initialOffers || !filteredOffers) {
		return null;
	}

	if (filteredOffers.length > 0) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			<IconSvg Svg={SettingIcon} className={styles.icon} />
			<h2>
				{t('naideno-k-sozhaleniyu-oni-ne-sootvetstvuyut-nastroennym-filtram', {
					number: initialOffers.length,
					variant: slantWords(initialOffers.length, t('variant', { returnObjects: true })),
				})}
			</h2>
			<p>{t('pozhaluista-sbroste-filtry-ili-nastroite-ikh-inache')}</p>
			<Button onClick={onChange} className={styles.btn}>
				{t('sbrosit-filtry')}
			</Button>
		</div>
	);
});

NotOffersFilters.displayName = 'NotOffersFilters';
