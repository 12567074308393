import { format } from 'date-fns';

import { useCallback } from 'react';

import { actionpayStartSearch, setDataLayerSearch, setDataLayerSuccessSearch } from '@app/analytics';
import { type ISearchForm } from '@entities/Search';
import { appSettings } from '@shared/appSettings';
import { isActionpayCookie } from '@shared/const';
import { SESSION_STORAGE_TOKEN } from '@shared/const/localstorage';
import { useLocale } from '@shared/hooks/useLocale';

import { useLazyGetOffersQuery } from '../api/offersApi';

export const useLazyGetOffers = () => {
	const lang = useLocale();
	const [getOffers, { data, isFetching, isError, isUninitialized }] = useLazyGetOffersQuery();

	const getLazyOffers = useCallback(
		async (searchData: ISearchForm) => {
			const { from, to, date_from, date_to, service_class, adults, children, infants } = searchData;
			setDataLayerSearch();
			const response = await getOffers({
				from: from?.split(',')[1]?.trim() ?? '',
				to: to?.split(',')[1]?.trim() ?? '',
				dep_date: format(date_from, 'yyyy-MM-dd'),
				ret_date: date_to ? format(date_to, 'yyyy-MM-dd') : '',
				service_class: service_class,
				direct: date_to ? 'Y' : 'N',
				adults: adults ? adults : 1,
				children: children ? children : 0,
				infants: infants ? infants : 0,
				lang: lang,
				currency: appSettings.appCurrency,
				altDomain: appSettings.appAltDomain,
			});

			if (response.data?.session_token) {
				sessionStorage.setItem(SESSION_STORAGE_TOKEN, response.data.session_token);
				setDataLayerSuccessSearch(response.data);

				if (isActionpayCookie) {
					actionpayStartSearch();
				}
			}

			return response;
		},
		[getOffers, lang],
	);
	return { getLazyOffers, data, isFetching, isError, isUninitialized };
};
