import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

export interface IBestPricesItem {
	date: string;
	link: string;
	stub: boolean;
	price: string;
	priceRaw: number;
	best: boolean;
}

export interface IBestPrices {
	[key: string]: IBestPricesItem;
}

export type DirectionType = 'RT' | 'OW';

export interface IGetCitiesParams {
	from: string;
	to: string;
	numberDay: number;
	startDateFrom: string;
	startDateTo?: string;
	type: DirectionType;
}

export interface IGetCitiesResponse {
	price: string;
	best: string;
}

export const searchDatePickerApi = createApi({
	reducerPath: 'searchDatePickerApi',
	tagTypes: [''],
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		// getBestPrice: build.query< , IGetCitiesParams>({
		// TODO не смог типизировать IBestPrices
		getBestPrice: build.query<IGetCitiesResponse[], IGetCitiesParams>({
			query: ({ from, to, numberDay, startDateFrom, startDateTo, type }) => {
				return {
					method: 'POST',
					url: `/ac/GetBestPrices`,
					body: {
						From: from,
						To: to,
						N: numberDay,
						StartDateFrom: startDateFrom,
						StartDateTo: startDateTo,
						Type: type,
					},
					responseHandler: async (response) => {
						const result = await response.json();
						function assignBy(key: string) {
							return (data: IBestPrices, item: IBestPricesItem) => {
								if (item.price !== '-') {
									data[item[key as 'date']] = item;
								}
								return data;
							};
						}

						const optimizedData = result?.Results?.reduce(assignBy('date'), {});
						return optimizedData;
					},
				};
			},
		}),
	}),
});

export const { useLazyGetBestPriceQuery } = searchDatePickerApi;
