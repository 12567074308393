import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SESSION_STORAGE_EXTRA_BAGGAGE } from '@shared/const/localstorage';

export interface IBaggage {
	id: string;
	count: number;
	passenger_id: null;
	segment_id: null;
	price: number[];
	applicability_passengers: string[];
	applicability_segments: string[];
}

export interface IExtraBaggageState {
	extraBaggage: IBaggage[];
	extraBaggagePrice: number;
}

const initialState: IExtraBaggageState = {
	extraBaggage: [],
	extraBaggagePrice: 0,
};

export const extraBaggageSlice = createSlice({
	name: 'extraBaggage',
	initialState,
	reducers: {
		initExtraBaggage: (state, action: PayloadAction<IExtraBaggageState>) => {
			const { extraBaggage, extraBaggagePrice } = action.payload;
			state.extraBaggage = extraBaggage;
			state.extraBaggagePrice = extraBaggagePrice;
		},
		setExtraBaggage: (state, action: PayloadAction<IBaggage>) => {
			state.extraBaggage.push(action.payload);
			state.extraBaggagePrice = state.extraBaggage.reduce((acc, cur) => acc + cur.price[0], 0);
			sessionStorage.setItem(
				SESSION_STORAGE_EXTRA_BAGGAGE,
				JSON.stringify({ extraBaggage: state.extraBaggage, extraBaggagePrice: state.extraBaggagePrice }),
			);
		},
		deleteExtraBaggage: (state, action: PayloadAction<number>) => {
			state.extraBaggage.splice(action.payload, 1);
			state.extraBaggagePrice = state.extraBaggage.reduce((acc, cur) => acc + cur.price[0], 0);
			sessionStorage.setItem(
				SESSION_STORAGE_EXTRA_BAGGAGE,
				JSON.stringify({ extraBaggage: state.extraBaggage, extraBaggagePrice: state.extraBaggagePrice }),
			);
		},
		resetExtraBaggage: (state) => {
			state.extraBaggage = [];
			state.extraBaggagePrice = 0;
			sessionStorage.removeItem(SESSION_STORAGE_EXTRA_BAGGAGE);
		},
	},
});

export const { reducer: extraBaggageReducer, actions: extraBaggageActions } = extraBaggageSlice;
