import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SESSION_STORAGE_EXTRA_SEAT } from '@shared/const/localstorage';

export interface ISeat {
	id: string;
	count: number;
	passenger_id: null;
	segment_id: null;
	freetext: string;
	applicability_passengers: string[];
	applicability_segments: string[];
	price: number[];
}

export interface IExtraSeatState {
	extraSeat: ISeat[];
	extraSeatPrice: number;
}

const initialState: IExtraSeatState = {
	extraSeat: [],
	extraSeatPrice: 0,
};

export const extraSeatSlice = createSlice({
	name: 'extraSeat',
	initialState,
	reducers: {
		initExtraSeat: (state, action: PayloadAction<IExtraSeatState>) => {
			const { extraSeat, extraSeatPrice } = action.payload;
			state.extraSeat = extraSeat;
			state.extraSeatPrice = extraSeatPrice;
		},
		setExtraSeat: (state, action: PayloadAction<ISeat>) => {
			state.extraSeat.push(action.payload);
			state.extraSeatPrice = state.extraSeat.reduce((acc, cur) => acc + cur.price[0], 0);
		},
		deleteExtraSeat: (state, action: PayloadAction<number>) => {
			state.extraSeat.splice(action.payload, 1);
			state.extraSeatPrice = state.extraSeat.reduce((acc, cur) => acc + cur.price[0], 0);
		},
		resetExtraSeat: (state) => {
			state.extraSeat = [];
			state.extraSeatPrice = 0;
			sessionStorage.removeItem(SESSION_STORAGE_EXTRA_SEAT);
		},
	},
});

export const { reducer: extraSeatReducer, actions: extraSeatActions } = extraSeatSlice;
