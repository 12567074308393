import { IOffer, ITrip } from '@entities/Offer';

import { IDurationFilter } from '../../../model/slices/filters/durationSlice/durationSlice';

export const filterDuration = (offer: IOffer, { currentDuration }: IDurationFilter) => {
	const { to, back } = currentDuration;

	if (!to) {
		return true;
	}

	const passTo = filterDirection(offer.grouped_trips[0].trips, to);

	if (!back) {
		return passTo;
	}

	const passBack = filterDirection(offer.grouped_trips[1].trips, back);

	return passTo && passBack; // TODO спросить у бизнеса как должно быть (&& или ||)
};

const filterDirection = (trips: ITrip[], duration: number) => {
	for (const trip of trips) {
		if (trip.duration <= duration) {
			return true;
		}
	}

	return false;
};
