import cn from 'classnames';

import styles from './Expand.module.scss';

interface ExpandProps {
	className?: string;
	isOpen?: boolean;
}

export const Expand = (props: ExpandProps) => {
	const { className, isOpen } = props;

	return <span className={cn(styles.expand, className, { [styles.open]: isOpen })} />;
};
