import { md5 } from 'js-md5';

import { createApi } from '@reduxjs/toolkit/query/react';
import { appSettings } from '@shared/appSettings';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';
import { SESSION_STORAGE_DEEPLINK, SESSION_STORAGE_TOKEN } from '@shared/const/localstorage';

import { ISelectOfferParams } from '../model/types/i.offer-select-params';
import { IOffersParams } from '../model/types/i.offers-params';
import { IOffersResp, ISelectOfferResp } from '../model/types/i.offers-requests';

export const offersApi = createApi({
	reducerPath: 'offersApi',
	baseQuery: dynamicBaseQuery,
	keepUnusedDataFor: 0,
	endpoints: (build) => ({
		getOffers: build.query<IOffersResp, IOffersParams>({
			query: ({ currency, from, to, dep_date, ...params }) => {
				const salt = 'HZvKasKC3rke3TWz';
				const hash = md5(`${from}${to}${dep_date}${salt}`);

				return {
					url: `/get_tariffs/`,
					params: {
						group_mode: 'grouped',
						newbackend: 1,
						date: new Date().getMilliseconds(),
						lid: appSettings.appLid,
						linked_mode: 'extended',
						session_token: null,
						only_direct: 'N',
						currentCurrency: currency,
						from,
						to,
						dep_date,
						hash,
						...params,
					},
				};
			},
		}),

		selectOffer: build.query<ISelectOfferResp, ISelectOfferParams>({
			query: ({ message, ...params }) => {
				const deeplink = sessionStorage.getItem(SESSION_STORAGE_DEEPLINK) ?? undefined;

				const config = {
					altDomain: appSettings.appAltDomain,
					lid: appSettings.appLid,
					currentCurrency: appSettings.appCurrency,
				};

				const deeplinkQuery = {
					newbackend: 1,
					date: new Date().getMilliseconds(),
					deeplink: `http://api.biletix.ru/booking/?newbackend=1&${message || deeplink}`,
					...config,
				};

				const baseQuery = {
					newbackend: 1,
					date: new Date().getMilliseconds(),
					session_token: sessionStorage.getItem(SESSION_STORAGE_TOKEN),
					...config,
					...params,
				};

				const getParamsQuery = () => {
					// Если message имеет данные, пользователь перешел по deeplink'у
					// ИЛИ если message отсутствует, но deeplink храниться в sessionStorage
					// значит пользователь вернулся с шага оплаты и ему необходимо загрузить
					// данные рейса используя deeplink(т.к. в данном случае offer_id отсутствует)
					if (message || deeplink) {
						// Если message и flightToId имеют данные, то пользователь
						// выбрал другой тариф который был доступен в рейсе
						// загруженный по deeplink'у
						if (params.offer_id1) {
							return baseQuery;
						}

						return deeplinkQuery;
					}

					// Если message НЕ имеет данные, пользователь перешел c шага 2 или 4
					return baseQuery;
				};

				return {
					url: `/select_offer/`,
					params: getParamsQuery(),
				};
			},
		}),
	}),
});

export const { useGetOffersQuery, useLazyGetOffersQuery, useLazySelectOfferQuery, useSelectOfferQuery } = offersApi;
