interface HandLuggageIconProps {
	number?: number | null;
}

export const HandLuggageIcon = (props: HandLuggageIconProps) => (
	<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<text x='50%' y='65%'>
			{props.number ? props.number : ''}
		</text>
		<rect x='4.5' y='0.5' width='5' height='3' rx='1.5' stroke='#394753' />
		<rect x='0.5' y='3.5' width='13' height='10' rx='1.5' stroke='#394753' />
	</svg>
);
