import cn from 'classnames';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CloseIcon from '@shared/assets/icons/close.svg?react';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import Button from '@shared/ui/Button/Button';
import IconSvg from '@shared/ui/IconSvg/IconSvg';

import { getLowerPriceByBaggage } from '../../../lib/filters/baggage/getLowerPrices';
import { getHotBaggage } from '../../../model/selectors/hotSelectors';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { baggageActions } from '../../../model/slices/filters/baggageSlice/baggageSlice';
import { hotActions } from '../../../model/slices/filters/hotSlice/hotSlice';
import styles from '../HotFilters.module.scss';
import BaggageIcon from './baggage.svg?react';

export const HotBaggageFilter = () => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();
	const isHotBaggage = useSelector(getHotBaggage);
	const initialOffers = useSelector(getInitialOffers);

	const lowerPriceWithBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: true, without: false });
	}, [initialOffers]);

	const lowerPriceWithoutBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: false, without: true });
	}, [initialOffers]);

	const onChange = () => {
		dispatch(baggageActions.reset());

		if (!isHotBaggage) {
			dispatch(baggageActions.toggleValue('without'));
		}

		dispatch(hotActions.toggleBaggage());
	};

	if (!lowerPriceWithBaggage || !lowerPriceWithoutBaggage) {
		return <></>;
	}

	return (
		<>
			<Button
				onClick={onChange}
				icon={BaggageIcon}
				className={cn(styles.button, {
					[styles.active]: isHotBaggage,
				})}
				color='c5'
				block
				size={isMobileAndTablet ? 'size_s' : 'size_m'}
			>
				{t('s-bagazhom')}
				{isHotBaggage && <IconSvg Svg={CloseIcon} className={styles.close} />}
			</Button>
		</>
	);
};
