import { memo } from 'react';

import { getPenaltyStatus } from '@shared/lib/helpers';
import { WithTooltip } from '@shared/ui/WithTooltip/WithTooltip';

import { IPenalty } from '../../lib/getPenalty';
import styles from './Penalty.module.scss';

interface PenaltyProps {
	className?: string;
	refundBefore: string;
	penalty: IPenalty;
}

export const Penalty = memo((props: PenaltyProps) => {
	const { penalty, refundBefore } = props;

	if (!refundBefore && (!penalty || !penalty[0])) {
		return null;
	}

	const status = getPenaltyStatus(refundBefore || penalty?.[0]);

	if (penalty?.[0] === 'charter' && penalty?.[1]) {
		return (
			<span className={styles.penalty}>
				<WithTooltip classNameTip={styles.tooltip} tip={penalty[1]}>
					{status}
				</WithTooltip>
			</span>
		);
	} else {
		return <span className={styles.penalty}>{status}</span>;
	}
});

Penalty.displayName = 'Penalty';
