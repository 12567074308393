import { useEffect, useRef } from 'react';

import { isBiletixKZ } from '@shared/const';

import { actionpay } from '../model/actionpay/actionpay';
import { cityadsMainTRM } from '../model/cityads/cityadsMainTRM';
import { ga } from '../model/google/ga';

export const Analytics = () => {
	const isMounted = useRef(false);

	useEffect(() => {
		if (!isMounted.current) {
			// Подключение googletagmanager
			ga();

			// Подключение cityads TRM
			cityadsMainTRM();

			// Подключение actionpay
			actionpay();
		}

		isMounted.current = true;
	}, []);

	return (
		<>
			{/* Пиксель cityads */}
			{/* Подключение script для TPM cityads */}
			<div
				dangerouslySetInnerHTML={{
					__html: `
			<script id='xcntmyAsync' type='text/javascript'></script>`,
				}}
			/>

			{/* Аналитика google */}
			<div
				dangerouslySetInnerHTML={{
					__html: `
					
			<noscript>
				<iframe
					src='https://www.googletagmanager.com/ns.html?id=${isBiletixKZ ? 'GTM-5KZ5PM8' : 'GTM-5CFXH2'}'
					height='0'
					width='0'
					style='display:none;visibility:hidden'
				></iframe>
			</noscript>`,
				}}
			/>
		</>
	);
};
