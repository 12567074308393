import cn from 'classnames';
import { isEmpty } from 'lodash';

import { memo, useDeferredValue, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { IOffer, Offer, ReturnLazyGetSelectedOffer } from '@entities/Offer';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';

import { useFilterOffer } from '../../../OffersList/hooks/useFilterOffer';
import { useSetInitialFilters } from '../../hooks/useSetInitialFilters';
import {
	getFilteredOffers,
	getFilters,
	getInitialOffers,
	getVisibleOffersCount,
} from '../../model/selectors/offersListSelectors';
import { offersListActions } from '../../model/slices/offerListSlice';
import { Filters } from '../Filters/Filters/Filters';
import { NotOffersFilters } from '../Filters/NotOffersFilters/NotOffersFilters';
import { HotFilters } from '../HotFilters/HotFilters';
import { ShowMore } from '../ShowMore/ShowMore';
import styles from './OffersList.module.scss';

interface OffersListProps {
	offers: IOffer[];
	selectOffer: ReturnLazyGetSelectedOffer['getSelectOfferLazy'];
}

export const OffersList = memo((props: OffersListProps) => {
	const { offers, selectOffer } = props;
	const { isMobileAndTablet } = useCustomMediaQuery();

	const dispatch = useAppDispatch();

	const filteredOffers = useSelector(getFilteredOffers);
	const initialOffers = useSelector(getInitialOffers);
	const filters = useSelector(getFilters);
	const visibleOffersCount = useSelector(getVisibleOffersCount);

	const defferedFilteredOffers = useDeferredValue(filteredOffers);

	const { filterOffer } = useFilterOffer();
	const { setInitialFilters } = useSetInitialFilters();

	useEffect(() => {
		// Сбрасывает фильтры
		dispatch(offersListActions.resetFilters());

		// Добавляет дефолтные оферы
		dispatch(offersListActions.setInitialOffers(offers));

		// Добавляет данные для фильтров и передает их в хранилище Redux
		setInitialFilters(offers);
	}, [dispatch, offers, setInitialFilters]);

	useEffect(() => {
		// Добавляет отфильтрованные оферы
		dispatch(offersListActions.setFilteredOffers((initialOffers ?? []).filter(filterOffer)));
	}, [dispatch, filterOffer, filters, initialOffers]);

	if (!initialOffers?.length) {
		return null;
	}

	return (
		<div className={cn('container-wl', styles.wrapper)}>
			<Filters />

			<div className={cn(styles.offersList)}>
				{!isMobileAndTablet && <HotFilters />}

				{!isEmpty(defferedFilteredOffers) ? (
					<>
						{defferedFilteredOffers?.slice(0, visibleOffersCount).map((offer) => (
							<Offer key={offer.price + offer.inner_id} selectOffer={selectOffer} offer={offer} />
						))}
						<ShowMore />
					</>
				) : (
					<NotOffersFilters />
				)}
			</div>
		</div>
	);
});
