import cn from 'classnames';

import { createPortal } from 'react-dom';

import { Altdomain } from '../../../../appSettings';
import { useLocale } from '../../../../hooks/useLocale';
import { useCustomMediaQuery } from '../../../../hooks/useMediaQuery';
import { IBannerLoaderProps } from '../../BannerLoader/ui/BannerLoader';
import { DefaultBannerLoader } from '../../DefaultBannerLoader/ui/DefaultBannerLoader';
import styles from '../../DefaultBannerLoader/ui/DefaultBannerLoader.module.scss';
import { useGetBannerLoaderInfoQuery } from '../api/bannerLoaderApi';

export const DynamicBannerLoader = ({ depart, arrival, step, month, child }: IBannerLoaderProps) => {
	const lang = useLocale();
	const { isMobile } = useCustomMediaQuery();

	const typeMobile = lang === 'ru' ? 'PROGRESS_BAR_MOBILE_NEW' : `${lang.toUpperCase()}_PROGRESS_BAR_MOBILE_NEW`;
	const type = lang === 'ru' ? 'PROGRESS_BAR_NEW' : `${lang.toUpperCase()}_PROGRESS_BAR_NEW`;

	const { isFetching, data } = useGetBannerLoaderInfoQuery({
		altDomain: isMobile ? Altdomain.BILETIX_MOBILE : Altdomain.BILETIX_DESKTOP,
		depart: depart ?? '',
		arrival: arrival ?? '',
		step: step ?? '',
		month: month ?? '',
		child: child ?? '',
		type: isMobile ? typeMobile : type,
	});

	if (isFetching) {
		return (
			<>
				{createPortal(
					<div className={cn('banner', styles.wrapper)}>
						<div className={styles.banner}></div>
					</div>,
					document.body,
				)}
			</>
		);
	}
	if (!data) {
		return <DefaultBannerLoader />;
	}

	return (
		<>
			{createPortal(
				<div className={cn('banner', styles.wrapper)}>
					<div className={styles.banner}>
						{data[0]?.IMAGE_URL && (
							<>
								<img className={styles.img} src={data[0].IMAGE_URL} alt={data[0].NAME} />
								<div className={styles.progress} />
							</>
						)}
					</div>
				</div>,
				document.body,
			)}
		</>
	);
};
