import cn from 'classnames';

import { useTranslation } from 'react-i18next';

import { appSettings } from '../../appSettings';
import imgTicket from '../../assets/images/ticket.png';
import { Dropdown } from '../../ui/Dropdown/Dropdown';
import styles from './Helper.module.scss';

const Helper = (): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div className={cn('container-wl', styles.helper)}>
			<div className={styles.position}>
				<Dropdown renderTrigger={t('podderzhka')} classNameTrigger={styles.dropdown}>
					{`${t(
						'my-rabotaem-24-chasa-v-sutki-operativno-reshit-lyuboi-vopros-mozhno-cherez-goryachuyu-liniyu-sluzhby-podderzhki',
					)} ${appSettings.appSupportPhone}`}
				</Dropdown>
			</div>
			<a href='https://travel.care/' target='_blank' className={styles.link} rel='noreferrer'>
				{t('pomosh-po-zakazu')}
				<img className={styles.img} src={imgTicket} alt='' />
			</a>
		</div>
	);
};

export default Helper;
