import cn from 'classnames';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPenaltyStatus } from '@shared/lib/helpers';

import { IPenalty } from '../../lib/getPenalty';
import { ISegment } from '../../model/types/i.offer';
import styles from './SegmentInfo.module.scss';

interface SegmentInfoProps {
	className?: string;
	penalty: IPenalty;
	refundBefore: string;
	segment: ISegment;
}

export const SegmentInfo = memo((props: SegmentInfoProps) => {
	const { t } = useTranslation('filters');
	const { className, segment, penalty, refundBefore } = props;

	return (
		<div className={cn(styles.segmentInfo, className)}>
			<p className={styles.acName}>{segment.airline.name ?? segment.airline.id}</p>
			{segment.airline.name != segment.airline.oak_name && (
				<p className={styles.otherAc}>
					{t('reis-vypolnyaetsya-ak')}&nbsp; &laquo;{segment.airline.oak_name}&raquo;
				</p>
			)}
			<p className={styles.airplane}>
				{segment.airline.id} {segment.number}
				{segment.plane_type_name !== null && ','} {segment.plane_type_name}
			</p>
			{penalty && <p className={styles.penalty}>{getPenaltyStatus(refundBefore || penalty[0])}</p>}
		</div>
	);
});

SegmentInfo.displayName = 'SegmentInfo';
