import cn from 'classnames';
import { keys } from 'lodash';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { priceFormat } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';

import { getHotTransfer } from '../../../model/selectors/hotSelectors';
import { getTransfersFilter } from '../../../model/selectors/transfersSelectors';
import { hotActions } from '../../../model/slices/filters/hotSlice/hotSlice';
import { transferActions } from '../../../model/slices/filters/transferSlice/transferSlice';
import styles from '../HotFilters.module.scss';
import TransferIcon from './transfer.svg?react';

export const HotDarkTransferFilter = () => {
	const { t } = useTranslation('filters');
	const { transferCounts, prices, anyCount } = useSelector(getTransfersFilter);

	const dispatch = useAppDispatch();
	const isHotTransfer = useSelector(getHotTransfer);

	const counts = keys(transferCounts);
	const isTransfer = counts?.[0] === '0' && Number(counts?.at(-1)) >= 1;

	if (!isTransfer) {
		return null;
	}

	const onChange = () => {
		// Если anyCount выбран, то нужно сделать все фильтры не выбранными с помощью toggleAnyCount
		// и далее задать нужный фильтр с помощью toggleTransferCount
		if (anyCount) {
			dispatch(transferActions.toggleAnyCount());
			dispatch(transferActions.toggleTransferCount('0'));
		} else {
			// Если anyCount не выбран и выбран isHotTransfer
			// то нужно вернуть состояние всех фильтров в true(выбрано) с помощью reset
			// далее нужно сделать все фильтры не выбранными с помощью toggleAnyCount
			// и далее выбирать нужный фильтр toggleTransferCount
			if (!isHotTransfer) {
				dispatch(transferActions.reset());
				dispatch(transferActions.toggleAnyCount());
				dispatch(transferActions.toggleTransferCount('0'));
			} else {
				// Если anyCount не выбран ине выбран isHotTransfer
				// то нужно вернуть состояние всех фильтров в true(выбрано) с помощью reset
				dispatch(transferActions.reset());
			}
		}

		dispatch(hotActions.toggleTransfer());
	};

	return (
		<Button
			onClick={onChange}
			icon={TransferIcon}
			className={cn(styles.button, styles.scale, {
				[styles.active]: isHotTransfer,
			})}
			variant='clear'
			color='c5'
			textPosition='space-around'
			size='size_m'
		>
			<div className={styles.description}>
				<span>{t('tolko-pryamye')}</span>
				<span className={styles.price}>
					{t('price', {
						ns: 'filters',
						price: priceFormat(prices[0]),
						currency: symbolCurrency[appSettings.appCurrency],
					})}
				</span>
			</div>
		</Button>
	);
};
