import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isRt } from '@entities/Offer';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { Slider } from '@shared/ui/Slider/Slider';
import { Tabs } from '@shared/ui/Tabs/Tabs';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { useGetDurationText } from '../../../lib/filters/duration/useGetDurationText';
import { getDurationFilter } from '../../../model/selectors/durationSelectors';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { durationActions } from '../../../model/slices/filters/durationSlice/durationSlice';
import { CitiesName } from '../CitiesName/CitiesName';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { RenderTabListItem } from '../RenderTabListItem/RenderTabListItem';
import { Trigger } from '../Trigger/Trigger';
import styles from './DurationFilter.module.scss';

export const DurationFilter = memo(() => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();

	const list = useSelector(getInitialOffers);
	const { currentDuration, initialDuration } = useSelector(getDurationFilter);

	const { getDurationText } = useGetDurationText();

	if (!list || !list.length) {
		return null;
	}

	if (!currentDuration.to || !initialDuration.to) {
		return null;
	}

	const sliderTo = (
		<Slider<number>
			reversed={isMobileAndTablet}
			min={initialDuration.to[0]}
			max={initialDuration.to[1]}
			value={currentDuration.to}
			label={
				<span className={styles.duration}>
					{isMobileAndTablet && isRt(list[0]) && <CitiesName />}
					{getDurationText('to')}
				</span>
			}
			onChange={(newValue) => dispatch(durationActions.setCurrentDuration({ ...currentDuration, to: newValue }))}
		/>
	);

	const sliderBack =
		initialDuration.back && currentDuration.back ? (
			<Slider<number>
				reversed={isMobileAndTablet}
				key={'BackDuration'}
				min={initialDuration.back[0]}
				max={initialDuration.back[1]}
				value={currentDuration.back}
				label={
					<span className={styles.duration}>
						{isMobileAndTablet && <CitiesName back />}
						{getDurationText('back')}
					</span>
				}
				onChange={(newValue) =>
					dispatch(durationActions.setCurrentDuration({ ...currentDuration, back: newValue }))
				}
			/>
		) : null;

	const renderTabs = () => {
		if (!isRt(list[0])) {
			return <div className={styles.wrapper}>{sliderTo}</div>;
		}

		return (
			<Tabs
				className={styles.wrapper}
				tabListClassName={styles.tabList}
				renderTabListItem={RenderTabListItem}
				tabListItems={[t('tuda'), t('obratno')]}
				tabPanels={[sliderTo, sliderBack]}
			/>
		);
	};

	if (isMobileAndTablet) {
		return (
			<MobileFilter title={t('vremya-v-puti')}>
				<div className={styles.mobileWrapper}>
					{sliderTo}
					{sliderBack}
				</div>
			</MobileFilter>
		);
	}

	return (
		<ToggledBlock
			renderContent={renderTabs}
			renderTrigger={({ isOpen }) => <Trigger text={t('vremya-v-puti')} isOpen={isOpen} />}
			closeOtherToogle={false}
		/>
	);
});
