import { ISearchForm } from '@entities/Search';
import { isBiletixRU } from '@shared/const';
import { COOKIE_VIP_ACTIONPAY } from '@shared/const/cookie';
import { SESSION_STORAGE_SEARCH_KEY } from '@shared/const/localstorage';
import { formatDateByPatternAndLocale, getCookie } from '@shared/lib/helpers';

import { ITwiketOrderInfo } from '../types/i.twiket-order-info';

const getSearch = () => {
	const search = JSON.parse(sessionStorage.getItem(SESSION_STORAGE_SEARCH_KEY) as string) as ISearchForm;

	if (!search) {
		return null;
	}

	return {
		type: 'avia',
		class: search.service_class === 'E' ? 'economy' : 'business',
		from: search.from.split(',')[0].trim(),
		dest: search.to.split(',')[0].trim(),
		dateFrom: formatDateByPatternAndLocale(new Date(search.date_from), 'yyyy-MM-dd', 'ru'),
		dateTill: search.date_to ? formatDateByPatternAndLocale(new Date(search.date_to), 'yyyy-MM-dd', 'ru') : '',
		count: search.adults,
		countChildren: search.children,
		countBaby: search.infants,
		codeFrom: search.from.split(',')[1].trim(),
		codeDest: search.to.split(',')[1].trim(),
	};
};

export const actionpayStartSearch = () => {
	if (isBiletixRU && !__IS_DEV__) {
		const searchObj = getSearch();

		if (!searchObj) {
			console.error('Failed to get SESSION_STORAGE_SEARCH_KEY');
			return;
		}

		try {
			window.APRT_SEND({
				pageType: 3,
				searchTickets: searchObj,
			});
		} catch (error) {
			console.error('Failed to send search data to ActionPay', error);
		}
	}
};

export const actionpayFlightDetails = () => {
	if (isBiletixRU && !__IS_DEV__) {
		const searchObj = getSearch();

		if (!searchObj) {
			console.error('Failed to get SESSION_STORAGE_SEARCH_KEY');
			return;
		}

		try {
			window.APRT_SEND({
				pageType: 2,
				searchTickets: searchObj,
			});
		} catch (error) {
			console.error('Failed to send search data to ActionPay', error);
		}
	}
};

export const actionpayFinish = (orderid: string, token: string, orderInfo: ITwiketOrderInfo) => {
	if (isBiletixRU && !__IS_DEV__) {
		const searchObj = getSearch();
		const cookieActionpay = getCookie(COOKIE_VIP_ACTIONPAY);
		const price = orderInfo.ORDER_ARRAY.PRICE;
		const currency = orderInfo.ORDER_ARRAY.CURRENCY;

		if (!cookieActionpay) {
			console.error('Failed to get cookie COOKIE_VIP_ACTIONPAY');
			return;
		}

		try {
			window.APRT_SEND({
				pageType: 6,
				searchTickets: searchObj,
				orderInfo: {
					id: orderid,
					price: parseInt(price),
				},
				purchasedProducts: {
					id: orderid,
					price: parseInt(price),
					quantity: currency,
				},
			});
		} catch (error) {
			console.error('Failed to send search data to ActionPay', error);
		}

		fetch(`https://apypp.com/ok/7776.png?actionpay=${cookieActionpay}&apid=${orderid}&price=${price}`).catch((e) =>
			console.log(e),
		);

		fetch(
			`https://partners.biletix.ru/api/set/order_owners/${orderid}/${token}/${orderid}/actionpay/${cookieActionpay}`,
		).catch((e) => console.log(e));
	}
};
