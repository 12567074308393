import { offersApi } from '@entities/Offer';
import { orderServicesReducer, orderServicesSlice } from '@entities/OrderServices';
import { paymentApi } from '@entities/Payment';
import {
	extraBaggageReducer,
	extraBaggageSlice,
	extraSeatApi,
	extraSeatReducer,
	extraSeatSlice,
	orderApi,
} from '@entities/Personal';
import { promocodeApi, promocodeReducer, promocodeSlice } from '@entities/Promocode';
import { searchDatePickerApi, searchDirectionApi } from '@entities/Search';
import { popupErrorReducer, popupErrorSlice } from '@features/PopupError';
import { CombinedState, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { bannerLoaderApi } from '@shared/ui/BannerLoader';
import { finishApi } from '@steps/FinishStep';
import { cartReducer, cartSlice } from '@widgets/Cart';
import { filtersReducer, offersListReducer, offersListSlice } from '@widgets/OffersList';

import { IStateSchema } from './i.stateSchema';

export const createReduxStore = (initialState?: IStateSchema) => {
	const reducer: Reducer<CombinedState<IStateSchema>> = combineReducers({
		filters: filtersReducer,
		[extraSeatSlice.name]: extraSeatReducer,
		[extraBaggageSlice.name]: extraBaggageReducer,
		[orderServicesSlice.name]: orderServicesReducer,
		[offersListSlice.name]: offersListReducer,
		[popupErrorSlice.name]: popupErrorReducer,
		[cartSlice.name]: cartReducer,
		[promocodeSlice.name]: promocodeReducer,
		[searchDirectionApi.reducerPath]: searchDirectionApi.reducer,
		[searchDatePickerApi.reducerPath]: searchDatePickerApi.reducer,
		[bannerLoaderApi.reducerPath]: bannerLoaderApi.reducer,
		[offersApi.reducerPath]: offersApi.reducer,
		[extraSeatApi.reducerPath]: extraSeatApi.reducer,
		[orderApi.reducerPath]: orderApi.reducer,
		[paymentApi.reducerPath]: paymentApi.reducer,
		[promocodeApi.reducerPath]: promocodeApi.reducer,
		[finishApi.reducerPath]: finishApi.reducer,
	});

	const store = configureStore({
		reducer,
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware().concat(
				searchDirectionApi.middleware,
				searchDatePickerApi.middleware,
				bannerLoaderApi.middleware,
				offersApi.middleware,
				extraSeatApi.middleware,
				orderApi.middleware,
				paymentApi.middleware,
				promocodeApi.middleware,
				finishApi.middleware,
			),
		// DevTools browser extension.
		devTools: Boolean(__IS_DEV__),
		preloadedState: initialState,
	});

	return store;
};

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
