import { createApi } from '@reduxjs/toolkit/query/react';
import { appSettings } from '@shared/appSettings';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

export interface IGetOrderStatusResp {
	ok: boolean;
	order_id: string;
	pdf_link: string;
	session_token: string;
	error: Error;
}

export interface Error {
	code: string;
	description: string;
	data: unknown;
}
export interface IGetOrderParams {
	orderId: string;
}

export const finishApi = createApi({
	reducerPath: 'finishApi',
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		getOrderStatus: build.query<IGetOrderStatusResp, IGetOrderParams>({
			query: ({ orderId }) => {
				return {
					url: `/order_ticketing/${orderId}/`,
					params: {
						date: new Date().getMilliseconds(),
						newbackend: 1,
						altDomain: appSettings.appAltDomain,
						currentCurrency: appSettings.appCurrency,
						userTicket: '',
						lid: appSettings.appLid,
					},
				};
			},
		}),
	}),
});

export const { useGetOrderStatusQuery, useLazyGetOrderStatusQuery } = finishApi;
