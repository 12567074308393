import { BILETIX_KZ_URL, FUTURA_TRAVEL_URL } from '../../const/api';
import { SESSION_STORAGE_TEST_ALTDOMAIN } from '../../const/localstorage';
import { useLocale } from '../../hooks/useLocale';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { appSettings } from '../appSettings';
import { Altdomain, Lid } from '../model/types/i.altdomain';

export const useAppSettings = (): void => {
	const { isMobile } = useCustomMediaQuery();
	const lang = useLocale();

	if (__IS_DEV__) {
		// 	// const testLid = sessionStorage.getItem(SESSION_STORAGE_TEST_LID) as Lid;
		const testAltdomain = sessionStorage.getItem(SESSION_STORAGE_TEST_ALTDOMAIN) as Altdomain;

		// 	// Если в sessionStorage есть значение lid или altDomain, то устанавливаем их
		if (testAltdomain) {
			// appSettings.appLid = testLid;
			appSettings.appAltDomain = testAltdomain;
		}
	}

	switch (appSettings.appAltDomain) {
		// аэропорты
		case Altdomain.SVX_AERO:
			appSettings.appLid = Lid.SVX_AERO;
			break;

		case Altdomain.GSV_AERO:
			appSettings.appLid = Lid.GSV_AERO;
			break;

		case Altdomain.SAMARA_RU:
			appSettings.appLid = Lid.SAMARA_RU;
			break;

		case Altdomain.GOJ_AERO:
			appSettings.appLid = Lid.GOJ_AERO;
			break;

		case Altdomain.OREN_AERO:
			appSettings.appLid = Lid.OREN_AERO;
			break;

		case Altdomain.NUX_AERO:
			appSettings.appLid = Lid.NUX_AERO;
			break;

		case Altdomain.RND_RU:
			appSettings.appLid = Lid.RND_RU;
			break;

		// biletix
		case Altdomain.FUTURA_TRAVEL:
			appSettings.appLid = Lid.FUTURA_TRAVEL;
			appSettings.appHost = FUTURA_TRAVEL_URL;
			break;

		case Altdomain.BILETIX_KZ_DESKTOP:
		case Altdomain.BILETIX_KZ_MOBILE:
			if (isMobile) {
				appSettings.appAltDomain = Altdomain.BILETIX_KZ_MOBILE;
				appSettings.appLid = Lid.BILETIX_KZ_MOBILE;
			} else {
				appSettings.appAltDomain = Altdomain.BILETIX_KZ_DESKTOP;
				appSettings.appLid = Lid.BILETIX_KZ_DESKTOP;
			}

			appSettings.appHost = BILETIX_KZ_URL;
			break;

		// Проверка для сайта biletix.ru
		default:
			if (lang === 'ru') {
				if (isMobile) {
					appSettings.appAltDomain = Altdomain.BILETIX_MOBILE;
					appSettings.appLid = Lid.BILETIX_MOBILE;
				} else {
					appSettings.appAltDomain = Altdomain.BILETIX_DESKTOP;
					appSettings.appLid = Lid.BILETIX_DESKTOP;
				}

				break;
			} else if (lang === 'en') {
				if (isMobile) {
					appSettings.appAltDomain = Altdomain.BILETIX_EN_MOBILE;
					appSettings.appLid = Lid.BILETIX_EN_MOBILE;
				} else {
					appSettings.appAltDomain = Altdomain.BILETIX_EN_DESKTOP;
					appSettings.appLid = Lid.BILETIX_EN_DESKTOP;
				}

				break;
			} else if (lang === 'de') {
				if (isMobile) {
					appSettings.appAltDomain = Altdomain.BILETIX_DE_MOBILE;
					appSettings.appLid = Lid.BILETIX_DE_MOBILE;
				} else {
					appSettings.appAltDomain = Altdomain.BILETIX_DE_DESKTOP;
					appSettings.appLid = Lid.BILETIX_DE_DESKTOP;
				}

				break;
			} else if (lang === 'zh') {
				if (isMobile) {
					appSettings.appAltDomain = Altdomain.BILETIX_ZH_MOBILE;
					appSettings.appLid = Lid.BILETIX_ZH_MOBILE;
				} else {
					appSettings.appAltDomain = Altdomain.BILETIX_ZH_DESKTOP;
					appSettings.appLid = Lid.BILETIX_ZH_DESKTOP;
				}

				break;
			}
			break;
	}
};
