import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { isRt } from '@entities/Offer';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { Slider } from '@shared/ui/Slider/Slider';
import { Tabs } from '@shared/ui/Tabs/Tabs';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { useGetEndpointsText } from '../../../lib/filters/endpoints/useGetEndpointsText';
import { getEndpointsFilter } from '../../../model/selectors/endpointsSelectors';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { TEndpoint, TTime, endpointsActions } from '../../../model/slices/filters/endpointsSlice/endpointsSlice';
import { CitiesName } from '../CitiesName/CitiesName';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { RenderTabListItem } from '../RenderTabListItem/RenderTabListItem';
import { Trigger } from '../Trigger/Trigger';
import styles from './EndpointsFilter.module.scss';

export const EndpointsFilter = memo(() => {
	const { isMobileAndTablet } = useCustomMediaQuery();
	const { t } = useTranslation('filters');
	const { current, initial } = useSelector(getEndpointsFilter);

	const list = useSelector(getInitialOffers);

	if (!list || !list.length) {
		return null;
	}

	if (!current.to || !initial.to) {
		return null;
	}

	const isRtOffers = isRt(list[0]);

	const sliderToDeparture = (
		<SliderEndpoint
			direction='to'
			point='departure'
			currentValue={[current.to.departure[0], current.to.departure[1]]}
			initial={{
				min: initial.to.departure[0],
				max: initial.to.departure[1],
			}}
			label={t('vylet')}
		/>
	);

	const sliderToArrival = (
		<SliderEndpoint
			direction='to'
			point='arrival'
			currentValue={[current.to.arrival[0], current.to.arrival[1]]}
			initial={{
				min: initial.to.arrival[0],
				max: initial.to.arrival[1],
			}}
			label={t('prilet')}
		/>
	);

	const sliderBackDeparture =
		initial.back && current.back ? (
			<SliderEndpoint
				direction='back'
				point='departure'
				currentValue={[current.back.departure[0], current.back.departure[1]]}
				initial={{
					min: initial.back.departure[0],
					max: initial.back.departure[1],
				}}
				label={t('vylet')}
			/>
		) : null;

	const sliderBackArrival =
		initial.back && current.back ? (
			<SliderEndpoint
				direction='back'
				point='arrival'
				currentValue={[current.back.arrival[0], current.back.arrival[1]]}
				initial={{
					min: initial.back.arrival[0],
					max: initial.back.arrival[1],
				}}
				label={t('prilet')}
			/>
		) : null;

	const citiesTo = <CitiesName />;
	const citiesBack = <CitiesName back />;

	const renderContent = () => {
		return (
			<div className={styles.wrapper}>
				<div className={styles.slider}>
					{isRtOffers && citiesTo}
					<Tabs
						tabListItems={[t('vylet'), t('prilet')]}
						tabListClassName={styles.tabList}
						tabPanels={[<>{sliderToDeparture}</>, <>{sliderToArrival}</>]}
						renderTabListItem={RenderTabListItem}
					/>
				</div>

				{initial.back && (
					<div className={styles.slider}>
						{isRtOffers && citiesBack}
						<Tabs
							tabListItems={[t('vylet'), t('prilet')]}
							tabListClassName={styles.tabList}
							tabPanels={[<>{sliderBackDeparture}</>, <>{sliderBackArrival}</>]}
							renderTabListItem={RenderTabListItem}
						/>
					</div>
				)}
			</div>
		);
	};

	if (isMobileAndTablet) {
		return (
			<MobileFilter title={t('vremya-vyleta-i-prileta')}>
				<div className={styles.wrapper}>
					<div className={styles.slider}>
						{isRtOffers && citiesTo}
						{sliderToDeparture}
						{sliderToArrival}
					</div>
					{isRtOffers && (
						<div className={styles.slider}>
							{citiesBack}
							{sliderBackDeparture}
							{sliderBackArrival}
						</div>
					)}
				</div>
			</MobileFilter>
		);
	}

	return (
		<ToggledBlock
			renderContent={renderContent}
			renderTrigger={({ isOpen }) => <Trigger text={t('vremya-vyleta-i-prileta')} isOpen={isOpen} />}
			closeOtherToogle={false}
		/>
	);
});

type SliderEndpointsProps = {
	direction: 'to' | 'back';
	point: keyof TEndpoint;
	label: string;
	initial: {
		min: number;
		max: number;
	};
	currentValue: TTime;
};

const SliderEndpoint = (props: SliderEndpointsProps) => {
	const {
		direction,
		point,
		label,
		initial: { min, max },
		currentValue,
	} = props;

	const { getEndpointsText } = useGetEndpointsText();
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();

	const onChange = (newValue: TTime) => {
		dispatch(endpointsActions.setCurrent({ direction: direction, point: point, value: newValue }));
	};

	return (
		<Slider<TTime>
			min={min}
			max={max}
			value={currentValue}
			range
			reversed={isMobileAndTablet}
			label={
				<span className={styles.range}>
					<span className={styles.point}>{label}</span>
					{getEndpointsText({ direction: direction, point: point })}
				</span>
			}
			onChange={onChange}
		/>
	);
};
