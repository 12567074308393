import i18next from 'i18next';

import { type IStateSchema } from '@app/providers/StoreProvider';
import { createSelector } from '@reduxjs/toolkit';
import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { priceFormat } from '@shared/lib/helpers';

import { IPriceFilter } from '../slices/filters/priceSlice/priceSlice';

export const getPriceFilter = (state: IStateSchema) => state.filters.price;
export const getPriceFilterString = createSelector(getPriceFilter, ({ currentPrice, initialPrice }: IPriceFilter) => {
	if (!currentPrice || !initialPrice) {
		return '';
	}

	if (currentPrice[0] === initialPrice[0] && currentPrice[1] === initialPrice[1]) {
		return i18next.t('lyubaya', { ns: 'filters' });
	}

	return `${priceFormat(currentPrice[0])} ${symbolCurrency[appSettings.appCurrency]} - ${priceFormat(
		currentPrice[1],
	)} ${symbolCurrency[appSettings.appCurrency]}`;
});
