import cn from 'classnames';

import { ButtonHTMLAttributes, ReactNode, memo } from 'react';

import LoaderIcon from '../../assets/icons/button-loader.svg?react';
import IconSvg from '../IconSvg/IconSvg';
import styles from './Button.module.scss';

type ButtonVariant = 'clear' | 'outline' | 'background' | 'disabled';
type ButtonColor = 'c1' | 'c2' | 'c3' | 'c4' | 'c5' | 'green' | 'pink';

type ButtonSize = 'size_s' | 'size_m' | 'size_l';
type ButtonType = 'button' | 'submit' | 'reset';
type ButtonTextPosition = 'left' | 'center' | 'right' | 'space-around';

// type ButtonColorBackground = ButtonCommonProps & { variant: 'background'; color: 'c1' | 'c2' | 'c3' | 'c4' | 'c5' };
// type ButtonColorOutline = ButtonCommonProps & { variant: 'outline'; color: 'c1' };
// type ButtonColorDisabled = ButtonCommonProps & { variant: 'disabled'; color: never };
// type ButtonColorClear = ButtonCommonProps & { variant: 'clear'; color: never };

// type ButtonProps = ButtonColorBackground | ButtonColorOutline | ButtonColorDisabled | ButtonColorClear;

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	variant?: ButtonVariant;
	color?: ButtonColor;
	textPosition?: ButtonTextPosition;
	block?: boolean;
	size?: ButtonSize;
	disabled?: boolean;
	children?: ReactNode;
	type?: ButtonType;
	icon?: React.FC<React.SVGProps<SVGSVGElement>>;
	loading?: boolean;
	arrow?: boolean;
}

const Button = memo((props: ButtonProps) => {
	const {
		className,
		children,
		variant = 'background',
		type = 'button',
		textPosition = 'left',
		disabled,
		color = 'c1',
		size = 'size_m',
		icon,
		block,
		loading = false,
		arrow = false,
		...otherProps
	} = props;

	return (
		<button
			type={type}
			className={cn(styles.button, className, {
				[styles.loading]: loading,
				[styles.block]: block,
				[styles[textPosition]]: true,
				[styles[variant]]: true,
				[styles[color]]: true,
				[styles[size]]: true,
				[styles.arrow]: arrow,
			})}
			disabled={disabled || loading}
			{...otherProps}
		>
			{icon && <IconSvg className={styles.icon} Svg={icon} />}
			{children}
			{loading && <IconSvg className={styles.iconLoading} Svg={LoaderIcon} />}
		</button>
	);
});

export default Button;
