import { type serviceClassType } from '@entities/Offer';

export enum SearchFormType {
	// City
	FROM = 'from',
	TO = 'to',
	// Date
	DATE_FROM = 'date_from',
	DATE_TO = 'date_to',
	// Passengers
	PASSENGERS = 'passengers',
	ADULT = 'adults',
	CHILD = 'children',
	INFANT = 'infants',
	//  Service class
	SERVICE_CLASS = 'service_class',
}

export interface ISearchForm {
	from: string;
	to: string;
	date_from: Date;
	date_to?: Date | null;
	// passengers: number;
	adults: number;
	children: number;
	infants: number;
	service_class: serviceClassType;
	avia_company?: string;
	flight?: string;
	transfers?: string;
	company_client?: string;
}

export interface ISearchParams {
	from?: string;
	to?: string;
	iata_from?: string;
	iata_to?: string;
	date_from?: string;
	date_to?: string;
	// passengers?: string;
	adults?: string;
	children?: string;
	infants?: string;
	service_class?: string;
	avia_company?: string;
	flight?: string;
	transfers?: string;
	company_client?: string;
}
