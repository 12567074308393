import cn from 'classnames';
import { format } from 'date-fns';
import { de, enUS, ru, zhCN } from 'date-fns/locale';
import styled from 'styled-components';

import { ForwardedRef, forwardRef } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @conarti/feature-sliced/layers-slices
import { SearchFormType } from '@entities/Search';

import CloseIcon from '../../assets/icons/close.svg?react';
import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import { ThemeFieldType } from '../../types';
import Button from '../Button/Button';
import { TextField } from '../TextField/TextField';
import styles from './DatePickerField.module.scss';

interface IDatePickerFieldProps {
	onCalendarChange: (date: Date, event: React.SyntheticEvent) => void;
	onCalendarReset: () => void;
	onCalendarClose: () => void;
	renderDayContents: (day: number, date: Date) => JSX.Element;
	startDate?: Date;
	endDate?: Date | null;
	minDate?: Date;
	selectsEnd?: boolean;
	isClearable?: boolean;
	onFocus: () => void;
	label: string;
	name: SearchFormType.DATE_FROM | SearchFormType.DATE_TO;
	icon?: React.FC<React.SVGProps<SVGSVGElement>> | undefined;
	locale: string;
	isComponentVisible: boolean;
	theme?: ThemeFieldType;
}

interface IMyContainer {
	children: React.ReactNode;
}

const Calendar = styled.div`
	.react-datepicker__header {
		background-color: #fff;
		border-radius: 0;
		border: 0;
		display: flex;
		flex-direction: column;
		height: 100%;
		gap: 10px;
	}

	.react-datepicker__month-container {
		width: 100%;
		padding: 0;
	}

	.react-datepicker__current-month {
		font: var(--font-m);
		font-weight: 500;
		text-transform: uppercase;

		color: var(--text-month-color);
	}

	.react-datepicker__day-names {
		display: flex;
		justify-content: space-around;
	}

	.react-datepicker__week {
		width: 100%;
		display: flex;
	}

	.react-datepicker__day {
		width: 55px;
		height: 55px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 0;
		font: var(--font-xs);
		font-weight: 700;
		margin: 0;
		position: relative;

		&:hover {
			border-radius: 0;
		}

		.wl-datepicker-price {
			font-weight: 500;
			font: var(--font-xxs);
			position: absolute;
			bottom: 5px;
		}
	}

	.react-datepicker__day--selected {
		background-color: var(--btn-date-start);
		border-radius: 0;

		.wl-datepicker-price {
			color: #fff;
		}

		&:hover {
			background-color: var(--btn-date-start);
			border-radius: 0;
		}
	}

	// Диапазон между start и end при выборе
	.react-datepicker__day--in-selecting-range {
		background-color: #f3f3f3;
		color: #aeb4bb;
	}

	// Выбранная дата start при выборе обратной даты
	.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
		background-color: var(--btn-date-start);
		color: #fff;

		&:hover {
			background-color: var(--btn-date-start);
		}
	}

	// Недоступные даты
	.react-datepicker__day--disabled {
		&:hover {
			background-color: transparent;
		}

		.wl-datepicker-price {
			display: none;
		}
	}

	// Диапазон между start и end
	.react-datepicker__day--in-range {
		background-color: #f3f3f3;
		color: #818181;

		&:hover {
			opacity: 0.9;
		}
	}

	.react-datepicker__day--range-end:not(.react-datepicker__day--disabled) {
		background-color: var(--btn-date-selected);
		color: #fff;

		&:hover {
			background-color: var(--btn-date-selected);
		}
	}

	.react-datepicker__day--range-start:not(.react-datepicker__day--disabled) {
		background-color: var(--btn-date-start);
		color: #fff;

		&:hover {
			background-color: var(--btn-date-start);
			color: #fff;
		}
	}

	.react-datepicker__navigation {
		top: 0;
		width: 40px;
		height: 40px;

		&:hover {
			border: 1px solid var(--btn-navigation-border);
			background-color: var(--btn-navigation-bg);
			opacity: 0.9;

			*:before {
				border-top: 7px solid transparent;
				border-bottom: 7px solid transparent;
				border-left: 7px solid var(--btn-navigation-icon);
			}
		}
	}

	.react-datepicker__navigation--next {
		right: 15px;
	}

	.react-datepicker__navigation--previous {
		left: 15px;
	}

	.react-datepicker__navigation-icon {
		top: -5px;
	}

	.react-datepicker__navigation-icon::before {
		border-style: none;
		border-width: 0;
		content: '';
		display: block;
		height: 0;
		position: absolute;
		top: 10px;
		width: 0;
		left: 0;
		border-top: 7px solid transparent;
		border-bottom: 7px solid transparent;
		border-left: 7px solid var(--btn-navigation-icon);
		transform: rotate(0);
	}

	.react-datepicker__navigation-icon--previous {
		right: 2px;

		&::before {
			transform: rotate(180deg);
			right: auto;
		}
	}

	.react-datepicker__month {
		margin: 10px 0;
	}

	.react-datepicker__day-name {
		font: var(--font-m);
		font-weight: 500;
		color: var(--text-light-color);

		&:nth-of-type(6n),
		&:nth-of-type(7n) {
			color: var(--text-weekend-color);
		}
	}
	.react-datepicker__close-icon::after {
		font: var(--font-xxl);
		background-color: #fff;
		height: 24px;
		width: 24px;
		color: var(--text-black-color);
	}
`;

export const DatePickerField = forwardRef(
	(
		{
			onCalendarChange,
			onCalendarReset,
			onCalendarClose,
			renderDayContents,
			startDate,
			endDate,
			minDate,
			selectsEnd,
			isClearable,
			onFocus,
			label,
			name,
			icon,
			locale,
			isComponentVisible,
			theme,
		}: IDatePickerFieldProps,
		ref: ForwardedRef<HTMLDivElement>,
	): JSX.Element => {
		const { t } = useTranslation('search');
		const { isMobileAndTablet } = useCustomMediaQuery();
		const { control, getValues } = useFormContext();

		let localeDateFns = ru;

		switch (locale) {
			case 'ru':
				localeDateFns = ru;

				break;
			case 'en':
				localeDateFns = enUS;

				break;
			case 'de':
				localeDateFns = de;

				break;
			case 'zh':
				localeDateFns = zhCN;

				break;
			default:
				break;
		}

		const MyContainer = ({ children }: IMyContainer) => {
			return (
				<CalendarContainer
					className={cn(styles.container, {
						[styles.containerTo]: name === SearchFormType.DATE_TO,
					})}
				>
					<div className={styles.calendar}>
						{name === SearchFormType.DATE_TO && (
							<Button
								block
								textPosition='center'
								onClick={onCalendarReset}
								className={styles.btn}
								size='size_m'
							>
								{t('obratnyi-bilet-ne-nuzhen')}
							</Button>
						)}

						<div style={{ position: 'relative' }}>
							<Calendar>{children}</Calendar>
						</div>

						{isMobileAndTablet && (
							<Button block icon={CloseIcon} textPosition='center' onClick={onCalendarClose}>
								{name === SearchFormType.DATE_TO ? t('obratno') : t('data-vyleta')}
							</Button>
						)}
					</div>
				</CalendarContainer>
			);
		};

		return (
			<div
				className={cn(styles.wrapper, {
					[styles[name]]: true,
				})}
				ref={ref}
			>
				<Controller
					name={name}
					control={control}
					render={({ field: { name, value, ref, onChange } }) => {
						return (
							<TextField
								ref={ref}
								onChange={onChange}
								theme={theme}
								name={name}
								label={label}
								onFocus={onFocus}
								value={value ? format(value, 'd MMMM', { locale: localeDateFns }) : ''}
								icon={icon}
								readonly
								reset={(value && name === SearchFormType.DATE_TO) ?? false}
								onReset={onCalendarReset}
							/>
						);
					}}
				/>

				{isComponentVisible && (
					<div>
						<DatePicker
							name={name}
							locale={locale}
							selected={getValues(name)}
							onChange={onCalendarChange}
							renderDayContents={renderDayContents}
							startDate={startDate}
							endDate={endDate}
							selectsEnd={selectsEnd}
							calendarContainer={MyContainer}
							minDate={minDate}
							isClearable={isClearable}
							inline
							disabledKeyboardNavigation
						/>
					</div>
				)}
			</div>
		);
	},
);
