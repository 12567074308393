import cn from 'classnames';

import { Fragment, ReactNode, useState } from 'react';

import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react';

import styles from './Tabs.module.scss';

export type TabListItemRenderFunction = ({ selected }: { selected?: boolean; content: ReactNode }) => JSX.Element;

interface TabsProps {
	tabListItems: ReactNode[];
	tabPanels: ReactNode[];
	renderTabListItem: TabListItemRenderFunction;
	verticalList?: boolean;
	tabListClassName?: string;
	className?: string;
}

export const Tabs = (props: TabsProps) => {
	// Добавлен useState для корректного сброса активной вкладки
	const [tabIndex, setTabIndex] = useState(0);
	const { tabListItems, tabPanels, className, tabListClassName, verticalList, renderTabListItem } = props;

	return (
		<TabGroup
			vertical={verticalList}
			as={'div'}
			className={className}
			onChange={(i: number) => setTabIndex(i)}
			selectedIndex={tabIndex}
		>
			<TabList className={cn(styles.tabList, tabListClassName, { [styles.verticalList]: verticalList })}>
				{tabListItems.map((tab, index) => (
					<Tab as={Fragment} key={index}>
						{renderTabListItem({ selected: index === tabIndex, content: tab })}
					</Tab>
				))}
			</TabList>
			<TabPanels>
				{tabPanels.map((TabContent, index) => (
					<TabPanel key={index}>{TabContent}</TabPanel>
				))}
			</TabPanels>
		</TabGroup>
	);
};
