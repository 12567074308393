import { format } from 'date-fns';
import i18next from 'i18next';

import { type PenaltyName } from '@entities/Offer';
import { type ISearchForm, type ISearchParams } from '@entities/Search';

import { fnsLocales } from '../../const/dictionary';

/**
 * Получает значение cookie по имени.
 * @param {string} name - Имя cookie.
 * @returns {string|undefined} - Значение cookie или undefined, если cookie не найдена.
 * @example
 * // Возвращает значение cookie 'username' (если такая cookie существует)
 * document.cookie = 'username=test;';
 * const username = getCookie('username');
 */
export function getCookie(name: string): string | undefined {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');
	if (parts && parts.length === 2) {
		return parts.pop()?.split(';').shift() || undefined;
	}
	return undefined;
}

export function setCookie(name: string, value: string, maxAge?: number) {
	// maxAge по умолчанию 30 дней
	document.cookie = `${name}=${value}; max-age=${maxAge ?? 86400 * 90}; path=/`;
}

/**
 * Возвращает слово в нужном падеже в зависимости от числа.
 * @param {number} num - Число, для которого нужно вернуть слово в нужном падеже.
 * @param {string[]} words - Массив слов в разных падежах. Первый элемент массива - слово в именительном падеже.
 * @returns {string} Слово в нужном падеже.
 *
 * @example
 * const result1 = slantWords(1, ['яблоко', 'яблока', 'яблок']);
 * console.log(result1); // 'яблоко'
 *
 * const result2 = slantWords(2, ['яблоко', 'яблока', 'яблок']);
 * console.log(result2); // 'яблока'
 *
 * const result3 = slantWords(5, ['яблоко', 'яблока', 'яблок']);
 * console.log(result3); // 'яблок'
 */

export const slantWords = (num: number, words: string[]) => {
	const cases = [2, 0, 1, 1, 1, 2];
	return words[num % 100 > 4 && num % 100 < 20 ? 2 : cases[num % 10 < 5 ? num % 10 : 5]] || words[0];
};

/**
 * Преобразует первый символ строки в верхний регистр, а остальные символы в нижний регистр.
 * @param {string} str - Строка для преобразования.
 * @returns {string} Преобразованная строка.
 *
 * @example
 * const str = 'hello world';
 * const result = firstCharacterUp(str);
 * console.log(result); // 'Hello world'
 */
export function firstCharacterUp(str: string): string {
	if (!str) return str;

	return str[0].toUpperCase() + str.slice(1).toLocaleLowerCase();
}

/**
 * Генерирует строку запроса для поиска авиабилетов на основе объекта параметров.
 * @param {ISearchForm} data - Объект параметров для поиска.
 * @returns {string} Строка запроса для поиска авиабилетов.
 *
 * @example
 * const searchParams = {
 *   from: 'MOW',
 *   to: 'LED',
 *   date_from: new Date(2022, 0, 1),
 *   date_to: new Date(2022, 0, 10),
 *   service_class: 'E',
 *   adults: 2,
 *   children: 1,
 *   infants: 0,
 * };
 * const queryString = generateSearchParams(searchParams);
 * console.log(queryString); // 'from=MOW&to=LED&date_from=2022-01-01&date_to=2022-01-10&service_class=E&adults=2&children=1'
 */

export const generateSearchParams = (data: ISearchForm) => {
	let querysring = '';

	// Обязательные поля
	querysring += `from=${data.from}`;
	querysring += `&to=${data.to}`;
	querysring += `&date_from=${format(data.date_from, 'yyyy-MM-dd')}`;
	querysring += `&service_class=${data.service_class}`;
	querysring += `&adults=${data.adults}`;

	// Не обязательные поля, могут быть пустые
	// Поэтому не должны попадать в querysring
	if (data.date_to) {
		querysring += `&date_to=${format(data.date_to, 'yyyy-MM-dd')}`;
	}

	if (data.children) {
		querysring += `&children=${data.children}`;
	}

	if (data.infants) {
		querysring += `&infants=${data.infants}`;
	}

	return querysring;
};

/**
 * Description
 * @param {string} search
 * @returns {any}
 */

export const getSearchParams = (search: string): Partial<ISearchParams> => {
	const cleanParams = search.substring(search.indexOf('from='));
	const params = new URLSearchParams(cleanParams);

	const searchData: Partial<ISearchParams> = {};

	params.forEach((value: string, key: string) => {
		searchData[key as keyof ISearchParams] = value;
	});

	return searchData;
};

/**
 * Функция преобразует объект в строку для формирования пути для навигации
 * @param {Record<string, never>} obj - Объект, содержащий параметры для формирования строки пути
 * @returns {string} Возвращает строку, содержащую параметры для формирования пути
 */

export const getPathForBreadcrumbs = (obj: Record<string, unknown>) => {
	let path = '';

	for (const key in obj) {
		if (obj[key]) {
			path = path.concat(`${key}=${obj[key]}&`);
		}
	}

	return path ? `?${path.slice(0, -1)}` : '';
};

/**
 * Проверяет дату и возвращает текущую, если переданная дата меньше текущей.
 * @param {Date} date - Дата для проверки.
 * @returns {Date} Дата, которую нужно использовать.
 *
 * @example
 * const myDate = new Date('2022-01-01');
 * const checkedDate = checkDate(myDate);
 * console.log(checkedDate); // Если переданная дата меньше текущей, то выведет текущую дату, иначе выведет переданную дату
 */

export const checkDate = (date: Date): Date => {
	const now = new Date();
	return date < now ? now : date;
};

/**
 * Функция для проверки строки с датой.
 *
 * @param {string} dateStr - Строка с датой в формате 'дд.мм.гггг'.
 * @param {boolean} [isDateMore] - Флаг, указывающий, нужно ли проверять, является ли переданная дата больше текущей даты (true) или меньше текущей (false). По умолчанию параметр необязательный и равен undefined.
 * @returns {boolean} - Возвращает true, если дата корректна и соответствует условию, в противном случае - false.
 *
 * @example
 * const result1 = checkStringDate('21.07.2022', true);
 * console.log(result1); // true
 *
 * const result2 = checkStringDate('21.07.2022', false);
 * console.log(result2); // false
 *
 * const result3 = checkStringDate('21.07.2022');
 * console.log(result3); // true
 */

export const checkStringDate = (dateStr: string, isDateMore?: boolean): boolean => {
	const today = new Date();
	const [day, month, year] = dateStr.split('.').map(Number);

	if (!isDateMore && (day > 31 || month > 12 || year > today.getFullYear())) {
		return false;
	}

	if (isDateMore && (day > 31 || month > 12 || year < today.getFullYear())) {
		return false;
	}

	const newUserDate = new Date(year, month - 1, day);

	return isDateMore ? newUserDate > today : newUserDate < today;
};

/**
 * Функция для перевода секунд в формат дни:часы:минуты.
 *
 * @param {number} seconds - Кол-во секунд
 * @param {IDictionary} dictionary - Словарь с переводами для подставляемых букв
 * @returns {string}
 *
 * @example
 * const result1 = secondsToDhm(5100, { days: 'д', hours: 'ч', minutes: 'м' };
 * console.log(result1); // '1ч 25м'
 *
 * const result2 = secondsToDhm(5100, { days: 'd', hours: 'h', minutes: 'm' };
 * console.log(result2); // '1h 25m'
 */

interface IDictionary {
	minutes: string;
	days: string;
	hours: string;
}

export const secondsToDhm = (seconds: number, dictionary: IDictionary): string => {
	const days = Math.floor(seconds / (3600 * 24));
	const hours = Math.floor((seconds % (3600 * 24)) / 3600);
	const minutes = Math.floor((seconds % 3600) / 60);

	const dDisplay = days > 0 ? `${days}${dictionary.days}` : '';
	const hDisplay = hours > 0 ? `${hours}${dictionary.hours}` : '';
	const mDisplay = minutes > 0 ? `${minutes}${dictionary.minutes}` : '';

	return `${dDisplay} ${hDisplay} ${mDisplay}`;
};

/**
 * Функция для форматирования даты по заданному паттерну и локале.
 *
 * @param {Date} date
 * @param {string} pattern
 * @param {string} locale
 * @returns {string}
 *
 * @example
 * const result1 = formatDateByPatternAndLocale(Sat Jul 22 2023 17:00:00 GMT+0800 (Китай, стандартное время), 'dd MMMM yyyy', 'ru');
 * console.log(result1); // '22 июля 2023'
 */

export const formatDateByPatternAndLocale = (date: Date, pattern: string, locale: string) => {
	return format(date, pattern, { locale: fnsLocales[locale] });
};

/**
 * Форматирует цену в виде строки.
 * @param {string} price - Цена в виде строки.
 * @returns {string} - Отформатированная цена.
 * @example
 * // Примеры использования:
 * const formattedPrice1 = priceFormat('1000'); // '1 000'
 * const formattedPrice2 = priceFormat('50000'); // '50 000'
 */

export function priceFormat(price: string | number) {
	const regEx = /(\d)(?=(\d{3})+(,|$))/g;

	if (typeof price === 'number') {
		return price.toFixed().replace(regEx, '$1 ');
	}

	return (parseInt(price) || 0).toFixed().replace(regEx, '$1 ');
}

export const getPenaltyStatus = (status?: string): PenaltyName | 'charter' | '' => {
	switch (status) {
		case 'charter':
			return i18next.t('charter', { ns: 'filters' });

		case 'N':
		case 'noreturnable':
			return i18next.t('nevozvratnyi', { ns: 'filters' });

		case 'Y':
		case 'returnable':
			return i18next.t('vozvratnyi', { ns: 'filters' });

		case 'PAY':
		case 'optionaltax':
		case 'returnablewithtax':
			return i18next.t('vozvratnyi-so-sborom', { ns: 'filters' });

		case 'specialСonditions':
			return i18next.t('osobye-usloviya', { ns: 'filters' });

		default:
			return '';
	}
};

export const getTransfer = (transfer?: string) => {
	switch (transfer) {
		case '0':
			return i18next.t('bez-peresadok', { ns: 'filters' });

		case '1':
			return i18next.t('odna-peresadka', { ns: 'filters' });

		case '2':
			return i18next.t('dve-peresadka', { ns: 'filters' });

		case '3':
			return i18next.t('tre-peresadka', { ns: 'filters' });

		case '4':
			return i18next.t('chetyre-peresadki', { ns: 'filters' });

		default:
			return '';
	}
};
