import cn from 'classnames';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { type IWithTooltipPlacement, WithTooltip } from '@shared/ui/WithTooltip/WithTooltip';

import { BagDoubleIcon } from '../../assets/BagDoubleIcon';
import { BagIcon } from '../../assets/BagIcon';
import { HandLuggageIcon } from '../../assets/HandLuggageIcon';
import NoBaggageIcon from '../../assets/noBaggage.svg?react';
import { IResultBaggageInfo } from '../../lib/getBaggage';
import styles from './Baggage.module.scss';

interface BaggageProps {
	baggage: IResultBaggageInfo;
	className?: string;
	direction?: 'row' | 'column';
	tooltipPlacement?: IWithTooltipPlacement;
}

export const BaggageInfo = memo((props: BaggageProps) => {
	const { baggage, className, tooltipPlacement = 'top', direction = 'row' } = props;

	return (
		<div className={cn(styles.baggage, className, styles[direction])}>
			<HandLuggage tooltipPlacement={tooltipPlacement} baggage={baggage} />
			<Baggage tooltipPlacement={tooltipPlacement} baggage={baggage} />
		</div>
	);
});

export const Baggage = memo((props: BaggageProps) => {
	const {
		tooltipPlacement,
		baggage: { baggageKg, baggagePlaces },
	} = props;
	const { t } = useTranslation('filters');

	if (baggageKg) {
		return (
			<WithTooltip initialPlacement={tooltipPlacement} tip={t(baggageKg.desc)}>
				<BagIcon number={baggageKg.kgs} />
			</WithTooltip>
		);
	}

	if (baggagePlaces) {
		const tmp = baggagePlaces;

		if (tmp.places === 1) {
			if (baggagePlaces.kgs) {
				return (
					<WithTooltip initialPlacement={tooltipPlacement} tip={t(baggagePlaces.desc)}>
						<BagIcon number={baggagePlaces.kgs} />
					</WithTooltip>
				);
			} else {
				return (
					<WithTooltip initialPlacement={tooltipPlacement} tip={t(tmp.desc)}>
						<BagIcon />
					</WithTooltip>
				);
			}
		}

		return (
			<WithTooltip initialPlacement={tooltipPlacement} tip={t(tmp.desc)}>
				<BagDoubleIcon number={tmp.kgs} />
			</WithTooltip>
		);
	}

	return (
		<WithTooltip initialPlacement={tooltipPlacement} tip={t('bagazh-ne-vkhodit-v-stoimost')}>
			<NoBaggageIcon />
		</WithTooltip>
	);
});

export const HandLuggage = memo((props: BaggageProps) => {
	const {
		tooltipPlacement,
		baggage: { handLuggageKg, handLuggagePlaces },
	} = props;
	const { t } = useTranslation('filters');

	const tmp = handLuggageKg ?? handLuggagePlaces;

	if (tmp) {
		return (
			<WithTooltip initialPlacement={tooltipPlacement} tip={t(tmp.desc)}>
				<HandLuggageIcon number={tmp.kgs} />
			</WithTooltip>
		);
	}

	return (
		<WithTooltip initialPlacement={tooltipPlacement} tip={t('utochnite')}>
			<HandLuggageIcon />
		</WithTooltip>
	);
});
