import { useCallback, useEffect, useRef, useState } from 'react';

export default function useComponentVisible(initialIsVisible: boolean) {
	const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);

	const ref = useRef<HTMLDivElement>(null);

	const handleClickOutside = useCallback(
		(event: MouseEvent) => {
			const isTargetOutside = ref.current && !ref.current.contains(event.target as Node);

			if (isTargetOutside) {
				setIsComponentVisible(false);
			}
		},
		[ref],
	);

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				setIsComponentVisible(false);
			}
		},
		[setIsComponentVisible],
	);

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside, true);
		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside, true);
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [handleClickOutside, handleKeyDown]);

	return { ref, isComponentVisible, setIsComponentVisible };
}
