import { Altdomain, appSettings } from '../../../../appSettings';
import futuraLoader from '../../../../assets/banners/futura-loader.jpg';
import { DefaultBannerLoader } from '../../DefaultBannerLoader/ui/DefaultBannerLoader';
import { DynamicBannerLoader } from '../../DynamicBannerLoader/ui/DynamicBannerLoader';
import { StaticBannerLoader } from '../../StaticBannerLoader/ui/StaticBannerLoader';

type TStep = 'form_top_submit' | 'offer_submit' | 'personal_data_submit' | 'precommit_submit';

export interface IBannerLoaderProps {
	depart?: string;
	arrival?: string;
	step?: TStep;
	month?: string;
	child?: string;
}

export const BannerLoader = (props: IBannerLoaderProps) => {
	const altDomain = appSettings.appAltDomain;

	switch (altDomain) {
		case Altdomain.BILETIX_DESKTOP:
		case Altdomain.BILETIX_MOBILE:
		case Altdomain.BILETIX_EN_DESKTOP:
		case Altdomain.BILETIX_EN_MOBILE:
		case Altdomain.BILETIX_KZ_DESKTOP:
		case Altdomain.BILETIX_KZ_MOBILE:
			return <DynamicBannerLoader {...props} />;

		case Altdomain.FUTURA_TRAVEL:
			return <StaticBannerLoader imgUrl={futuraLoader} description='Ищем для вас</br> лучшие билеты' />;

		default:
			return <DefaultBannerLoader />;
	}
};
