import i18next from 'i18next';

export const transformStopTimeToWait = (time: string) => {
	let result = '';
	const hh = Number(time.split(':')[0]);
	const mm = Number(time.split(':')[1]);
	const hours = i18next.t('ch', { ns: 'filters' });
	const minutes = i18next.t('m', { ns: 'filters' });

	if (hh > 0) {
		result += `${hh}${hours}`;
	}

	if (mm > 0) {
		result += ` ${mm}${minutes}`;
	}

	return result;
};
