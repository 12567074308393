import cn from 'classnames';

import { createPortal } from 'react-dom';

import styles from '../../DefaultBannerLoader/ui/DefaultBannerLoader.module.scss';

export const StaticBannerLoader = ({ imgUrl, description }: { imgUrl: string; description: string }) => {
	return (
		<>
			{createPortal(
				<div className={cn('banner', styles.wrapper)}>
					<div className={styles.banner}>
						<img className={styles.img} src={imgUrl} alt='Баннер загрузки' />
						<p className={styles.description} dangerouslySetInnerHTML={{ __html: description }} />

						<div className={styles.progress} />
					</div>
				</div>,
				document.body,
			)}
		</>
	);
};
