import cn from 'classnames';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { enUS } from 'date-fns/locale/en-US';
import { ru } from 'date-fns/locale/ru';
import { zhCN } from 'date-fns/locale/zh-CN';

import { registerLocale } from 'react-datepicker';
import { useFormContext } from 'react-hook-form';

import { Altdomain, appSettings } from '@shared/appSettings';
import CalendaIconrSigma from '@shared/assets/icons/calendar-sigma.svg?react';
import CalendarIcon from '@shared/assets/icons/calendar.svg?react';
import { symbolCurrency } from '@shared/const/dictionary';
import { useLocale } from '@shared/hooks/useLocale';
import useComponentVisible from '@shared/hooks/useOutsideAlerter';
import { checkDate } from '@shared/lib/helpers';
import { ThemeType } from '@shared/types';
import { DatePickerField } from '@shared/ui/DatePickerField/DatePickerField';

import { useLazyGetBestPriceQuery } from '../../api/searchDatePickerApi';
import { SearchFormType } from '../../model/types/i.searchForm';
import styles from './SearchDatePicker.module.scss';

interface ISearchDatePickerProps {
	name: SearchFormType.DATE_FROM | SearchFormType.DATE_TO;
	label: string;
	theme: ThemeType;
}

export const SearchDatePicker = ({ name, label, theme }: ISearchDatePickerProps) => {
	const [getBestPrice, { data: bestPrice }] = useLazyGetBestPriceQuery();

	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const lang = useLocale();
	const { getValues, setValue, setFocus } = useFormContext();
	const Icon = theme === 'sigma' ? CalendaIconrSigma : CalendarIcon;

	const isKZ =
		appSettings.appAltDomain === Altdomain.BILETIX_KZ_DESKTOP ||
		appSettings.appAltDomain === Altdomain.BILETIX_KZ_MOBILE ||
		appSettings.appAltDomain === Altdomain.FUTURA_TRAVEL;

	switch (lang) {
		case 'ru':
			registerLocale('ru', ru);
			break;
		case 'en':
			registerLocale('en', enUS);
			break;
		case 'de':
			registerLocale('de', de);
			break;
		case 'zh':
			registerLocale('zh', zhCN);
			break;
		default:
			break;
	}

	const dateFrom = getValues(SearchFormType.DATE_FROM);
	const dateTo = getValues(SearchFormType.DATE_TO);

	const onCalendarReset = () => {
		setValue(name, null);
		setIsComponentVisible(false);
	};

	const onCalendarClose = () => {
		setIsComponentVisible(false);
	};

	const onCalendarChange = (date: Date) => {
		setValue(name, date);
		setIsComponentVisible(false);
		if (setFocus && name === SearchFormType.DATE_FROM) {
			setFocus(SearchFormType.DATE_TO);
		}
	};

	const onFocus = () => {
		const iataFrom = getValues(SearchFormType.FROM)?.split(',')[1]?.trim();
		const iataTo = getValues(SearchFormType.TO)?.split(',')[1]?.trim();

		setIsComponentVisible(true);

		// Отображение цен доступно только для ₽
		if (!isKZ && appSettings.appCurrency === 'RUR' && iataFrom && iataTo) {
			if (name === SearchFormType.DATE_TO) {
				getBestPrice({
					from: iataFrom,
					to: iataTo,
					numberDay: 38,
					startDateFrom: format(checkDate(dateFrom), 'yyyy-MM-dd'),
					startDateTo: format(checkDate(dateFrom), 'yyyy-MM-dd'),
					type: 'RT',
				});
			} else {
				getBestPrice({
					from: iataFrom,
					to: iataTo,
					numberDay: 365,
					startDateFrom: format(checkDate(new Date()), 'yyyy-MM-dd'),
					type: 'OW',
				});
			}
		}
	};

	const renderDayContents = (day: number, date: Date) => {
		const currentDate = Number(format(date, 'yyyy-MM-dd', { locale: zhCN }));

		return (
			<div className={styles.dateWrap}>
				<span>{day}</span>
				{bestPrice && bestPrice[currentDate] && bestPrice[currentDate]?.price !== '-' && (
					<span
						className={cn('wl-datepicker-price', {
							[styles.best]: bestPrice[currentDate].best,
							[styles.price]: !bestPrice[currentDate].best,
						})}
					>
						{`${bestPrice[currentDate]?.price} ${symbolCurrency[appSettings.appCurrency]}`}
					</span>
				)}
			</div>
		);
	};

	return (
		<DatePickerField
			// Calendar
			onCalendarChange={onCalendarChange}
			onCalendarClose={onCalendarClose}
			onCalendarReset={onCalendarReset}
			renderDayContents={renderDayContents}
			startDate={new Date(dateFrom)}
			endDate={dateTo ? new Date(dateTo) : null}
			minDate={name === SearchFormType.DATE_TO ? new Date(dateFrom) : new Date()}
			// selectsEnd={name === SearchFormType.DATE_TO}
			isClearable={(dateTo && name === SearchFormType.DATE_TO) ?? false}
			// TextField
			onFocus={onFocus}
			theme='transparent'
			label={label}
			name={name}
			icon={name === SearchFormType.DATE_FROM ? Icon : undefined}
			// Other
			locale={lang}
			ref={ref}
			isComponentVisible={isComponentVisible}
		/>
	);
};
