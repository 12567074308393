import cn from 'classnames';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { secondsToDhm } from '@shared/lib/helpers';

import { getStopsInfo } from '../../lib/getStopsInfo';
import { transformStopTimeToWait } from '../../lib/transformStopTimeToWait';
import { ISegment, ITrip } from '../../model/types/i.offer';
import styles from './Stops.module.scss';

interface StopsProps {
	className?: string;
	segment: ISegment;
	trip: ITrip;
	index: number;
}

export const Stops = memo((props: StopsProps) => {
	const { className, segment, trip, index } = props;
	const { t } = useTranslation('filters');
	const hours = t('ch');
	const minutes = t('m');
	const days = t('d');
	const { isAnotherAirport, isAuto, isTrain } = getStopsInfo(trip);

	return (
		<>
			{Array.isArray(segment.stops) && segment.stops.length > 0 && (
				<>
					{segment.stops.map((stop, index) => {
						return (
							<div className={cn(styles.stopSegment, className)} key={index}>
								<p className={styles.duration}>
									{secondsToDhm(stop.duration, { days: days, hours: hours, minutes: minutes })}
								</p>
								<p className={styles.stopInfo}>{`${t('tekhnicheskaya-posadka')} - ${
									stop.city_full_name
								}`}</p>
							</div>
						);
					})}
				</>
			)}

			{'city_code' in segment.stops && (
				<div className={cn(styles.stopSegment, className)}>
					<p className={styles.duration}>
						{secondsToDhm(segment.stops.duration, { days: days, hours: hours, minutes: minutes })}
					</p>
					<div className={styles.stopInfo}>{`${t('tekhnicheskaya-posadka')} - ${
						segment.stops.city_full_name
					}`}</div>
				</div>
			)}

			{segment.time_to_wait.length > 0 && (
				<div className={cn(styles.stopSegment, className)}>
					<p className={styles.duration}>{transformStopTimeToWait(segment.time_to_wait)}</p>
					<div className={styles.stopInfo}>
						{`${t('peresadka')} - ${segment.arrival.city_name}`}
						{(isAnotherAirport || isTrain || isAuto) && (
							<div className='wl-offer__special'>
								{isAnotherAirport &&
									trip.segments[index + 1] !== undefined &&
									segment.arrival.id !== trip.segments[index + 1].departure.id && (
										<p>
											{`${t('smena-aeroporta')} (${segment.arrival.id} -
											${trip.segments[index + 1].departure.id})`}
										</p>
									)}
								{isTrain && <p>{t('peresadka-na-poezd')}</p>}
								{isAuto && <p>{t('peresadka-na-avtobus')}</p>}
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
});

Stops.displayName = 'Stops';
