import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import { initReactI18next } from 'react-i18next';

import { appSettings } from '../../appSettings';

const isDevEndpoint = window.location.host.includes('avia-wl-new-endpoint.biletix.ru');

i18n.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			// Проверить на режим dev и тестовый endpoint
			// Проверка на необходима для корректного отображения на тестовой странице biletix (https://biletix.ru/dev-wl/)
			loadPath: `${__IS_DEV__ && isDevEndpoint ? '.' : appSettings.appUrl}/locales/{{lng}}/{{ns}}.json`,
		},
		fallbackLng: false,
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		returnNull: false,
	})
	.catch(() => {
		console.log('Failed init config i18n');
	});
export default i18n;
