import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export interface ITransferFilter {
	transferCounts: Record<string, boolean>;
	prices: Record<string, number>;
	anyCount: boolean;
}

const initialState: ITransferFilter = {
	transferCounts: {},
	prices: {},
	anyCount: true,
};

const isAllKeysTrue = <T extends object>(obj: T) => {
	let allTrue = true;

	for (const key in obj) {
		if (!obj[key]) {
			allTrue = false;
		}
	}

	return allTrue;
};

export const transferSlice = createSlice({
	name: 'transfer',
	initialState,
	reducers: {
		setInitialTransfers(state, action: PayloadAction<Record<string, number[]>>) {
			const transfers = action.payload;

			state.transferCounts = {};
			state.prices = {};

			for (const num in transfers) {
				state.transferCounts[num] = true;
				state.prices[num] = Math.min(...transfers[num]);
			}
		},
		toggleTransferCount(state, action: PayloadAction<keyof ITransferFilter['transferCounts']>) {
			const key = action.payload;

			state.transferCounts[key] = !state.transferCounts[key];
			state.anyCount = isAllKeysTrue(state.transferCounts);
		},
		toggleAnyCount(state) {
			state.anyCount = !state.anyCount;

			if (!state.anyCount) {
				for (const key in state.transferCounts) {
					state.transferCounts[key] = false;
				}
			}

			if (state.anyCount) {
				for (const key in state.transferCounts) {
					state.transferCounts[key] = true;
				}
			}
		},
		reset(state) {
			state.anyCount = true;

			for (const key in state.transferCounts) {
				state.transferCounts[key] = true;
			}
		},
	},

	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			transferSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: transferActions, reducer: transferReducer } = transferSlice;
