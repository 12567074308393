import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import Button from '@shared/ui/Button/Button';

import { getFilteredOffers, getVisibleOffersCount } from '../../model/selectors/offersListSelectors';
import { offersListActions } from '../../model/slices/offerListSlice';

export const ShowMore = memo(() => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('filters');

	const filteredOffers = useSelector(getFilteredOffers);
	const visibleOffersCount = useSelector(getVisibleOffersCount);

	if (!filteredOffers) {
		return null;
	}

	const onClickHandler = () => {
		dispatch(offersListActions.incrementVisibleOffersCount());
	};

	const availableOffers = filteredOffers?.length - visibleOffersCount;

	if (availableOffers < 1) {
		return null;
	}

	return (
		<Button textPosition='center' size='size_m' onClick={onClickHandler} color='c4'>
			{t('pokazat-eshe')}
		</Button>
	);
});
