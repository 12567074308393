import cn from 'classnames';

import React, { ForwardedRef, forwardRef } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';

import CloseIcon from '../../assets/icons/close.svg?react';
import { ThemeFieldType } from '../../types';
import Button from '../Button/Button';
import IconSvg from '../IconSvg/IconSvg';
import styles from './TextField.module.scss';

export interface ITextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	id?: string;
	label?: string | JSX.Element | null;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onFocus?: React.FocusEventHandler<HTMLInputElement>;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	onReset?: () => void;
	errorsName?: string;
	errors?: FieldErrors<FieldValues>;
	readonly?: boolean;
	icon?: React.FC<React.SVGProps<SVGSVGElement>>;
	style?: React.CSSProperties;
	className?: string;
	border?: boolean;
	reset?: boolean;
	theme?: ThemeFieldType;
	description?: string;
	errorLabelMessage?: boolean;
	disabled?: boolean;
	isOnlyNumber?: boolean;
	isOnlyText?: boolean;
	isOnlyLatinText?: boolean;
	isOnlyCyrillicText?: boolean;
	isUpperCaseText?: boolean;
	isTouched?: boolean;
	isDefaultShowSuccess?: boolean;
}

export const TextField = forwardRef(
	(
		{
			name,
			label,
			id,
			onChange,
			onFocus,
			onBlur,
			onReset,
			value,
			description = '',
			errors,
			errorsName = name,
			readonly,
			icon,
			reset,
			style,
			theme = 'default',
			disabled,
			errorLabelMessage,
			isOnlyNumber,
			isOnlyText,
			isOnlyLatinText,
			isOnlyCyrillicText,
			isUpperCaseText,
			className,
			isTouched,
			// Сразу отображает состояние success, если в поле есть значение и isTouched = false
			// Например, после перезагрузки страницы из localstorage в форму подставляются значения пассажира
			// и необходимо сразу отобразить успешную валидацию
			isDefaultShowSuccess = false,
			...props
		}: ITextFieldProps,
		ref: ForwardedRef<HTMLInputElement>,
	) => {
		const themeClasses: Record<ThemeFieldType, string> = {
			default: styles.default,
			transparent: styles.transparent,
			background: styles.background,
		};

		const onValidation = (value: React.ChangeEvent<HTMLInputElement>): React.ChangeEvent<HTMLInputElement> => {
			const { target } = value;
			const { value: currentValue } = target;
			const selectionStart = target.selectionStart;

			if (isOnlyNumber) {
				target.value = currentValue.replace(/\D/g, '');
			} else if (isOnlyText) {
				target.value = currentValue.replace(/[^a-zA-Zа-яА-Я]+/gu, '');
			} else if (isOnlyLatinText) {
				target.value = currentValue.replace(/[^a-zA-Z]+/gu, '');
			} else if (isOnlyCyrillicText) {
				target.value = currentValue.replace(/[^а-яА-Я]+/gu, '');
			}

			if (isUpperCaseText) {
				target.value = currentValue.toUpperCase();
				target.setSelectionRange(selectionStart, selectionStart);
			}

			return value;
		};

		return (
			<>
				<div
					className={cn(styles.textField, className, {
						[styles.isError]: errors && errors[errorsName]?.message,
						[styles.isSuccess]:
							(isDefaultShowSuccess && !isTouched && value && !(errors && errors[errorsName]?.message)) ||
							(isTouched && !disabled && value && !(errors && errors[errorsName]?.message)),
						[themeClasses[theme]]: theme,
						[styles.disabled]: disabled,
						[styles.uppercase]: isUpperCaseText,
					})}
					style={style}
				>
					{description && <span className={styles.description}>{description}</span>}
					<input
						id={id}
						type='text'
						name={name}
						onFocus={onFocus}
						onBlur={onBlur}
						onChange={(e) => onChange && onChange(onValidation(e))}
						autoComplete='off'
						ref={ref}
						value={value ?? ''}
						readOnly={readonly}
						disabled={disabled}
						{...props}
					/>

					{label && <label>{label}</label>}
					{errors && errors[errorsName] && (
						<span
							className={cn({
								[styles.errorMessage]: !errorLabelMessage && errors && errors[errorsName]?.message,
								[styles.errorLabelMessage]: errorLabelMessage && errors && errors[errorsName]?.message,
							})}
						>
							{errors[errorsName]?.message?.toString()}
						</span>
					)}
					{icon && <div className={styles.icon}>{<IconSvg Svg={icon} />}</div>}
					{reset && (
						<Button variant='clear' className={styles.reset} onClick={onReset}>
							<IconSvg Svg={CloseIcon} />
						</Button>
					)}
				</div>
			</>
		);
	},
);
