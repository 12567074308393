import cn from 'classnames';

import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IGroupedTrip, ITrip } from '../../model/types/i.offer';
import styles from './ExtraTimeTrip.module.scss';

interface ExtraTimeTripProps {
	className?: string;
	groupedTrips: IGroupedTrip;
	onTripClick: (trip: ITrip) => void;
	activeTrip: ITrip;
}

export const ExtraTimeTrip = memo((props: ExtraTimeTripProps) => {
	const { className, onTripClick, groupedTrips, activeTrip } = props;
	const { t } = useTranslation('filters');
	const [isExpand, setIsExpand] = useState(false);

	return (
		<div className={cn(styles.wrapper, className)}>
			{[...(isExpand ? groupedTrips.trips : groupedTrips.trips.slice(0, 7))].map((trip) => (
				<button
					className={cn(styles.btn, { [styles.active]: activeTrip.offer_id === trip.offer_id })}
					onClick={() => onTripClick(trip)}
					key={trip.offer_id}
				>
					<span className={styles.start}>{trip.segments[0].departure_time}</span>
					<span className={cn(styles.line, { [styles.withTransfers]: activeTrip.segments.length > 1 })} />
					<span className={styles.end}>{trip.segments.at(-1)?.arrival_time}</span>
				</button>
			))}

			{groupedTrips.trips.length > 7 && (
				<button
					className={cn(styles.more, { [styles.expanded]: isExpand })}
					onClick={() => setIsExpand(!isExpand)}
				>
					{isExpand ? t('skryt') : t('pokazat-eshe')}
					<span className={styles.arrow} />
				</button>
			)}
		</div>
	);
});
