import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PARTNERS_BILETIX_URL } from '../../../../const/api';
import { IBannerLoader, IBannerLoaderParams } from '../model/types/banner';

export const bannerLoaderApi = createApi({
	reducerPath: 'bannerLoaderApi',
	tagTypes: [''],
	baseQuery: fetchBaseQuery({
		baseUrl: `${PARTNERS_BILETIX_URL}/api/`,
		prepareHeaders: (headers) => {
			return headers;
		},
	}),
	endpoints: (build) => ({
		getBannerLoaderInfo: build.query<IBannerLoader, IBannerLoaderParams>({
			query: ({ altDomain, depart, arrival, step, month, child, type }) => {
				return {
					url: `/get/banners_info/`,
					params: {
						type: type,
						lamp: 'green',
						altDomain: altDomain,
						depart: depart,
						arrival: arrival,
						step: step,
						'month[]': month,
						'child[]': child,
					},
				};
			},
			keepUnusedDataFor: 0,
		}),
	}),
});

export const { useGetBannerLoaderInfoQuery } = bannerLoaderApi;
