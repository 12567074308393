import { Locale, de, enUS, ru, zhCN } from 'date-fns/locale';

export const fnsLocales: Record<string, Locale> = {
	ru: ru,
	en: enUS,
	de: de,
	zh: zhCN,
};

export const symbolCurrency: Record<string, string> = {
	RUR: '₽',
	KZT: '₸',
};
