import { ITrip } from '../model/types/i.offer';

type IWarning = {
	transitRequired: boolean;
	seatsLeft: number;
};

export const getWarningText = (tripTo: ITrip | null, tripBack: ITrip | null, visaRequired: string): IWarning => {
	const result: IWarning = {
		transitRequired: false,
		seatsLeft: Number(tripTo?.seat_count),
	};

	if (tripTo) {
		if (visaRequired === 'Y') {
			result.transitRequired = true;
		}

		if (tripBack && tripTo.seat_count > tripBack.seat_count) {
			result.seatsLeft = Number(tripBack.seat_count);
		}
	}

	return result;
};
