interface IconProps extends React.SVGProps<SVGSVGElement> {
	className?: string;
	styles?: string;
	Svg: React.FC<React.SVGProps<SVGSVGElement>>;
}

const IconSvg = (props: IconProps) => {
	const { className, Svg, ...otherProps } = props;
	return <Svg className={className} {...otherProps} />;
};

export default IconSvg;
