import cn from 'classnames';
import { isEmpty } from 'lodash';

import { useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useLazyGetOffers } from '@entities/Offer';
import { ISearchForm, SearchDatePicker, SearchDirection, SearchFormType, SearchPassengers } from '@entities/Search';
import { useTheme } from '@features/ThemeSwitcher';
import { appSettings } from '@shared/appSettings';
import { SESSION_STORAGE_SEARCH_KEY } from '@shared/const/localstorage';
import { generateSearchParams } from '@shared/lib/helpers';
import { BannerLoader } from '@shared/ui/BannerLoader';
import Button from '@shared/ui/Button/Button';

import styles from './SearchForm.module.scss';

export const SearchForm = (): JSX.Element => {
	const navigate = useNavigate();
	const { theme } = useTheme();
	const { search } = useLocation();
	const location = useLocation();

	const { getLazyOffers, isFetching } = useLazyGetOffers();

	const { t } = useTranslation('search');

	const form = useForm<ISearchForm>({
		defaultValues: {
			date_from: new Date(),
			date_to: null,
			adults: 1,
			children: 0,
			infants: 0,
			service_class: 'E',
		},
		mode: 'onBlur',
	});

	const { handleSubmit, setValue } = form;

	useEffect(() => {
		// Если LS !== null, проходимся циклом по LS и добавляем в useForm
		// Если LS === null, то useForm берет значения из defaultValues

		const ls = sessionStorage.getItem(SESSION_STORAGE_SEARCH_KEY);

		const setValueToUseForm = (obj: ISearchForm) => {
			Object.entries(obj).map(([key, value]) => {
				if (key === SearchFormType.DATE_TO || key === SearchFormType.DATE_FROM) {
					setValue(key, value ? new Date(value as string) : null);
				} else if (
					key === SearchFormType.ADULT ||
					key === SearchFormType.CHILD ||
					key === SearchFormType.INFANT
				) {
					setValue(key, value ? Number(value) : 0);
				} else {
					// @ts-ignore
					setValue(key, value);
				}
			});
		};

		if (ls) {
			setValueToUseForm(JSON.parse(ls));
		} else {
			if (appSettings.appFrom) {
				setValue(SearchFormType.FROM, appSettings.appFrom);
			}
			if (appSettings.appTo) {
				setValue(SearchFormType.TO, appSettings.appTo);
			}
		}
	}, [search, setValue]);

	const onSubmit: SubmitHandler<ISearchForm> = async (searchData: ISearchForm) => {
		sessionStorage.setItem(SESSION_STORAGE_SEARCH_KEY, JSON.stringify(searchData));
		const querysring = generateSearchParams(searchData);

		if (appSettings.appRedirect) {
			return window.open(`${appSettings.appRedirect}?${querysring}`, '_blank', 'noreferrer');
		}

		const { status, data } = await getLazyOffers(searchData);

		if (status === 'fulfilled' && !isEmpty(data.data)) {
			return navigate(`${location.pathname}?${querysring}`);
		}
	};

	const checkKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
		if (e.key === 'Enter') e.preventDefault();
	};

	return (
		<div className={styles.wrapper}>
			{isFetching && <BannerLoader depart='MOW' arrival='LED' step='form_top_submit' month='6' child='n' />}
			<FormProvider {...form}>
				<form
					role='presentation'
					onSubmit={handleSubmit(onSubmit)}
					onKeyDown={(e) => checkKeyDown(e)}
					className={cn('container-wl', styles.form)}
				>
					<SearchDirection city={SearchFormType.FROM} label={t('gorod-vyleta')} nextRef={SearchFormType.TO} />

					<SearchDirection
						city={SearchFormType.TO}
						label={t('gorod-prileta')}
						nextRef={SearchFormType.DATE_FROM}
					/>

					<SearchDatePicker name={SearchFormType.DATE_FROM} label={t('data-vyleta')} theme={theme} />

					<SearchDatePicker name={SearchFormType.DATE_TO} label={t('obratno')} theme={theme} />

					<SearchPassengers />

					<Button
						variant='background'
						size='size_l'
						color='c1'
						type='submit'
						textPosition='center'
						arrow={theme !== 'sigma'}
						className={styles.btnSearch}
					>
						{t('naiti')}
					</Button>
				</form>
			</FormProvider>
		</div>
	);
};
