import { useTranslation } from 'react-i18next';

import Button from '@shared/ui/Button/Button';
import { Modal } from '@shared/ui/Modal/Modal';

import styles from './PageError.module.scss';

export const PageError = () => {
	const { t } = useTranslation('error');

	const reloadPage = () => {
		location.reload();
	};

	return (
		<Modal isOpen={true} className={styles.wrapper} onClose={reloadPage}>
			<p>{t('proizoshla-nepredvidennaya-oshibka')}</p>
			<Button type='button' color='c1' onClick={reloadPage}>
				{t('perezagruzit')}
			</Button>
		</Modal>
	);
};
