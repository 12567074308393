import cn from 'classnames';
import { toNumber } from 'lodash';

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { slantWords } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';
import { Checkbox } from '@shared/ui/Checkbox/Checkbox';
import { Dropdown } from '@shared/ui/Dropdown/Dropdown';

import { SearchFormType } from '../../model/types/i.searchForm';
import styles from './SearchPassengers.module.scss';

export const SearchPassengers = () => {
	const { t } = useTranslation('search');
	const { isMobileAndTablet } = useCustomMediaQuery();
	const { getValues, watch, setValue } = useFormContext();

	const value =
		toNumber(getValues(SearchFormType.ADULT)) +
		toNumber(getValues(SearchFormType.CHILD)) +
		toNumber(getValues(SearchFormType.INFANT));

	const increment = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const id = event.currentTarget.dataset.type as SearchFormType;
		const currentValue = toNumber(getValues(id));

		switch (id) {
			case SearchFormType.ADULT:
				setValue(id, currentValue !== 1 ? currentValue - 1 : 1);
				break;
			case SearchFormType.CHILD:
				setValue(id, currentValue !== 0 ? currentValue - 1 : 0);
				break;
			case SearchFormType.INFANT:
				setValue(id, currentValue !== 0 ? currentValue - 1 : 0);
				break;
			default:
				break;
		}
	};

	const decrement = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		const id = event.currentTarget.dataset.type as SearchFormType;
		const currentValue = toNumber(getValues(id));
		setValue(id, currentValue + 1);
	};

	const toogleBusiness = (e: React.ChangeEvent<HTMLInputElement>) => {
		const checked = e.target.checked;
		setValue(SearchFormType.SERVICE_CLASS, checked ? 'B' : 'E');
	};

	const renderTrigger = (
		<>{`${value} ${slantWords(value, t('passazhiry', { returnObjects: true }))}, ${
			watch(SearchFormType.SERVICE_CLASS) === 'B' ? t('biznes') : t('ekonom')
		}`}</>
	);

	const renderContent = (
		<ul className={cn(styles.listPassengers)}>
			{/* Взрослые */}
			<li>
				<span>{t('vzroslye')}</span>
				<div className={styles.wrapper}>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.ADULT}
						onClick={increment}
						disabled={watch(SearchFormType.ADULT) === 1}
						textPosition='center'
					>
						&#8722;
					</Button>
					<span className={styles.value}>{watch(SearchFormType.ADULT)}</span>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.ADULT}
						onClick={decrement}
						textPosition='center'
						disabled={value >= 10}
					>
						+
					</Button>
				</div>
			</li>

			{/* Дети до 12 лет */}
			<li>
				<span>{t('deti-do-12-let')}</span>
				<div className={styles.wrapper}>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.CHILD}
						onClick={increment}
						disabled={watch(SearchFormType.CHILD) === 0}
						textPosition='center'
					>
						&#8722;
					</Button>
					<span className={styles.value}>{watch(SearchFormType.CHILD)}</span>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.CHILD}
						onClick={decrement}
						textPosition='center'
						disabled={value >= 10}
					>
						+
					</Button>
				</div>
			</li>

			{/* Дети до 2 лет */}
			<li>
				<div>
					<span>{t('deti-do-2-let')}</span>
					<span className={styles.without}>{t('bez-mesta')}</span>
				</div>

				<div className={styles.wrapper}>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.INFANT}
						onClick={increment}
						disabled={watch(SearchFormType.INFANT) === 0}
						textPosition='center'
					>
						&#8722;
					</Button>
					<span className={styles.value}>{watch(SearchFormType.INFANT)}</span>
					<Button
						variant='clear'
						className={styles.button}
						data-type={SearchFormType.INFANT}
						onClick={decrement}
						textPosition='center'
						disabled={value >= 10 || watch(SearchFormType.INFANT) >= watch(SearchFormType.ADULT)}
					>
						+
					</Button>
				</div>
			</li>

			<li>
				<Checkbox
					onChange={toogleBusiness}
					checked={watch(SearchFormType.SERVICE_CLASS) === 'B'}
					text={t('biznes')}
				/>
			</li>
		</ul>
	);

	const contentCloseButton = <span className={styles.close}>{t('gotovo')}</span>;

	return (
		<div className={styles.field}>
			<Dropdown
				renderTrigger={renderTrigger}
				classNameTrigger={styles.trigger}
				contentCloseButton={isMobileAndTablet && contentCloseButton}
			>
				{renderContent}
			</Dropdown>
		</div>
	);
};
