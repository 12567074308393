import cn from 'classnames';

import { memo } from 'react';

import { IWithTooltipPlacement, WithTooltip } from '../../ui/WithTooltip/WithTooltip';
import styles from './Aviacompany.module.scss';

export interface Airline {
	id: string;
	oak: string;
	vak: string;
	name: string;
	oak_name: string;
}
export interface AirlineInfo {
	airlineInfo: Airline;
	airlineNumber: string;
}

export interface AviacompanyProps {
	className?: string;
	airline: AirlineInfo;
	tooltipPlacement?: IWithTooltipPlacement;
	view?: 'small' | 'big';
}

function getImageUrl(name: string) {
	return new URL(`../../assets/logos/${name}.png`, import.meta.url).href;
}

const replaceImg = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
	e.currentTarget.onerror = null;
	e.currentTarget.src = new URL(`../../assets/icons/no-avia-icon.svg`, import.meta.url).href;
};

export const Aviacompany = memo((props: AviacompanyProps) => {
	const {
		className,
		tooltipPlacement = 'top',
		view,
		airline,
		airline: { airlineNumber },
	} = props;

	let {
		airlineInfo: { id, name },
	} = airline;

	// FlyArystan - дочерняя компания Air Astana
	// Для Air Astana и FlyArystan используется один и тот же код 'КС'
	// Чтобы отличать FlyArystan от Air Astana, номер рейса всегда начинается с цифры 7 и имеет длину из 4 цифр
	if (id === 'KC' && airlineNumber.length === 4 && parseInt(airlineNumber.charAt(0)) === 7) {
		id = 'KC_FlyAristan';
		name = 'FlyArystan';
	}

	if (view === 'small') {
		return (
			<WithTooltip initialPlacement={tooltipPlacement} tip={name ?? id}>
				<img className={cn(styles.logo, styles.small)} src={getImageUrl(id)} alt={id} onError={replaceImg} />
			</WithTooltip>
		);
	}

	return (
		<div className={cn(styles.aviacompany, className)}>
			<img className={styles.logo} src={getImageUrl(id)} alt={id} onError={replaceImg} />
			<p className={styles.name}>{name ?? id}</p>
		</div>
	);
});
