import cn from 'classnames';

import { ReactNode, memo } from 'react';

import Arrow from '@shared/assets/icons/arrow.svg?react';
import Button from '@shared/ui/Button/Button';

import styles from './ModalMobile.module.scss';

interface ModalMobileProps {
	onClose: () => void;
	isOpen: boolean;
	children: ReactNode;
	title?: JSX.Element;
	className?: string;
}

export const ModalMobile = memo((props: ModalMobileProps) => {
	const { isOpen, onClose, children, title, className } = props;

	return (
		<div className={cn(styles.modal, className, { [styles.opened]: isOpen })}>
			<div className={styles.header}>
				<Button variant='clear' className={styles.back} onClick={onClose}>
					<Arrow />
				</Button>

				{title && title}
			</div>

			<div className={styles.content}>{children}</div>
		</div>
	);
});

ModalMobile.displayName = 'ModalMobile';
