import cn from 'classnames';

import { CloseButton, Popover, PopoverButton, PopoverPanel } from '@headlessui/react';

import { Expand } from '../Expand/Expand';
import styles from './Dropdown.module.scss';

interface MenuProps {
	classNameTrigger?: string;
	renderTrigger: React.ReactNode;
	children: React.ReactNode;
	contentCloseButton?: React.ReactNode;
	isDownIcon?: boolean;
}

export const Dropdown = (props: MenuProps) => {
	const { renderTrigger, classNameTrigger, isDownIcon = true, children, contentCloseButton } = props;

	return (
		<Popover>
			{({ open }) => (
				<>
					<PopoverButton className={cn(styles.trigger, classNameTrigger && classNameTrigger)}>
						{renderTrigger}
						{isDownIcon && <Expand isOpen={open} className={styles.icon} />}
					</PopoverButton>

					<PopoverPanel>
						{children}
						{contentCloseButton && <CloseButton>{contentCloseButton}</CloseButton>}
					</PopoverPanel>
				</>
			)}
		</Popover>
	);
};
