import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { Slider } from '@shared/ui/Slider/Slider';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { getPriceFilter, getPriceFilterString } from '../../../model/selectors/priceSelectors';
import { TPrice, priceActions } from '../../../model/slices/filters/priceSlice/priceSlice';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Trigger } from '../Trigger/Trigger';
import styles from './PriceFilter.module.scss';

export const PriceFilter = memo(() => {
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();
	const { t } = useTranslation('filters');

	const { currentPrice, initialPrice } = useSelector(getPriceFilter);
	const priceRangeToString = useSelector(getPriceFilterString);

	const onChange = useCallback(
		(newPrice: TPrice) => {
			dispatch(priceActions.setCurrentPrice(newPrice));
		},
		[dispatch],
	);

	if (!initialPrice || !currentPrice) {
		return null;
	}

	const priceSlider = (
		<Slider<TPrice>
			range
			min={initialPrice[0]}
			max={initialPrice[1]}
			value={currentPrice}
			onChange={onChange}
			label={priceRangeToString}
		/>
	);

	if (isMobileAndTablet) {
		return <MobileFilter title={t('cena-bileta')}>{priceSlider}</MobileFilter>;
	}

	return (
		<ToggledBlock
			renderTrigger={({ isOpen }) => <Trigger text={t('cena-bileta')} isOpen={isOpen} />}
			renderContent={() => <div className={styles.content}>{priceSlider}</div>}
			closeOtherToogle={false}
		/>
	);
});
