export const BagIcon = ({ number }: { number?: number | null }) => (
	<svg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<text x='51%' y='55%'>
			{number ?? ''}
		</text>
		<rect x='4.5' y='0.5' width='5' height='3' rx='1.5' stroke='#333333' />
		<rect x='3' y='18' width='3' height='3' rx='1.5' fill='#333333' />
		<rect x='8' y='18' width='3' height='3' rx='1.5' fill='#333333' />
		<rect x='13.5' y='3.5' width='15' height='13' rx='1.5' transform='rotate(90 13.5 3.5)' stroke='#333333' />
	</svg>
);
