import cn from 'classnames';

import { InputHTMLAttributes, ReactNode, memo } from 'react';

import MarkIcon from '../../assets/icons/mark.svg?react';
import styles from './Checkbox.module.scss';

interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
	className?: string;
	text?: string | ReactNode;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	withoutHoverBg?: boolean;
	textBottom?: boolean;
}

export const Checkbox = memo(({ withoutHoverBg, textBottom, text, className, ...props }: CheckboxProps) => {
	const { checked, disabled } = props;

	const wrapperMods = {
		[styles.disabled]: disabled,
		[styles.textBottom]: textBottom,
		[styles.withoutHoverBg]: withoutHoverBg,
	};

	return (
		<>
			<label className={cn(className, styles.wrapper, wrapperMods)}>
				<input type='checkbox' checked={checked} className={styles.input} disabled={disabled} {...props} />
				<MarkIcon className={cn(styles.checkbox, { [styles.checkboxActive]: checked })} aria-hidden='true' />
				{text}
			</label>
		</>
	);
});
