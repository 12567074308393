export const LOCAL_STORAGE_LANG = 'wl_lang';
export const LOCAL_STORAGE_THEME_KEY = 'wl_theme';
export const SESSION_STORAGE_SEARCH_KEY = 'wl_search';
export const SESSION_STORAGE_PASSENGERS = 'wl_passengers';
export const SESSION_STORAGE_CONTACTS = 'wl_contacts';
export const SESSION_STORAGE_TOKEN = 'wl_session_token';
export const SESSION_STORAGE_OFFER_TO = 'wl_offer_id1';
export const SESSION_STORAGE_OFFER_BACK = 'wl_offer_id2';
export const SESSION_STORAGE_ORDER_ID = 'wl_order_id';
export const SESSION_STORAGE_EXTRA_BAGGAGE = 'wl_extra_baggage';
export const SESSION_STORAGE_EXTRA_SEAT = 'wl_extra_seat';
export const SESSION_STORAGE_HIDDEN_SERVICE = 'wl_hidden_service';
export const SESSION_STORAGE_HIDDEN_PROMOCODE = 'wl_hidden_promocode';
export const SESSION_STORAGE_IS_BOOK = 'wl_book';
// Помогает отображать кнопку "Оплатил" для мобайл СБП, чтобы перейти на финишную страницу
export const SESSION_STORAGE_SBP_PAYMENT_ID = 'wl_sbp_payment_id';
export const SESSION_STORAGE_DEEPLINK = 'wl_deeplink';
export const SESSION_STORAGE_IS_RELOAD_PAGE_PAYMENT = 'wl_reload_page_payment';
// Тестирование
export const SESSION_STORAGE_TEST_ALTDOMAIN = 'wl_test_altdomain';
export const SESSION_STORAGE_TEST_LID = 'wl_test_lid';
