import cn from 'classnames';
import RcSlider, { SliderProps as RcSliderProps } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { ReactNode } from 'react';

import styles from './Slider.module.scss';
import { SliderWrapper } from './SliderWrapper';

interface SliderProps<T> extends Omit<RcSliderProps, 'value' | 'onChange'> {
	className?: string;
	label?: ReactNode;
	value: T;
	reversed?: boolean;
	onChange: (value: T) => void;
}

export const Slider = <T extends number | [number, number]>(props: SliderProps<T>) => {
	const { className, label, value, onChange, reversed = false, ...otherProps } = props;

	return (
		<SliderWrapper className={cn({ [styles.reversed]: reversed }, styles.wrapper, className)}>
			<RcSlider value={value} onChange={(e) => onChange(e as T)} {...otherProps} />
			{label}
		</SliderWrapper>
	);
};
