import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { secondsToDhm } from '@shared/lib/helpers';

import { getDurationFilter } from '../../../model/selectors/durationSelectors';
import { IDurationFilter } from '../../../model/slices/filters/durationSlice/durationSlice';

export const useGetDurationText = () => {
	const duration = useSelector(getDurationFilter);
	const { t } = useTranslation('filters');

	const getDurationText = (direction: keyof IDurationFilter['currentDuration']) => {
		const current = duration.currentDuration[direction];
		const maximum = duration.initialDuration[direction]?.[1];

		const hours = t('ch');
		const minutes = t('m');
		const days = t('d');

		if (!current) {
			return '';
		}

		if (maximum === current) {
			return t('lyuboe-vremya');
		}

		return secondsToDhm(current, { days: days, hours: hours, minutes: minutes });
	};

	return { getDurationText };
};
