import { isEmpty } from 'lodash';

import { TStateAirportsDirection, TStateAirportsTransfer } from './airportsFilter';

export type TAirportsDirection = {
	to: Record<string, number[]>;
	back: Record<string, number[]>;
};

export type TAirportsTransfer = {
	airports: Record<string, number[]>;
};
export interface IAirportsFilter {
	departure: TAirportsDirection;
	arrival: TAirportsDirection;
	transfers: TAirportsTransfer;
}

export const fillTransfer = (directionPayload: TAirportsTransfer, direction: TStateAirportsTransfer) => {
	direction.prices = {};
	direction.airports = {
		all: true,
	};

	for (const key in directionPayload.airports) {
		direction.airports[key] = true;
		direction.prices[key] = Math.min(...directionPayload.airports[key]);
	}
};

export const fillDirection = (directionPayload: TAirportsDirection, direction: TStateAirportsDirection) => {
	direction.prices = {};

	direction.to = {
		all: true,
	};

	direction.back = null;

	for (const key in directionPayload.to) {
		direction.to[key] = true;
		direction.prices[key] = Math.min(...directionPayload.to[key]);
	}

	if (isEmpty(directionPayload.back)) {
		return;
	}

	direction.back = {
		all: true,
	};

	for (const key in directionPayload.back) {
		direction.back[key] = true;
		direction.prices[key] = Math.min(...directionPayload.back[key]);
	}
};
