import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { appSettings } from '../appSettings';

export const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
	args,
	WebApi,
	extraOptions,
) => {
	const rawBaseQuery = fetchBaseQuery({
		baseUrl: `${appSettings.appHost}/api/`,
		prepareHeaders: async (headers) => {
			return headers;
		},
	});
	return rawBaseQuery(args, WebApi, extraOptions);
};
