import { entries } from 'lodash';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { Checkbox } from '@shared/ui/Checkbox/Checkbox';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { useGetLowerPrice } from '../../../lib/filters/aviacompanies/useGetLowerPrice';
import { getAviacompanies } from '../../../model/selectors/aviacompanySelectors';
import { aviacompanyActions } from '../../../model/slices/filters/aviacompanySlice/aviacompanySlice';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Trigger } from '../Trigger/Trigger';
import styles from './AviacompanyFilter.module.scss';

export const AviacompanyFilter = memo(() => {
	const { t } = useTranslation('filters');
	const aviacompanies = useSelector(getAviacompanies);
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();

	const { getPrice } = useGetLowerPrice();

	const onChange = (name: string) => {
		return () => {
			dispatch(aviacompanyActions.toggleCompany(name));
		};
	};

	const AviacompanyContent = () => (
		<div className={styles.wrapper}>
			{entries(aviacompanies).map(([ac, value]) => (
				<Checkbox
					key={ac}
					checked={value}
					text={
						<span className={styles.text}>
							{ac === 'all' ? t('vse') : ac} <span className={styles.price}>{getPrice(ac)}</span>
						</span>
					}
					onChange={onChange(ac)}
				/>
			))}
		</div>
	);

	if (isMobileAndTablet) {
		return (
			<MobileFilter title={t('aviakompanii')}>
				<AviacompanyContent />
			</MobileFilter>
		);
	}

	return (
		<ToggledBlock
			renderContent={AviacompanyContent}
			renderTrigger={({ isOpen }) => <Trigger text={t('aviakompanii')} isOpen={isOpen} />}
			closeOtherToogle={false}
		/>
	);
});
