import cn from 'classnames';

import { memo } from 'react';

import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { Expand } from '@shared/ui/Expand/Expand';
import { ToggleRenderFunction, ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { getBaggage } from '../../lib/getBaggage';
import { getPenalty } from '../../lib/getPenalty';
import { IOffer } from '../../model/types/i.offer';
import { BaggageInfo } from '../BaggageInfo/BaggageInfo';
import { Destinations } from '../Destinations/Destinations';
import { DetailsMobile } from '../DetailsMobile/DetailsMobile';
import { Penalty } from '../Penalty/Penalty';
import { Trip } from '../Trip/Trip';
import { TripMobile } from '../TripMobile/TripMobile';
import styles from './SelectedOffer.module.scss';

interface SelectedOfferProps {
	className?: string;
	offer: Partial<IOffer>;
}

export const SelectedOffer = memo((props: SelectedOfferProps) => {
	const { className, offer } = props;

	const { isMobileAndTablet } = useCustomMediaQuery();

	const castedOffer = offer as IOffer;

	const tripTo = offer.grouped_trips?.[0]?.trips[0];
	const tripBack = offer.grouped_trips?.[1]?.trips[0];
	const refundBefore = offer.refund_before ?? '';

	// Mobile
	if (isMobileAndTablet) {
		const renderContentTo: ToggleRenderFunction = ({ isOpen }) => {
			return (
				<>
					{tripTo && (
						<>
							<Destinations withoutDestinationWord={!tripBack} trip={tripTo} />
							<div className={styles.wrapper}>
								<TripMobile
									baggage={getBaggage(castedOffer)}
									penalty={getPenalty(castedOffer)}
									refundBefore={refundBefore}
									trip={tripTo}
								/>
								{isOpen && (
									<DetailsMobile
										baggage={getBaggage(castedOffer)}
										penalty={getPenalty(castedOffer)}
										refundBefore={refundBefore}
										trip={tripTo}
									/>
								)}
							</div>
						</>
					)}
				</>
			);
		};

		const renderContentBack: ToggleRenderFunction = ({ isOpen }) => {
			return (
				<>
					{tripBack && (
						<>
							<Destinations isBack trip={tripBack} />
							<div className={styles.wrapper}>
								<TripMobile
									baggage={getBaggage(castedOffer, true)}
									penalty={getPenalty(castedOffer, true)}
									refundBefore={refundBefore}
									trip={tripBack}
									isBack
								/>
								{isOpen && (
									<DetailsMobile
										baggage={getBaggage(castedOffer, true)}
										penalty={getPenalty(castedOffer, true)}
										refundBefore={refundBefore}
										trip={tripBack}
									/>
								)}
							</div>
						</>
					)}
				</>
			);
		};

		return (
			<div className={styles.mobileInner}>
				{tripTo && (
					<div className={styles.mobileWrapper}>
						<ToggledBlock
							reversed
							renderTrigger={({ isOpen }) => <Expand isOpen={isOpen} />}
							renderContent={renderContentTo}
							staticContent
							classNameTrigger={styles.toogle}
							closeOtherToogle={false}
						/>
					</div>
				)}

				{tripBack && (
					<div className={styles.mobileWrapper}>
						<ToggledBlock
							reversed
							renderTrigger={({ isOpen }) => <Expand isOpen={isOpen} />}
							renderContent={renderContentBack}
							staticContent
							classNameTrigger={styles.toogle}
							closeOtherToogle={false}
						/>
					</div>
				)}
			</div>
		);
	}

	// Desktop
	const renderContent: ToggleRenderFunction = ({ isOpen }) => {
		return (
			<div className={cn(styles.selectedOffer, { [styles.withBack]: tripBack }, className)}>
				{tripTo && (
					<div className={styles.wrapper}>
						<div className={styles.header}>
							<Destinations withoutDestinationWord={!tripBack} trip={tripTo} />

							{!isOpen && (
								<>
									<Penalty penalty={getPenalty(castedOffer)} refundBefore={refundBefore} />
									<BaggageInfo className={styles.baggage} baggage={getBaggage(castedOffer)} />
								</>
							)}
						</div>

						<Trip
							isOpen={isOpen}
							baggage={getBaggage(castedOffer)}
							penalty={getPenalty(castedOffer)}
							refundBefore={refundBefore}
							trip={tripTo}
						/>
					</div>
				)}

				{tripBack && (
					<>
						<span className={styles.divider} />
						<div className={styles.wrapper}>
							<div className={styles.header}>
								<Destinations isBack trip={tripBack} />
								{!isOpen && (
									<>
										<Penalty penalty={getPenalty(castedOffer, true)} refundBefore={refundBefore} />
										<BaggageInfo
											className={styles.baggage}
											baggage={getBaggage(castedOffer, true)}
										/>
									</>
								)}
							</div>

							<Trip
								isOpen={isOpen}
								baggage={getBaggage(castedOffer, true)}
								penalty={getPenalty(castedOffer, true)}
								refundBefore={refundBefore}
								trip={tripBack}
							/>
						</div>
					</>
				)}
			</div>
		);
	};

	return (
		<div className='container-wl'>
			<ToggledBlock
				reversed
				className={cn({ [styles.half]: !tripBack })}
				classNameTrigger={styles.toogle}
				renderTrigger={({ isOpen }) => <Expand isOpen={isOpen} />}
				renderContent={renderContent}
				staticContent
				closeOtherToogle={false}
			/>
		</div>
	);
});

SelectedOffer.displayName = 'SelectedOffer';
