import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { priceFormat } from '@shared/lib/helpers';
import { Checkbox } from '@shared/ui/Checkbox/Checkbox';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { getLowerPriceByBaggage } from '../../../lib/filters/baggage/getLowerPrices';
import { getBaggageFilter } from '../../../model/selectors/baggageSelectors';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { type IBaggageFilter, baggageActions } from '../../../model/slices/filters/baggageSlice/baggageSlice';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Trigger } from '../Trigger/Trigger';
import styles from './BaggageFilter.module.scss';

export const BaggageFilter = memo(() => {
	const baggage = useSelector(getBaggageFilter);
	const initialOffers = useSelector(getInitialOffers);
	const { t } = useTranslation('filters');

	const { isMobileAndTablet } = useCustomMediaQuery();

	const dispatch = useAppDispatch();

	const lowerPriceWithBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: true, without: false });
	}, [initialOffers]);

	const lowerPriceWithoutBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: false, without: true });
	}, [initialOffers]);

	const onChange = (key: keyof IBaggageFilter) => {
		return () => {
			dispatch(baggageActions.toggleValue(key));
		};
	};

	const isDisabled = !lowerPriceWithBaggage || !lowerPriceWithoutBaggage;

	const BaggageFilterContent = () => (
		<div className={styles.wrapper}>
			<Checkbox
				text={
					<span className={styles.text}>
						{t('s-bagazhom')}{' '}
						<span className={styles.price}>
							{t('price', {
								ns: 'filters',
								price: priceFormat(lowerPriceWithBaggage ?? 0),
								currency: symbolCurrency[appSettings.appCurrency],
							})}
						</span>
					</span>
				}
				checked={baggage.with}
				disabled={isDisabled}
				onChange={onChange('with')}
			/>
			<Checkbox
				text={
					<span className={styles.text}>
						{t('bez-bagazha')}{' '}
						<span className={styles.price}>
							{t('price', {
								ns: 'filters',
								price: priceFormat(lowerPriceWithoutBaggage ?? 0),
								currency: symbolCurrency[appSettings.appCurrency],
							})}
						</span>
					</span>
				}
				disabled={isDisabled}
				checked={baggage.without}
				onChange={onChange('without')}
			/>
		</div>
	);

	if (isMobileAndTablet) {
		return (
			<MobileFilter title={t('bagazh')}>
				<BaggageFilterContent />
			</MobileFilter>
		);
	}

	return (
		<ToggledBlock
			renderTrigger={({ isOpen }) => <Trigger text={t('bagazh')} isOpen={isOpen} />}
			renderContent={BaggageFilterContent}
			closeOtherToogle={false}
		/>
	);
});
