import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { promocodeApi } from '../../api/promocodeApi';
import { IGetPromocodeResp } from '../types/i.promo';

export interface IPromosState {
	data: IGetPromocodeResp | null;
	userPromocode: string;
}

const initialState: IPromosState = {
	data: null,
	userPromocode: '',
};

export const promocodeSlice = createSlice({
	name: 'promocode',
	initialState,
	reducers: {
		setUserPromocode: (state, action: PayloadAction<string>) => {
			state.userPromocode = action.payload;
		},
		resetPromo: (state) => {
			state.data = null;
			state.userPromocode = '';
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(promocodeApi.endpoints.setPromocode.matchFulfilled, (state, { payload }) => {
			state.data = payload;
		});
	},
});

export const { reducer: promocodeReducer, actions: promocodeActions } = promocodeSlice;
