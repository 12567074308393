import { type FC, type ReactNode } from 'react';

import { useAppSettings } from '@shared/appSettings';

interface AppSettingsProviderProps {
	children: ReactNode;
}

const AppSettingsProvider: FC<AppSettingsProviderProps> = ({ children }) => {
	useAppSettings();

	return children;
};

export default AppSettingsProvider;
