export const actionpay = () => {
	(function (w: Window, d: Document) {
		try {
			const el = 'getElementsByTagName',
				rs = 'readyState';
			if (d[rs] !== 'interactive' && d[rs] !== 'complete') {
				const c = arguments.callee;
				return setTimeout(function () {
					c(w, d);
				}, 100);
			}
			const s = d.createElement('script');
			s.type = 'text/javascript';
			s.async = true;
			s.src = '//aprtx.com/code/biletix/';
			const p = d[el]('body')[0] || d[el]('head')[0];
			if (p) p.appendChild(s);
		} catch (x) {
			console.log(x);
		}
	})(window, document);
};
