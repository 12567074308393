import { isEmpty } from 'lodash';

import { offersApi } from '@entities/Offer';
import { paymentApi } from '@entities/Payment';
import { extraSeatApi, orderApi } from '@entities/Personal';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { finishApi } from '@steps/FinishStep';

export interface IError {
	code: string | undefined;
	message?: string;
}

export interface IPopupErrorState {
	error: IError;
}

const initialState: IPopupErrorState = {
	error: {
		code: '',
		message: '',
	},
};

export const popupErrorSlice = createSlice({
	name: 'popupError',
	initialState,
	reducers: {
		setError: (state, action: PayloadAction<Pick<IError, 'code'>>) => {
			state.error.code = action.payload.code;
		},
		resetCode: (state) => {
			state.error = { code: '', message: '' };
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(offersApi.endpoints.getOffers.matchFulfilled, (state, { payload }) => {
			if (payload?.error.code !== 'OK') {
				state.error = {
					code: payload?.error.code,
					message: payload?.error.description,
				};
			}
		});

		builder.addMatcher(offersApi.endpoints.getOffers.matchFulfilled, (state, { payload }) => {
			if (payload?.error.code === 'OK' && isEmpty(payload.data)) {
				state.error = {
					code: 'NOT_FOUND',
				};
			}
		});

		builder.addMatcher(offersApi.endpoints.getOffers.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_GET_OFFERS_REJECTED',
			};
		});

		builder.addMatcher(offersApi.endpoints.selectOffer.matchFulfilled, (state, { payload }) => {
			if (!payload?.ok) {
				state.error = {
					code: payload?.code,
					message: payload?.description,
				};
			}
		});

		builder.addMatcher(offersApi.endpoints.selectOffer.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_SELECT_OFFER_REJECTED',
			};
		});

		// ----------
		// extraSeatApi
		// ----------
		builder.addMatcher(extraSeatApi.endpoints.getExtraSeat.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_GET_EXTRA_SEAT_REJECTED',
			};
		});

		builder.addMatcher(extraSeatApi.endpoints.getExtraSeat.matchFulfilled, (state, { payload }) => {
			if (!payload?.ok) {
				state.error = {
					code: payload?.error.code,
					message: payload?.error?.description,
				};
			}
		});

		// ----------
		// orderApi
		// ----------
		builder.addMatcher(orderApi.endpoints.createOrder.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error.code,
					message: payload?.error.message,
				};
			}
		});

		builder.addMatcher(orderApi.endpoints.createOrder.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_CREATE_ORDER_REJECTED',
			};
		});

		builder.addMatcher(orderApi.endpoints.getPaymentMethods.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.message,
				};
			}
		});

		builder.addMatcher(orderApi.endpoints.getPaymentMethods.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_GET_PAYMENT_METHODS_REJECTED',
			};
		});

		builder.addMatcher(orderApi.endpoints.getOrderServices.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(orderApi.endpoints.getOrderServices.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_GET_ORDER_SERVICES_REJECTED',
			};
		});

		// ----------
		// paymentApi
		// ----------
		builder.addMatcher(paymentApi.endpoints.setPayment.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(paymentApi.endpoints.setPayment.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_SET_PAYMENT_REJECTED',
			};
		});

		builder.addMatcher(paymentApi.endpoints.addOrderService.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code || 'CUSTOM_ADD_ORDER_SERVICE_REJECTED',
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(paymentApi.endpoints.addOrderService.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_ADD_ORDER_SERVICE_REJECTED',
			};
		});

		builder.addMatcher(paymentApi.endpoints.addOrderItem.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_ADD_ORDER_ITEM_REJECTED',
			};
		});

		builder.addMatcher(paymentApi.endpoints.addOrderItem.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code || 'CUSTOM_ADD_ORDER_ITEM_REJECTED',
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(paymentApi.endpoints.book.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.description ?? payload?.error?.message,
				};
			}
		});

		builder.addMatcher(paymentApi.endpoints.book.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_BOOK_REJECTED',
			};
		});

		builder.addMatcher(paymentApi.endpoints.startPayment.matchFulfilled, (state, { payload }) => {
			if (!payload.ok) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(paymentApi.endpoints.startPayment.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_START_PAYMENT_REJECTED',
			};
		});

		builder.addMatcher(finishApi.endpoints.getOrderStatus.matchFulfilled, (state, { payload }) => {
			// Ошибки перечисленные ниже обрабатываются в компоненте FinishStep
			if (
				!payload.ok &&
				!(
					payload?.error?.code === 'NOT_PAYED' ||
					payload?.error?.code === 'NOT_BOOKED' ||
					payload?.error?.code === 'CANCELED' ||
					payload?.error?.code === 'NOT_PAID' ||
					payload?.error?.code === 'NOT_FOUND' ||
					payload?.error?.code === 'MANUAL_ISSUE' ||
					payload?.error?.code === 'CONNECTION_ERROR' ||
					payload?.error?.code === 'SYSTEM_ERROR'
				)
			) {
				state.error = {
					code: payload?.error?.code,
					message: payload?.error?.description,
				};
			}
		});

		builder.addMatcher(finishApi.endpoints.getOrderStatus.matchRejected, (state) => {
			state.error = {
				code: 'CUSTOM_GET_ORDER_STATUS_REJECTED',
			};
		});
	},
});

export const { reducer: popupErrorReducer, actions: popupErrorActions } = popupErrorSlice;
