import cn from 'classnames';

import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { Altdomain, appSettings } from '../../../../appSettings';
import { useCustomMediaQuery } from '../../../../hooks/useMediaQuery';
import styles from './DefaultBannerLoader.module.scss';

export const DefaultBannerLoader = () => {
	const ref = useRef<boolean>(false);
	const [index, setIndex] = useState(1);
	const { isMobile } = useCustomMediaQuery();

	useEffect(() => {
		const intervalId = setInterval(() => {
			setIndex((prev) => (prev === 4 ? 1 : prev + 1));
		}, 3000);

		return () => clearInterval(intervalId);
	}, []);

	function getImageUrl() {
		const altDomain = appSettings.appAltDomain;

		switch (altDomain) {
			case Altdomain.BILETIX_DE_DESKTOP:
				return new URL(`../../../../assets/banners/de/desktop/${index}.png`, import.meta.url).href;

			case Altdomain.BILETIX_DE_MOBILE:
				return new URL(`../../../../assets/banners/de/mobile/${index}.png`, import.meta.url).href;

			case Altdomain.BILETIX_ZH_DESKTOP:
				return new URL(`../../../../assets/banners/zh/desktop/${index}.png`, import.meta.url).href;

			case Altdomain.BILETIX_ZH_MOBILE:
				return new URL(`../../../../assets/banners/zh/mobile/${index}.png`, import.meta.url).href;

			default:
				if (isMobile) {
					if (!ref.current) {
						ref.current = true;
					}

					return new URL(`../../../../assets/banners/plane-mobile-${index}.png`, import.meta.url).href;
				}

				return new URL(`../../../../assets/banners/plane-${index}.png`, import.meta.url).href;
		}
	}

	return (
		<>
			{createPortal(
				<div className={cn('banner', styles.wrapper)}>
					<div className={cn(styles.banner, { [styles.plane]: ref.current })}>
						<img className={styles.img} src={getImageUrl()} alt='Баннер загрузки' />

						<div className={styles.progress} />
					</div>
				</div>,
				document.body,
			)}
		</>
	);
};
