import { IOffer } from '@entities/Offer';

import { IHotFilter } from '../../../model/slices/filters/hotSlice/hotSlice';

export const filterReturnable = (offer: IOffer, filters: IHotFilter) => {
	if (!filters.returnable) {
		return true;
	}

	return offer.penalty === 'returnable' || offer.penalty === 'optionaltax';
};
