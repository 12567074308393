import { createApi } from '@reduxjs/toolkit/query/react';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

import { ICity } from '../model/types/ISearchDirection';

export interface IGetCitiesParams {
	q: string;
	lang: string;
	lid?: string;
}

export const searchDirectionApi = createApi({
	reducerPath: 'searchDirectionApi',
	tagTypes: [''],
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		getCities: build.query<ICity[], IGetCitiesParams>({
			query: ({ q, lang, lid = '' }) => {
				return {
					url: `/get_cities/`,
					params: {
						newbackend: 1,
						q: q,
						lang: lang,
						country: 'ru',
						lid: lid,
					},
					responseHandler: async (response) => {
						const result = await response.json();
						return result.data;
					},
				};
			},
		}),
	}),
});

export const { useLazyGetCitiesQuery } = searchDirectionApi;
