import { ITrip } from '@entities/Offer';

import { TEndpoint } from '../../model/slices/filters/endpointsSlice/endpointsSlice';
import { timeToSecs } from '../timeToSecs';

type FillFiltersParams = {
	duration: number[];
	acompanies: Record<string, number[]>;
	price: number;
	endpoints: Record<keyof TEndpoint, number[]>;
	transfers: Record<string, number[]>;
	airports: {
		airportsDeparture: Record<string, number[]>;
		airportsArrival: Record<string, number[]>;
		airportsTransfers: Record<string, number[]>;
	};
};

/*
 * Функция fillFilters, которая принимает массив поездок и набор параметров,
 * а затем обрабатывает trip для заполнения различных структур данных фильтра такой информацией,
 * как продолжительность поездки, авиакомпании, аэропорты и цены. Он просматривает каждый trip,
 * сегменты и аэропорты и соответствующим образом обновляет данные фильтра.
 */
export const fillFilters = (trips: ITrip[], params: FillFiltersParams) => {
	const { duration, acompanies, price, endpoints, transfers, airports } = params;

	trips.forEach((trip) => {
		const segmentsLength = trip.segments.length;

		const transfersCount = segmentsLength - 1;

		if (!transfers[transfersCount]) {
			transfers[transfersCount] = [];
		}

		transfers[transfersCount].push(price);

		duration.push(trip.duration);

		trip.segments.forEach((segment, index) => {
			const acname = segment.airline.name;

			// Если нет такого авиакомпании в списке, то добавляем авиакомпанию, например {PC: []}
			if (!acompanies[acname]) {
				acompanies[acname] = [];
			}

			// Добавляем цену в массив цен для конкретного авиакомпании
			//  Например: {PC: [27913, 22487, ...]}
			acompanies[acname].push(price);

			// Добавляем время вылета
			if (index === 0) {
				endpoints.departure.push(timeToSecs(segment.departure_time));
			}

			// Добавляем время прилета последнего сегмента
			if (index === segmentsLength - 1) {
				endpoints.arrival.push(timeToSecs(segment.arrival_time));
			}

			// Аэропорты
			const departureAirport = segment.departure.city;
			const arrivalAirport = segment.arrival.city;
			const { airportsArrival, airportsDeparture, airportsTransfers } = airports;

			// Если нет такого аэропорта в списке, то добавляем {Sabiha Gokcen: []}
			if (!airportsDeparture[departureAirport] && index === 0) {
				airportsDeparture[departureAirport] = [];
			}

			if (!airportsArrival[arrivalAirport] && index === segmentsLength - 1) {
				airportsArrival[arrivalAirport] = [];
			}

			// Если сегмента больше одного, значит есть транзитные сегменты
			// Исключаем первый и нулевой сегмент
			if (transfersCount > 0 && index !== 0 && !airportsTransfers[departureAirport]) {
				airportsTransfers[departureAirport] = [];
			}

			// Добавляем цену в массив цен для конкретного аэропорта для arrival и departure
			// Например: {Sabiha Gokcen: [27913, 22487, ...]}
			if (segmentsLength === 1) {
				airportsArrival[arrivalAirport].push(price);
				airportsDeparture[departureAirport].push(price);
				return;
			}

			if (index === 0) {
				airportsDeparture[departureAirport].push(price);
				return;
			}

			if (index === segmentsLength - 1) {
				airportsArrival[arrivalAirport].push(price);
			}

			airportsTransfers[departureAirport].push(price);
		});
	});
};
