import { IOffer } from '@entities/Offer';

import { IPriceFilter } from '../../../model/slices/filters/priceSlice/priceSlice';

export const filterPrice = (offer: IOffer, filters: IPriceFilter) => {
	if (!filters.currentPrice) {
		return true;
	}

	const offerPrice = Number(offer.price);
	const min = filters.currentPrice[0];
	const max = filters.currentPrice[1];

	return offerPrice <= max && offerPrice >= min;
};
