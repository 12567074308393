import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getPenaltyStatus } from '@shared/lib/helpers';
import { Aviacompany } from '@shared/ui/Aviacompany/Aviacompany';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { ITrip } from '../../model/types/i.offer';
import { BaggageInfo } from '../BaggageInfo/BaggageInfo';
import { TripTrack } from '../TripTrack/TripTrack';
import styles from './TripMobile.module.scss';

interface TripMobileProps {
	className?: string;
	trip: ITrip;
	baggage: IResultBaggageInfo;
	isBack?: boolean;
	penalty: IPenalty;
	refundBefore: string;
}

export const TripMobile = memo((props: TripMobileProps) => {
	const { baggage, isBack, penalty, trip, refundBefore } = props;
	const { t } = useTranslation('filters');
	const { segmentEnd, segmentStart } = useMemo(
		() => ({
			segmentStart: trip.segments[0],
			segmentEnd: trip.segments.at(-1),
		}),
		[trip],
	);

	return (
		<div className={styles.small}>
			<div className={styles.info}>
				<Aviacompany
					tooltipPlacement='top-start'
					view='small'
					airline={{ airlineInfo: segmentStart.airline, airlineNumber: segmentStart.number }}
				/>
				<p className={styles.time}>{segmentStart.departure_time}</p>
				<TripTrack trip={trip} />
				<p className={styles.time}>{segmentEnd?.arrival_time ?? ''}</p>
				<BaggageInfo tooltipPlacement='top-end' baggage={baggage} />
			</div>
			<div className={styles.penalty}>
				<p>{isBack ? t('obratno') : t('tuda')}</p>
				<span className={styles.dotes} />
				<p>{getPenaltyStatus(refundBefore || penalty?.at(0))}</p>
			</div>
		</div>
	);
});

TripMobile.displayName = 'TripMobile';
