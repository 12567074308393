import { createApi } from '@reduxjs/toolkit/query/react';
import { appSettings } from '@shared/appSettings';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

import { IAddOrderItemParams, IAddOrderItemResp } from '../model/types/i.add-order-item';
import { IAddOrderServiceParams, IAddOrderServiceResp } from '../model/types/i.add-order-service';
import { IBookParams, IBookResp } from '../model/types/i.book';
import { IOrderTicketingParams, IOrderTicketingResp } from '../model/types/i.order-ticketing';
import { ISetPaymentParams, ISetPaymentResp } from '../model/types/i.set-payment';
import { IStartPaymentParams, IStartPaymentResp } from '../model/types/i.start-payment';

export const paymentApi = createApi({
	reducerPath: 'paymentApi',
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		setPayment: build.query<ISetPaymentResp, ISetPaymentParams>({
			query: ({ orderId, paymentId, sessionToken }) => {
				return {
					url: `/set_payment_method/`,
					params: {
						newbackend: 1,
						date: new Date().getMilliseconds(),
						altDomain: appSettings.appAltDomain,
						currentCurrency: appSettings.appCurrency,
						donot_init_payment: 'Y',
						order_id: orderId,
						pay_method_id: paymentId,
						session_token: sessionToken,
						lid: appSettings.appLid,
					},
				};
			},
		}),
		addOrderService: build.query<IAddOrderServiceResp, IAddOrderServiceParams>({
			query: ({ orderId, sessionToken, services }) => {
				return {
					url: `/add_order_service/`,
					params: {
						newbackend: 1,
						date: new Date().getMilliseconds(),
						session_token: sessionToken,
						altDomain: appSettings.appAltDomain,
						currentCurrency: appSettings.appCurrency,
						order_id: orderId,
						services: JSON.stringify(services),
						lid: appSettings.appLid,
						userTicket: '',
					},
				};
			},
		}),
		addOrderItem: build.query<IAddOrderItemResp, IAddOrderItemParams>({
			query: ({ orderId, sessionToken, promocode, price }) => {
				return {
					url: `/add_order_item/`,
					params: {
						newbackend: 1,
						date: new Date().getMilliseconds(),
						session_token: sessionToken,
						altDomain: appSettings.appAltDomain,
						order_id: orderId,
						code: 'VOUCHER',
						name: promocode,
						price: price,
						count: '1',
						hash: '',
						lid: appSettings.appLid,

						userTicket: '',
					},
				};
			},
		}),
		book: build.query<IBookResp, IBookParams>({
			query: ({
				order_id,
				session_token,
				lang,
				bonus_card_dep_last_segment,
				departure_first_segment_datetime,
				arrival_last_segment_datetime,
				origin_iata,
				children,
				infants,
				destination_iata,
				ak,
				classCabin,
				departure_first_segment_datetime_return,
				arrival_last_segment_datetime_return,
				stops_count,
				payment_method,
				add_data,
			}) => {
				return {
					url: `/book/`,
					params: {
						lang: lang,
						newbackend: 1,
						order_id: order_id,
						session_token: session_token,
						bonus_card_number: '',
						bonus_card_vendo: '',
						bonus_card_dep_last_segment: bonus_card_dep_last_segment,
						departure_first_segment_datetime: departure_first_segment_datetime,
						arrival_last_segment_datetime: arrival_last_segment_datetime,
						origin_iata: origin_iata,
						children_count: children,
						infants_count: infants,
						destination_iata: destination_iata,
						ak: ak,
						class: classCabin,
						departure_first_segment_datetime_return: departure_first_segment_datetime_return,
						arrival_last_segment_datetime_return: arrival_last_segment_datetime_return,
						stops_count: stops_count,
						payment_method: payment_method,
						currentCurrency: appSettings.appCurrency,
						userTicket: '',
						altDomain: appSettings.appAltDomain,
						donot_init_payment: 'Y',
						time: new Date().getMilliseconds() / Math.random(),
						add_data: add_data,
					},
				};
			},
		}),
		startPayment: build.query<IStartPaymentResp, IStartPaymentParams>({
			query: ({ orderId, sessionToken, additionalData, backUrl, backUrlData }) => {
				return {
					url: `/start_payment/`,
					params: {
						newbackend: 1,
						back_url_data: JSON.stringify(backUrlData),
						order_id: orderId,
						session_token: sessionToken,
						altDomain: appSettings.appAltDomain,
						currentCurrency: appSettings.appCurrency,
						userTicket: '',
						lid: appSettings.appLid,
						time: new Date().getMilliseconds() / Math.random(),
						additional_data: JSON.stringify(additionalData),
						donot_init_payment: 'N',
						back_url: backUrl,
					},
				};
			},
		}),
		orderTicketing: build.query<IOrderTicketingResp, IOrderTicketingParams>({
			query: ({ orderId, sessionToken }) => {
				return {
					url: `/order_ticketing/${orderId}/`,
					params: {
						newbackend: 1,
						date: new Date().getMilliseconds(),
						session_token: sessionToken,
						altDomain: appSettings.appAltDomain,
						lid: appSettings.appLid,
						currentCurrency: appSettings.appCurrency,
						userTicket: '',
					},
				};
			},
		}),
	}),
});

export const {
	useLazySetPaymentQuery,
	useLazyAddOrderServiceQuery,
	useLazyAddOrderItemQuery,
	useLazyBookQuery,
	useLazyStartPaymentQuery,
	useLazyOrderTicketingQuery,
	useOrderTicketingQuery,
} = paymentApi;
