import { IOffer } from '@entities/Offer';

import { IBaggageFilter } from '../../../model/slices/filters/baggageSlice/baggageSlice';
import { filterBaggage } from './filterBaggage';

export const getLowerPriceByBaggage = (offers: IOffer[] | null, filters: IBaggageFilter) => {
	if (!offers) {
		return null;
	}

	let min: null | number = null;

	offers.forEach((offer: IOffer) => {
		const pass = filterBaggage(offer, filters);

		if (!pass) {
			return;
		}

		const price = Number(offer.price);

		if (!min) {
			min = price;
			return;
		}

		if (price < min) {
			min = price;
		}
	});

	return min;
};
