import cn from 'classnames';

import { Fragment, PropsWithChildren } from 'react';

import { Dialog, DialogPanel, Transition } from '@headlessui/react';

import CloseIcon from '../../assets/icons/close.svg?react';
import Button from '../Button/Button';
import styles from './Modal.module.scss';

type ModalType = 'centeredContent' | 'topContent';

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	type?: ModalType;
	paddingTop?: string;
	className?: string;
}

export const Modal = (props: PropsWithChildren<ModalProps>) => {
	const { isOpen, onClose, paddingTop = 25, type = 'centeredContent', className, children } = props;

	return (
		<Transition
			show={isOpen}
			enter='transition duration-300 ease-out'
			enterFrom='transform scale-95 opacity-0'
			enterTo='transform scale-100 opacity-100'
			leave='transition duration-300 ease-out'
			leaveFrom='transform scale-100 opacity-100'
			leaveTo='transform scale-95 opacity-0'
			as={Fragment}
		>
			<Dialog className={styles.modal} onClose={onClose}>
				<div className={styles.overlay} aria-hidden='true' />

				<div style={type === 'topContent' ? { paddingTop } : {}} className={cn(styles.inner, styles[type])}>
					<DialogPanel className={cn(styles.content, className)}>
						<Button className={cn(styles.btn)} icon={CloseIcon} variant='clear' onClick={onClose}></Button>
						{children}
					</DialogPanel>
				</div>
			</Dialog>
		</Transition>
	);
};
