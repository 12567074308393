import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CloseIcon from '@shared/assets/icons/close.svg?react';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import Button from '@shared/ui/Button/Button';
import IconSvg from '@shared/ui/IconSvg/IconSvg';

import { getHotReturnable } from '../../../model/selectors/hotSelectors';
import { hotActions } from '../../../model/slices/filters/hotSlice/hotSlice';
import styles from '../HotFilters.module.scss';
import RubleIcon from './ruble.svg?react';

export const HotReturnableFilter = () => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();
	const isHotReturnable = useSelector(getHotReturnable);

	const onChange = () => dispatch(hotActions.toggleReturnable());

	return (
		<>
			<Button
				onClick={onChange}
				icon={RubleIcon}
				className={cn(styles.button, {
					[styles.active]: isHotReturnable,
				})}
				color='c4'
				size={isMobileAndTablet ? 'size_s' : 'size_m'}
				block
			>
				{t('vozvratnye')}
				{isHotReturnable && <IconSvg Svg={CloseIcon} className={styles.close} />}
			</Button>
		</>
	);
};
