import { useEffect } from 'react';

export type MutationCallback = (mutationList: MutationRecord[], observer: MutationObserver) => void;
export type ObserverOptions = MutationObserverInit & { callback: MutationCallback };

export const useMutationObserver = (
	domNodeSelector: string,
	observerOptions: ObserverOptions,
	cb: MutationCallback,
): void => {
	useEffect(() => {
		const targetNode = document.querySelector(domNodeSelector);

		if (targetNode != null) {
			const observer = new MutationObserver(cb);

			observer.observe(targetNode, observerOptions);

			return () => {
				observer.disconnect();
			};
		}
	}, [domNodeSelector, observerOptions, cb]);
};
