import cn from 'classnames';

import { ReactNode, useMemo, useState } from 'react';

import { Placement, autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react-dom';

import { useCustomMediaQuery } from '../../hooks/useMediaQuery';
import styles from './WithTooltip.module.scss';

export type IWithTooltipPlacement = Placement;

interface WithTooltipProps {
	className?: string;
	classNameTip?: string;
	children: ReactNode;
	tip: ReactNode;
	initialPlacement?: Placement;
}

export const WithTooltip = (props: WithTooltipProps) => {
	const { children, className, classNameTip, tip, initialPlacement } = props;
	const { isMobileAndTabletCart } = useCustomMediaQuery();

	const [isHover, setIsHover] = useState(false);

	const { refs, floatingStyles, placement } = useFloating({
		open: isHover,
		placement: initialPlacement ?? (isMobileAndTabletCart ? 'top-end' : 'top'),
		middleware: [offset(10), flip(), shift()],
		whileElementsMounted: autoUpdate,
	});

	const handlers = useMemo(() => {
		return {
			onMouseLeave: () => setIsHover(false),
			onMouseEnter: () => setIsHover(true),
		};
	}, []);

	return (
		<>
			<div className={cn(styles.wrapper, className)} ref={refs.setReference} {...handlers}>
				{children}
			</div>
			{isHover && (
				<div
					{...handlers}
					ref={refs.setFloating}
					style={floatingStyles}
					className={cn(styles.tip, classNameTip, styles[placement])}
				>
					{tip}
				</div>
			)}
		</>
	);
};
