import i18next from 'i18next';

import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { priceFormat } from '@shared/lib/helpers';

import { getAviacompanyPrices } from '../../../model/selectors/aviacompanySelectors';

export const useGetLowerPrice = () => {
	const prices = useSelector(getAviacompanyPrices);

	const getPrice = useCallback(
		(acName: string) => {
			if (!prices[acName]) {
				return '';
			}

			return i18next.t('price', {
				ns: 'filters',
				price: priceFormat(Math.min(...prices[acName])),
				currency: symbolCurrency[appSettings.appCurrency],
			});
		},
		[prices],
	);

	return { getPrice };
};
