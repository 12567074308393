import { createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';
import { baggageActions } from '../baggageSlice/baggageSlice';
import { transferActions } from '../transferSlice/transferSlice';

export interface IHotFilter {
	baggage: boolean;
	returnable: boolean;
	early: boolean;
	evening: boolean;
	transfer: boolean;
}

const initialState: IHotFilter = {
	baggage: false,
	returnable: false,
	early: false,
	evening: false,
	transfer: false,
};

export const hotSlice = createSlice({
	name: 'hot',
	initialState,
	reducers: {
		toggleBaggage(state) {
			state.baggage = !state.baggage;
		},
		toggleReturnable(state) {
			state.returnable = !state.returnable;
		},
		toggleEarly(state) {
			state.early = !state.early;
			state.evening = false;
		},
		toggleEvening(state) {
			state.evening = !state.evening;
			state.early = false;
		},
		toggleTransfer(state) {
			state.transfer = !state.transfer;
		},
		reset(state) {
			state.baggage = false;
			state.returnable = false;
			state.early = false;
			state.evening = false;
			state.transfer = false;
		},
	},

	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			hotSlice.caseReducers.reset(state);
		});
		builder.addCase(transferActions.toggleAnyCount, (state) => {
			if (state.transfer) {
				hotSlice.caseReducers.toggleTransfer(state);
			}
		});
		builder.addCase(transferActions.toggleTransferCount, (state) => {
			if (state.transfer) {
				hotSlice.caseReducers.toggleTransfer(state);
			}
		});
		builder.addCase(baggageActions.toggleValue, (state) => {
			if (state.baggage) {
				hotSlice.caseReducers.toggleBaggage(state);
			}
		});
	},
});

export const { actions: hotActions, reducer: hotReducer } = hotSlice;
