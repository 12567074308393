import { IOffer } from '@entities/Offer';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IOffersList {
	filteredOffers: IOffer[] | null;
	visibleOffersCount: number;
	initialOffers: IOffer[] | null;
}

const initialState: IOffersList = {
	initialOffers: null,
	filteredOffers: null,
	visibleOffersCount: 10,
};

export const offersListSlice = createSlice({
	name: 'offerList',
	initialState,
	reducers: {
		setInitialOffers(state, action: PayloadAction<IOffer[]>) {
			state.filteredOffers = action.payload;
			state.initialOffers = action.payload;
		},
		setFilteredOffers(state, action: PayloadAction<IOffer[]>) {
			state.filteredOffers = action.payload;
			state.visibleOffersCount = 10;
		},
		incrementVisibleOffersCount(state) {
			state.visibleOffersCount = state.visibleOffersCount + 10;
		},
		resetFilters(state) {
			state.filteredOffers = null;
			state.visibleOffersCount = 10;
		},
	},
});

export const { actions: offersListActions, reducer: offersListReducer } = offersListSlice;
