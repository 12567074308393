import { type IOffer, getBaggage, isRt } from '@entities/Offer';

import { type IBaggageFilter } from '../../../model/slices/filters/baggageSlice/baggageSlice';

export const filterBaggage = (offer: IOffer, filters: IBaggageFilter) => {
	if (filters.with && filters.without) {
		return true;
	}

	if (!filters.with && !filters.without) {
		return false;
	}

	const baggageTo = getBaggage(offer);
	const baggageBack = isRt(offer) ? getBaggage(offer, true) : null;

	// Только с багажом
	if (filters.with) {
		if (baggageTo.baggageKg || baggageTo.baggagePlaces) {
			return true;
		}

		if (!baggageBack) {
			return false;
		}

		if (baggageBack.baggageKg || baggageBack.baggagePlaces) {
			return true;
		}

		return false;
	}

	// Только без багажа
	if (baggageTo.baggageKg || baggageTo.baggagePlaces) {
		return false;
	}

	if (baggageBack) {
		if (baggageBack.baggageKg || baggageBack.baggagePlaces) {
			return false;
		}
	}

	return true;
};
