import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import Button from '@shared/ui/Button/Button';

import { offersListActions } from '../../../model/slices/offerListSlice';
import styles from './ResetFilter.module.scss';

export const ResetFilter = memo(() => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();

	const onReset = () => dispatch(offersListActions.resetFilters());

	return (
		<div className={styles.wrapper}>
			{isMobileAndTablet ? (
				<Button variant='clear' className={styles.btn} onClick={onReset}>
					{t('sbrosit')}
				</Button>
			) : (
				<Button variant='clear' className={styles.btn} onClick={onReset} size='size_l'>
					{t('ochistit-vse-filtry')}
				</Button>
			)}
		</div>
	);
});
