import { isBiletixKZ } from '@shared/const';

export const ga = () => {
	(function (w: Window, d: Document, s: 'script', l: 'dataLayer', i: string) {
		w[l] = w[l] || [];
		w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
		const f = d.getElementsByTagName(s)[0];
		const j = d.createElement(s);
		const dl = l != 'dataLayer' ? `&l=${l}` : '';
		j.async = true;
		j.src = j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
		f.parentNode?.insertBefore(j, f);
	})(window, document, 'script', 'dataLayer', isBiletixKZ ? 'GTM-5KZ5PM8' : 'GTM-5CFXH2');
};
