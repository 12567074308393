import { type ISearchParams } from '@entities/Search';
import { createApi } from '@reduxjs/toolkit/query/react';
import { appSettings } from '@shared/appSettings';
import { dynamicBaseQuery } from '@shared/appSettings/lib/dynamicBaseQuery';

import { ICreateOrderResp } from '../model/types/i.create-order';
import { IOrderServicesResp } from '../model/types/i.order-services';
import { IPaymentMethodResp } from '../model/types/i.payment-methods';
import { type IPersonalFieldsForm } from '../model/types/i.personal-form';

export interface IContacts {
	phone: string;
	email: string;
	agree: boolean;
}

// create_order_pure
export interface ICreateOrderParams {
	search: ISearchParams;
	passengers: IPersonalFieldsForm[];
	contacts: IContacts;
	session_token: string;
}

//  add_user_profiles
export interface IAddUserProfileParams {
	user_id?: string;
	passengers: IPersonalFieldsForm[];
	session_token: string;
}

// get_order_services
export interface IGetOrderServicesParams {
	order_id: string;
	lang: string;
}

// get_payment_methods
export interface IGetPaymentMethodsParams {
	order_id: string;
	session_token: string;
}

export const orderApi = createApi({
	reducerPath: 'orderApi',
	tagTypes: [''],
	baseQuery: dynamicBaseQuery,
	endpoints: (build) => ({
		createOrder: build.query<ICreateOrderResp, ICreateOrderParams>({
			query: ({
				search: { adults, children, infants, date_from, date_to, from, to },
				passengers,
				contacts,
				session_token,
			}) => {
				return {
					url: `/create_order_pure/`,
					params: {
						newbackend: 1,
						date: new Date().getMilliseconds(),
						adults,
						children,
						infants,
						contacts: JSON.stringify(contacts),
						currentCurrency: appSettings.appCurrency,
						dep_date: date_from,
						ret_date: date_to,
						external_fees_for_passcat: JSON.stringify({ infant: 0, adult: 0, child: 0 }),
						from: from?.split(',')[1].trim(),
						to: to?.split(',')[1].trim(),
						partner: appSettings.appAltDomain,
						user_id: '',
						userTicket: '',
						session_token,
						passengers: JSON.stringify(passengers),
						altDomain: appSettings.appAltDomain,
					},
				};
			},
		}),
		addUserProfiles: build.query<unknown, IAddUserProfileParams>({
			query: ({ user_id, session_token, passengers }) => {
				return {
					url: `/add_user_profiles/`,
					params: {
						newbackend: 1,
						user_id,
						session_token,
						altDomain: 'SOAP',
						time: new Date().getMilliseconds() / Math.random(),
						passengers: JSON.stringify(passengers),
					},
				};
			},
		}),
		getOrderServices: build.query<IOrderServicesResp, IGetOrderServicesParams>({
			query: ({ order_id, lang }) => {
				return {
					url: `/get_order_services/`,
					params: {
						newbackend: 1,
						order_id,
						date: new Date().getMilliseconds(),
						altDomain: appSettings.appAltDomain,
						lang,
					},
				};
			},
		}),
		getPaymentMethods: build.query<IPaymentMethodResp, IGetPaymentMethodsParams>({
			query: ({ order_id, session_token }) => {
				return {
					url: `/get_payment_methods/`,
					params: {
						dateforrandom: new Date().getMilliseconds(),
						newbackend: 1,
						order_id,
						session_token,
						allowCash: 1,
						altDomain: appSettings.appAltDomain,
						currentCurrency: appSettings.appCurrency,
					},
				};
			},
		}),
	}),
});

export const {
	useLazyCreateOrderQuery,
	useLazyAddUserProfilesQuery,
	useLazyGetOrderServicesQuery,
	useLazyGetPaymentMethodsQuery,
	useGetOrderServicesQuery,
	useGetPaymentMethodsQuery,
} = orderApi;
