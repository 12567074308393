import cn from 'classnames';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { secondsToDhm } from '@shared/lib/helpers';
import { WithTooltip } from '@shared/ui/WithTooltip/WithTooltip';

import { ITrip } from '../../model/types/i.offer';
import styles from './TripTrack.module.scss';

interface TripTrackProps {
	className?: string;
	trip: ITrip;
}

export const TripTrack = memo((props: TripTrackProps) => {
	const { t } = useTranslation('filters');
	const { className, trip } = props;
	const { isMobileAndTablet } = useCustomMediaQuery();

	const hours = t('ch');
	const minutes = t('m');
	const days = t('d');

	return (
		<div className={cn(styles.tripTrack, { [styles.small]: isMobileAndTablet }, className)}>
			<div className={styles.airports}>
				<div className={cn(styles.start, styles.airport)}>
					{trip.segments[0].departure.id} <span className={styles.circle} />
				</div>
				{trip.segments.map((segment, index) => {
					if (segment.time_to_wait.length > 0) {
						return (
							<WithTooltip
								key={index}
								tip={`${t('peresadka-v-aeroportu')} (${segment.arrival.name}) ${
									segment.arrival.city_name
								}`}
							>
								<div className={cn(styles.stop, styles.airport)}>
									{segment.arrival.id} <span className={cn(styles.circle, styles.emptyCircle)} />
								</div>
							</WithTooltip>
						);
					}
				})}
				<div className={cn(styles.end, styles.airport)}>
					{trip.segments.at(-1)?.arrival.id} <span className={styles.circle} />
				</div>
			</div>
			<span className={styles.track} />
			<p className={styles.duration}>
				{!isMobileAndTablet && `${t('v-puti')} `}
				{secondsToDhm(trip.duration, { days: days, hours: hours, minutes: minutes })}
			</p>
		</div>
	);
});
