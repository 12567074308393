import { createContext } from 'react';

import { ThemeType } from '@shared/types';

export interface IThemeContextProps {
	theme?: ThemeType;
	setTheme?: (theme: ThemeType) => void;
}

export const ThemeContext = createContext<IThemeContextProps>({});
