import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { getEndpointsFilter } from '../../../model/selectors/endpointsSelectors';
import { TSetCurrentPayload } from '../../../model/slices/filters/endpointsSlice/endpointsSlice';
import { timeRangeToString } from '../../timeRangeToString';

export const useGetEndpointsText = () => {
	const { t } = useTranslation('filters');
	const { current, initial } = useSelector(getEndpointsFilter);

	const getEndpointsText = ({ direction, point }: Omit<TSetCurrentPayload, 'value'>) => {
		const tmpCurrent = current[direction];
		const tmpInitial = initial[direction];

		if (!tmpCurrent || !tmpInitial) {
			return '';
		}

		const currentValue = tmpCurrent[point];
		const initialValue = tmpInitial[point];

		if (currentValue[0] === initialValue[0] && currentValue[1] === initialValue[1]) {
			return t('lyuboe-vremya');
		}

		return timeRangeToString(currentValue);
	};

	return { getEndpointsText };
};
