import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export type TDuration = [number, number];

export interface IDurationFilter {
	initialDuration: {
		to: TDuration | null;
		back: TDuration | null;
	};
	currentDuration: {
		to: number | null;
		back: number | null;
	};
}

const initialState: IDurationFilter = {
	currentDuration: {
		back: null,
		to: null,
	},
	initialDuration: {
		back: null,
		to: null,
	},
};

type TPayloadInitial = {
	to: number[];
	back: number[] | null;
};

export const durationSlice = createSlice({
	name: 'duration',
	initialState,
	reducers: {
		setInitialDuration(state, action: PayloadAction<TPayloadInitial>) {
			const { to, back } = action.payload;

			const parsed: IDurationFilter['initialDuration'] = {
				to: [Math.min(...to), Math.max(...to)],
				back: back ? [Math.min(...back), Math.max(...back)] : null,
			};

			state.initialDuration = parsed;

			state.currentDuration = {
				to: parsed.to ? parsed.to[1] : null,
				back: parsed.back ? parsed.back[1] : null,
			};
		},

		setCurrentDuration(state, action: PayloadAction<IDurationFilter['currentDuration']>) {
			state.currentDuration = action.payload;
		},

		reset(state) {
			const { to, back } = state.initialDuration;

			state.currentDuration = {
				to: to ? to[1] : null,
				back: back ? back[1] : null,
			};
		},
	},

	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			durationSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: durationActions, reducer: durationReducer } = durationSlice;
