import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Nullable } from '@shared/lib/typescript/Nullable';

import { offersListActions } from '../../offerListSlice';
import { IAirportsFilter, fillDirection, fillTransfer } from './fillDirection';

export type TStateDirectionVariants = Record<string, boolean> & { all: boolean };

export type TDirectionVariants = Record<string, number[]>;

export interface IStateAirportsFilter {
	departure: TStateAirportsDirection;
	arrival: TStateAirportsDirection;
	transfers: TStateAirportsTransfer;
}

export type TStateAirportsDirection = {
	to: TStateDirectionVariants;
	back: Nullable<TStateDirectionVariants>;
	prices: Record<string, number>;
};

export type TStateAirportsTransfer = {
	airports: TStateDirectionVariants;
	prices: Record<string, number>;
};

const initialState: IStateAirportsFilter = {
	departure: {
		to: {
			all: true,
		},
		prices: {},
		back: null,
	},
	arrival: {
		to: {
			all: true,
		},
		prices: {},
		back: null,
	},
	transfers: {
		airports: {
			all: true,
		},
		prices: {},
	},
};

export const airportsSlice = createSlice({
	name: 'airports',
	initialState,
	reducers: {
		toggleActive(
			state,
			action: PayloadAction<{
				direction: 'to' | 'back' | 'airports';
				point: keyof IStateAirportsFilter;
				name: string;
			}>,
		) {
			const { direction, name, point } = action.payload;

			if (!state[point]) {
				return;
			}

			let tmp;

			if (Object.prototype.hasOwnProperty.call(state[point], 'transfers')) {
				const transferState = state[point] as TStateAirportsTransfer;
				tmp = transferState.airports;
			} else {
				const directionState = state[point] as TStateAirportsDirection;
				tmp = directionState[direction];
			}

			if (!tmp) {
				return;
			}

			if (name === 'all') {
				// Если фильтр "Все" в true, то установить фильтр "Все" в false
				tmp[name] = !tmp[name];

				// Пройтись по всем фильтрам и выставить значение равное фильтру "Все"
				for (const key in tmp) {
					if (key !== 'all') {
						tmp[key] = tmp[name];
					}
				}

				return;
			}

			tmp[name] = !tmp[name];

			// Если хоть один фильтр в false, то установить фильтр "Все" в false
			// Если все  фильтр в true, то установить фильтр "Все" в true
			for (const key in tmp) {
				if (!tmp[key] && key !== 'all') {
					tmp['all'] = false;
					return;
				}

				tmp['all'] = true;
			}
		},
		setInitialAirports(state, action: PayloadAction<IAirportsFilter>) {
			const { arrival, departure, transfers } = action.payload;

			fillDirection(departure, state.departure);
			fillDirection(arrival, state.arrival);
			fillTransfer(transfers, state.transfers);
		},
		reset(state) {
			for (const key in state.departure.to) state.departure.to[key] = true;

			for (const key in state.arrival.to) state.arrival.to[key] = true;

			for (const key in state.transfers.airports) state.transfers.airports[key] = true;

			if (state.departure.back) {
				for (const key in state.departure.back) state.departure.back[key] = true;
			}

			if (state.arrival.back) {
				for (const key in state.arrival.back) state.arrival.back[key] = true;
			}
		},
	},
	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			airportsSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: airportsActions, reducer: airportsReducer } = airportsSlice;
