import { IOffer } from '@entities/Offer';

export const getCitiesName = (offer: IOffer) => {
	const result: Record<'cityDeparture' | 'cityArrival', null | string> = {
		cityDeparture: null,
		cityArrival: null,
	};

	result.cityDeparture = offer.grouped_trips[0].trips[0].segments[0].departure.city_name;
	result.cityArrival = offer.grouped_trips[0].trips[0].segments.at(-1)?.arrival.city_name ?? '';

	return result;
};
