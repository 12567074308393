import { isEmpty } from 'lodash';

import { useCallback } from 'react';

import { type IOffer, isRt } from '@entities/Offer';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';

import { fillFilters } from '../lib/filters/fillFilters';
import {
	// TAirportsDirection,
	// TAirportsTransfer,
	airportsActions,
} from '../model/slices/filters/airportsFilter/airportsFilter';
import { aviacompanyActions } from '../model/slices/filters/aviacompanySlice/aviacompanySlice';
import { durationActions } from '../model/slices/filters/durationSlice/durationSlice';
import { type TEndpoint, endpointsActions } from '../model/slices/filters/endpointsSlice/endpointsSlice';
import { priceActions } from '../model/slices/filters/priceSlice/priceSlice';
import { transferActions } from '../model/slices/filters/transferSlice/transferSlice';

/* Пользовательский хук useSetInitialFilters,
 * который собирает данные для фильтров и передает их в хранилище Redux.
 * Он перебирает список предложений, извлекает различные точки данных, связанные с ценами, трансферами,
 * аэропортами и продолжительностью, и отправляет действия для установки
 * исходных данных в хранилище Redux для этих фильтров. */

export type TAirportsTransfer = {
	airports: Record<string, number[]>;
};

export type TAirportsDirection = {
	to: Record<string, number[]>;
	back: Record<string, number[]>;
};
export const useSetInitialFilters = () => {
	const dispatch = useAppDispatch();

	const setInitialFilters = useCallback(
		(offers: IOffer[]) => {
			if (isEmpty(offers)) {
				return;
			}

			const isRtOffers = isRt(offers[0]);

			/* Инициализируем переменные для фильтров */
			const prices: number[] = [];
			const transfers: Record<string, number[]> = {};
			const airportsDeparture: TAirportsDirection = {
				to: {},
				back: {},
			};
			const airportsArrival: TAirportsDirection = {
				to: {},
				back: {},
			};
			const airportsTransfers: TAirportsTransfer = {
				airports: {},
			};
			const durationTo: number[] = [];
			const durationBack: number[] = [];
			const acompanies: Record<string, number[]> = {};
			const endpointsTo: Record<keyof TEndpoint, number[]> = {
				departure: [],
				arrival: [],
			};
			const endpointsBack: Record<keyof TEndpoint, number[]> = {
				departure: [],
				arrival: [],
			};

			/* Заполняем фильтры */
			for (const offer of offers) {
				const price = Number(offer.price);
				prices.push(price);

				fillFilters(offer.grouped_trips[0].trips, {
					acompanies,
					price,
					duration: durationTo,
					endpoints: endpointsTo,
					transfers,
					airports: {
						airportsDeparture: airportsDeparture.to,
						airportsArrival: airportsArrival.to,
						airportsTransfers: airportsTransfers.airports,
					},
				});

				if (isRt(offer)) {
					fillFilters(offer.grouped_trips[1].trips, {
						acompanies,
						price,
						duration: durationBack,
						endpoints: endpointsBack,
						transfers,
						airports: {
							airportsDeparture: airportsDeparture.back,
							airportsArrival: airportsArrival.back,
							airportsTransfers: airportsTransfers.airports,
						},
					});
				}
			}

			/* Устанавливаем данные в store */
			// priceSlice
			dispatch(priceActions.setInitialPrice(prices));

			// transferSlice
			dispatch(transferActions.setInitialTransfers(transfers));

			// endpointsSlice
			dispatch(
				endpointsActions.setInitialEndpoints({
					to: endpointsTo,
					back: isRtOffers ? endpointsBack : null,
				}),
			);

			// aviacompanySlice
			dispatch(aviacompanyActions.setInitialData(acompanies));

			// durationSlice
			dispatch(
				durationActions.setInitialDuration({
					to: durationTo,
					back: isRtOffers ? durationBack : null,
				}),
			);

			// airportsSlice
			dispatch(
				airportsActions.setInitialAirports({
					departure: airportsDeparture,
					arrival: airportsArrival,
					transfers: airportsTransfers,
				}),
			);
		},
		[dispatch],
	);

	return { setInitialFilters };
};
