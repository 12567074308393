import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ModalMobile } from '@entities/Offer';
import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { priceFormat, slantWords } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';

import { getFilteredOffers, getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { HotFilters } from '../../HotFilters/HotFilters';
import { AirportsFilter } from '../AirportsFilter/AirportsFilter';
import { AviacompanyFilter } from '../AviacompanyFilter/AviacompanyFilter';
import { BaggageFilter } from '../BaggageFilter/BaggageFilter';
import { DurationFilter } from '../DurationFilter/DurationFilter';
import { EndpointsFilter } from '../EndpointsFilter/EndpointsFilter';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { PriceFilter } from '../PriceFilter/PriceFilter';
import { ResetFilter } from '../ResetFilter/ResetFilter';
import { TransferFilter } from '../TransferFilter/TransferFilter';
import styles from './Filters.module.scss';

const FiltersContent = () => (
	<div className={styles.filters}>
		{/* Пересадки */}
		<TransferFilter />

		{/* Время вылета и прилета */}
		<EndpointsFilter />

		{/* Багаж */}
		<BaggageFilter />

		{/* Цена билета */}
		<PriceFilter />

		{/* Время в пути */}
		<DurationFilter />

		{/* Авиакомпании */}
		<AviacompanyFilter />

		{/* Аэропорты */}
		<AirportsFilter />

		{/* Сброс фильтров */}
		<ResetFilter />
	</div>
);

const ModalTitle = () => {
	const { t } = useTranslation('filters');
	const filteredOffers = useSelector(getFilteredOffers);
	const initialOffers = useSelector(getInitialOffers);

	return (
		<div className={styles.headerInfo}>
			{initialOffers && (
				<p>
					{filteredOffers?.length} из {initialOffers.length}{' '}
					{slantWords(initialOffers.length, t('reisa', { returnObjects: true }))}
				</p>
			)}
			{filteredOffers && filteredOffers.length > 0 && (
				<p className={styles.headerPrice}>
					{t('price', {
						ns: 'filters',
						price: priceFormat(filteredOffers[0].price),
						currency: symbolCurrency[appSettings.appCurrency],
					})}
				</p>
			)}
		</div>
	);
};

export const Filters = memo(() => {
	const { t } = useTranslation('filters');
	const { isMobileAndTablet } = useCustomMediaQuery();
	const [openModal, setIsOpenModal] = useState(false);

	const toggleModalOpen = () => {
		setIsOpenModal((prev) => !prev);
	};

	if (isMobileAndTablet) {
		return (
			<>
				<Button color='c5' className={styles.trigger} onClick={toggleModalOpen}>
					{openModal ? t('primenit') : t('filtrovat')}
				</Button>

				<ModalMobile isOpen={openModal} onClose={toggleModalOpen} title={<ModalTitle />}>
					<MobileFilter title={'Быстрые фильтры'}>
						<div className={styles.hotFilters}>
							<HotFilters />
						</div>
					</MobileFilter>

					<FiltersContent />
				</ModalMobile>
			</>
		);
	}

	return <FiltersContent />;
});
