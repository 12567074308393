import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { PARTNERS_BILETIX_URL } from '@shared/const/api';

import { IGetPromocodeParams, IGetPromocodeResp } from '../model/types/i.promo';

export const promocodeApi = createApi({
	reducerPath: 'promocodeApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${PARTNERS_BILETIX_URL}`,
		prepareHeaders: (headers) => {
			return headers;
		},
	}),
	endpoints: (build) => ({
		setPromocode: build.query<IGetPromocodeResp, IGetPromocodeParams>({
			query: ({ promocode, orderId }) => {
				return {
					url: `/api/get/promocode/`,
					params: {
						promocode,
						orderid: orderId,
					},
				};
			},
		}),
	}),
});

export const { useLazySetPromocodeQuery } = promocodeApi;
