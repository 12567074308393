import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IService {
	description: string;
	type: string;
	id: string;
	count: number;
	passenger_id: null;
	segment_id: null;
	applicability_passengers: string[];
	applicability_segments: string[];
	price: number[];
}

export interface IOrderServicesState {
	orderServices: IService[];
	orderServicesPrice: number;
}

const initialState: IOrderServicesState = {
	orderServices: [],
	orderServicesPrice: 0,
};

export const orderServicesSlice = createSlice({
	name: 'orderServices',
	initialState,
	reducers: {
		setOrderService: (state, action: PayloadAction<IService>) => {
			state.orderServices.push(action.payload);
			state.orderServicesPrice = state.orderServices.reduce(
				(acc, cur) => acc + cur.price.reduce((acc1, cur1) => acc1 + cur1, 0),
				0,
			);
		},
		deleteOrderService: (state, action: PayloadAction<string>) => {
			state.orderServices = state.orderServices.filter((el) => el.id !== action.payload);
			state.orderServicesPrice = state.orderServices.reduce(
				(acc, cur) => acc + cur.price.reduce((acc1, cur1) => acc1 + cur1, 0),
				0,
			);
		},
		resetOrderService: (state) => {
			state.orderServices = [];
			state.orderServicesPrice = 0;
		},
	},
});

export const { reducer: orderServicesReducer, actions: orderServicesActions } = orderServicesSlice;
