import { useMediaQuery } from 'react-responsive';

export const useCustomMediaQuery = () => {
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
	const isMobileAndTablet = useMediaQuery({ maxWidth: 991 });
	const isMobileAndTabletCart = useMediaQuery({ maxWidth: 1100 });

	return { isMobile, isTablet, isMobileAndTablet, isMobileAndTabletCart };
};
