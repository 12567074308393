import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ICartShema } from '../types/i.cartShema';

const initialState: ICartShema = {
	cartPrice: 0,
};

export const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		initPrice: (state, action: PayloadAction<number>) => {
			state.cartPrice = action.payload;
		},
		setCartPrice: (state, action: PayloadAction<number>) => {
			state.cartPrice = state.cartPrice + action.payload;
		},
		removeCartPrice: (state, action: PayloadAction<number>) => {
			state.cartPrice = state.cartPrice - action.payload;
		},
	},
});

export const { actions: cartActions } = cartSlice;
export const { reducer: cartReducer } = cartSlice;
