import styled from 'styled-components';

export const SliderWrapper = styled.div`
	.rc-slider {
		background: none;
		padding: 7px 0;
	}

	.rc-slider-rail,
	.rc-slider-track {
		height: 2px;
	}

	.rc-slider-rail {
		background: #818181;
	}

	.rc-slider-track {
		background: var(--slider-color);
	}

	.rc-slider-handle {
		border-color: #818181;
		opacity: 1;
		margin-top: -6px;
	}

	.rc-slider-handle.rc-slider-handle-dragging,
	.rc-slider-handle:active,
	.rc-slider-handle:focus-visible {
		border-color: var(--slider-color);
		box-shadow: 0 0 0 5px var(--slider-handle-shadow-color);
	}

	.rc-slider-disabled {
		.rc-slider-track {
			background: #818181;
		}

		.rc-slider-handle.rc-slider-handle-dragging,
		.rc-slider-handle:active,
		.rc-slider-handle:focus-visible {
			border-color: #818181;
			box-shadow: none;
		}
	}
`;
