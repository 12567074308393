import i18next from 'i18next';
import { entries, isEmpty } from 'lodash';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { priceFormat } from '@shared/lib/helpers';
import { Checkbox } from '@shared/ui/Checkbox/Checkbox';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { getAirportsFilter } from '../../../model/selectors/airportsSelectors';
import { TStateDirectionVariants, airportsActions } from '../../../model/slices/filters/airportsFilter/airportsFilter';
import { CitiesName } from '../CitiesName/CitiesName';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Trigger } from '../Trigger/Trigger';
import styles from './AirportsFilter.module.scss';

export const AirportsFilter = memo(() => {
	const { t } = useTranslation('filters');
	const { isMobileAndTablet } = useCustomMediaQuery();
	const dispatch = useAppDispatch();

	const { arrival, departure, transfers } = useSelector(getAirportsFilter);

	const AirportsDeparture = () => {
		return (
			<div className={styles.wrapper}>
				{departure.back && <CitiesName />}

				{getAirports(departure.to, departure.prices, (name: string) => {
					dispatch(airportsActions.toggleActive({ direction: 'to', point: 'departure', name }));
				})}

				{departure.back && (
					<>
						<CitiesName back />
						{getAirports(departure.back, departure.prices, (name: string) => {
							dispatch(airportsActions.toggleActive({ direction: 'back', point: 'departure', name }));
						})}
					</>
				)}
			</div>
		);
	};

	const AirportsArrival = () => {
		return (
			<div className={styles.wrapper}>
				{arrival.back && <CitiesName />}

				{getAirports(arrival.to, arrival.prices, (name: string) => {
					dispatch(airportsActions.toggleActive({ direction: 'to', point: 'arrival', name }));
				})}

				{arrival.back && (
					<>
						{arrival.back && <CitiesName back />}
						{getAirports(arrival.back, arrival.prices, (name: string) => {
							dispatch(airportsActions.toggleActive({ direction: 'back', point: 'arrival', name }));
						})}
					</>
				)}
			</div>
		);
	};

	const AirportsTransfers = () => {
		return (
			<div className={styles.wrapper}>
				<>
					{getAirports(Object.assign({}, transfers.airports), transfers.prices, (name: string) => {
						dispatch(airportsActions.toggleActive({ direction: 'airports', point: 'transfers', name }));
					})}
				</>
			</div>
		);
	};

	if (isMobileAndTablet) {
		return (
			<>
				<MobileFilter title={t('aeroporty-vyleta')}>
					<AirportsDeparture />
				</MobileFilter>
				<MobileFilter title={t('aeroporty-prileta')}>
					<AirportsArrival />
				</MobileFilter>
				{!isEmpty(transfers.prices) && (
					<MobileFilter title={t('aeroporty-peresadki')}>
						<AirportsTransfers />
					</MobileFilter>
				)}
			</>
		);
	}

	return (
		<>
			<ToggledBlock
				renderContent={AirportsDeparture}
				renderTrigger={({ isOpen }) => <Trigger text={t('aeroporty-vyleta')} isOpen={isOpen} />}
				closeOtherToogle={false}
			/>
			<ToggledBlock
				renderContent={AirportsArrival}
				renderTrigger={({ isOpen }) => <Trigger text={t('aeroporty-prileta')} isOpen={isOpen} />}
				closeOtherToogle={false}
			/>

			{!isEmpty(transfers.prices) && (
				<ToggledBlock
					renderContent={AirportsTransfers}
					renderTrigger={({ isOpen }) => <Trigger text={t('aeroporty-peresadki')} isOpen={isOpen} />}
					closeOtherToogle={false}
				/>
			)}
		</>
	);
});

const getAirports = (
	direction: TStateDirectionVariants,
	prices: Record<string, number>,
	onClick: (name: string) => void,
) => {
	return (
		<>
			{entries(direction).map(([key, value]) => {
				return (
					<Checkbox
						key={key}
						onChange={() => onClick(key)}
						checked={value}
						disabled={entries(direction).length === 2}
						text={
							<span className={styles.text}>
								{key === 'all' ? i18next.t('vse', { ns: 'filters' }) : key}
								{prices[key] && (
									<span className={styles.price}>
										{i18next.t('price', {
											ns: 'filters',
											price: priceFormat(prices[key]),
											currency: symbolCurrency[appSettings.appCurrency],
										})}
									</span>
								)}
							</span>
						}
					/>
				);
			})}
		</>
	);
};
