import cn from 'classnames';

import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocale } from '@shared/hooks/useLocale';
import { formatDateByPatternAndLocale, secondsToDhm } from '@shared/lib/helpers';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { ITrip } from '../../model/types/i.offer';
import { BaggageInfo } from '../BaggageInfo/BaggageInfo';
import { SegmentInfo } from '../SegmentInfo/SegmentInfo';
import { Stops } from '../Stops/Stops';
import styles from './Details.module.scss';

interface DetailsProps {
	className?: string;
	trip: ITrip;
	penalty: IPenalty;
	baggage: IResultBaggageInfo;
	refundBefore: string;
}

export const Details = memo((props: DetailsProps) => {
	const { className, trip, penalty, baggage, refundBefore } = props;
	const { t } = useTranslation('filters');

	const locale = useLocale();

	return (
		<div className={cn(styles.details, className)}>
			{trip.segments.map((segment, s) => {
				return (
					<Fragment key={s}>
						<div className={styles.segment}>
							<p className={styles.duration}>
								{secondsToDhm(segment.duration_in_seconds, {
									days: t('d'),
									hours: t('ch'),
									minutes: t('m'),
								})}
							</p>
							<div className={styles.part}>
								<div className={styles.dates}>
									<div className={styles.flightPoint}>
										<p className={styles.top}>
											<span className={styles.time}>{segment.departure_time}</span>
											<span
												className={styles.city}
											>{`${segment.departure.city} (${segment.departure.id})`}</span>
										</p>
										<p className={styles.date}>
											{formatDateByPatternAndLocale(new Date(segment.start), 'dd MMMM', locale)}
										</p>
									</div>
									<div className={styles.flightPoint}>
										<p className={styles.top}>
											<span className={styles.time}>{segment.arrival_time}</span>
											<span
												className={styles.city}
											>{`${segment.arrival.city} (${segment.arrival.id})`}</span>
										</p>
										<p className={styles.date}>
											{formatDateByPatternAndLocale(new Date(segment.end), 'dd MMMM', locale)}
										</p>
									</div>
								</div>
								<SegmentInfo
									className={styles.segmentData}
									penalty={penalty}
									segment={segment}
									refundBefore={refundBefore}
								/>
								<BaggageInfo baggage={baggage} direction='column' />
							</div>
						</div>
						<Stops className={styles.stopSegment} segment={segment} trip={trip} index={s} />
					</Fragment>
				);
			})}
		</div>
	);
});
