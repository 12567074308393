export enum Altdomain {
	// biletix
	BILETIX_DESKTOP = 'biletix.ru.desktop',
	BILETIX_MOBILE = 'biletix.ru.mobile',
	BILETIX_EN_DESKTOP = 'biletix.en.desktop',
	BILETIX_EN_MOBILE = 'biletix.en.mobile',
	BILETIX_DE_DESKTOP = 'biletix.de.desktop',
	BILETIX_DE_MOBILE = 'biletix.de.mobile',
	BILETIX_ZH_DESKTOP = 'biletix.zh.desktop',
	BILETIX_ZH_MOBILE = 'biletix.zh.mobile',
	BILETIX_KZ_DESKTOP = 'biletix.kz.desktop',
	BILETIX_KZ_MOBILE = 'biletix.kz.mobile',
	FUTURA_TRAVEL = 'futura.travel',
	// biletix, переход по ссылкам
	BILETIX_AVIASALES_KZ = 'biletix.kz.aviasales',
	BILETIX_RU_AVIA_CITY = 'biletix.ru.avia.city',
	BILETIXBLOG = 'biletix.ru.blog',
	// партнеры, переход по ссылкам
	CA = 'ca',
	CITYADS = 'cityads',
	AVIASALES = 'aviasales.ru',
	ACTIONPAY = 'actionpay',
	YANDEX = 'yandex',
	YANDEX_RU = 'yandex.ru',
	HOMECREDITBANK = 'homecreditbank',
	BILETYPLUS = 'biletyplus.ru',
	RASSILKAES = 'rassilkaes',
	PORTRETIX = 'portretix.ru',
	// аэропорты
	SVX_AERO = 'svx.aero',
	GSV_AERO = 'gsv.aero',
	SAMARA_RU = 'airport.samara.ru',
	GOJ_AERO = 'goj.aero',
	OREN_AERO = 'oren.aero',
	NUX_AERO = 'nux.aero',
	RND_RU = 'rnd-airport.ru',
}

export enum Lid {
	// biletix
	BILETIX_DESKTOP = 'sr',
	BILETIX_MOBILE = 'ar',
	BILETIX_EN_DESKTOP = '3w',
	BILETIX_EN_MOBILE = '4z',
	BILETIX_DE_DESKTOP = '3m',
	BILETIX_DE_MOBILE = '28',
	BILETIX_ZH_DESKTOP = '3v',
	BILETIX_ZH_MOBILE = '3n',
	BILETIX_KZ_DESKTOP = 'kz',
	BILETIX_KZ_MOBILE = 'km',
	FUTURA_TRAVEL = 'fk',
	// biletix, переход по ссылкам
	BILETIX_AVIASALES_KZ = '5k',
	BILETIX_RU_AVIA_CITY = 'vr',
	BILETIXBLOG = 'fc',
	// партнеры, переход по ссылкам
	CITYADS = 'op',
	AVIASALES = 'as',
	ACTIONPAY = 'ap',
	YANDEX = 'yd',
	YANDEX_RU = 'yt',
	HOMECREDITBANK = 'qc',
	BILETYPLUS = '1f',
	RASSILKAES = 'ry',
	PORTRETIX = 'ec',
	// аэропорты
	SVX_AERO = 'g3',
	GSV_AERO = '7n',
	SAMARA_RU = 'g5',
	GOJ_AERO = 'g4',
	OREN_AERO = '4g',
	NUX_AERO = 'qk',
	RND_RU = 'g6',
}

export type TMapAltdomain = {
	[key in Altdomain]: Lid;
};

export const MapAltdomain: TMapAltdomain = {
	//biletix
	[Altdomain.BILETIX_DESKTOP]: Lid.BILETIX_DESKTOP,
	[Altdomain.BILETIX_MOBILE]: Lid.BILETIX_MOBILE,
	[Altdomain.BILETIX_EN_DESKTOP]: Lid.BILETIX_EN_DESKTOP,
	[Altdomain.BILETIX_EN_MOBILE]: Lid.BILETIX_EN_MOBILE,
	[Altdomain.BILETIX_DE_DESKTOP]: Lid.BILETIX_DE_DESKTOP,
	[Altdomain.BILETIX_DE_MOBILE]: Lid.BILETIX_DE_MOBILE,
	[Altdomain.BILETIX_ZH_DESKTOP]: Lid.BILETIX_ZH_DESKTOP,
	[Altdomain.BILETIX_ZH_MOBILE]: Lid.BILETIX_ZH_MOBILE,
	[Altdomain.BILETIX_KZ_DESKTOP]: Lid.BILETIX_KZ_DESKTOP,
	[Altdomain.BILETIX_KZ_MOBILE]: Lid.BILETIX_KZ_MOBILE,
	[Altdomain.BILETIX_AVIASALES_KZ]: Lid.BILETIX_AVIASALES_KZ,
	[Altdomain.FUTURA_TRAVEL]: Lid.FUTURA_TRAVEL,
	[Altdomain.BILETIX_RU_AVIA_CITY]: Lid.BILETIX_RU_AVIA_CITY,
	[Altdomain.BILETIXBLOG]: Lid.BILETIXBLOG,
	// партнеры
	[Altdomain.CA]: Lid.CITYADS,
	[Altdomain.CITYADS]: Lid.CITYADS,
	[Altdomain.AVIASALES]: Lid.AVIASALES,
	[Altdomain.ACTIONPAY]: Lid.ACTIONPAY,
	[Altdomain.YANDEX]: Lid.YANDEX,
	[Altdomain.YANDEX_RU]: Lid.YANDEX_RU,
	[Altdomain.HOMECREDITBANK]: Lid.HOMECREDITBANK,
	[Altdomain.BILETYPLUS]: Lid.BILETYPLUS,
	[Altdomain.RASSILKAES]: Lid.RASSILKAES,
	[Altdomain.PORTRETIX]: Lid.PORTRETIX,
	// аэропорты
	[Altdomain.SVX_AERO]: Lid.SVX_AERO,
	[Altdomain.GSV_AERO]: Lid.GSV_AERO,
	[Altdomain.SAMARA_RU]: Lid.SAMARA_RU,
	[Altdomain.GOJ_AERO]: Lid.GOJ_AERO,
	[Altdomain.OREN_AERO]: Lid.OREN_AERO,
	[Altdomain.NUX_AERO]: Lid.NUX_AERO,
	[Altdomain.RND_RU]: Lid.RND_RU,
};
