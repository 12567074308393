import { CombinedState, Reducer, combineReducers } from '@reduxjs/toolkit';

import { airportsReducer, airportsSlice } from './airportsFilter/airportsFilter';
import { aviacompanyReducer, aviacompanySlice } from './aviacompanySlice/aviacompanySlice';
import { baggageReducer, baggageSlice } from './baggageSlice/baggageSlice';
import { durationReducer, durationSlice } from './durationSlice/durationSlice';
import { endpointsReducer, endpointsSlice } from './endpointsSlice/endpointsSlice';
import { hotReducer, hotSlice } from './hotSlice/hotSlice';
import { priceReducer, priceSlice } from './priceSlice/priceSlice';
import { transferReducer, transferSlice } from './transferSlice/transferSlice';

export interface IFiltersState {
	[baggageSlice.name]: ReturnType<typeof baggageReducer>;
	[priceSlice.name]: ReturnType<typeof priceReducer>;
	[durationSlice.name]: ReturnType<typeof durationReducer>;
	[aviacompanySlice.name]: ReturnType<typeof aviacompanyReducer>;
	[endpointsSlice.name]: ReturnType<typeof endpointsReducer>;
	[transferSlice.name]: ReturnType<typeof transferReducer>;
	[airportsSlice.name]: ReturnType<typeof airportsReducer>;
	[hotSlice.name]: ReturnType<typeof hotReducer>;
}

export const filtersReducer: Reducer<CombinedState<IFiltersState>> = combineReducers({
	[baggageSlice.name]: baggageReducer,
	[priceSlice.name]: priceReducer,
	[durationSlice.name]: durationReducer,
	[aviacompanySlice.name]: aviacompanyReducer,
	[endpointsSlice.name]: endpointsReducer,
	[transferSlice.name]: transferReducer,
	[airportsSlice.name]: airportsReducer,
	[hotSlice.name]: hotReducer,
});
