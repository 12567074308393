import { FC, ReactNode } from 'react';
import { DeepPartial } from 'react-hook-form';
import { Provider } from 'react-redux';

import { IStateSchema } from '../config/i.stateSchema';
import { createReduxStore } from '../config/store';

interface StoreProviderProps {
	children: ReactNode;
	initialState?: DeepPartial<IStateSchema>;
}

export const StoreProvider: FC<StoreProviderProps> = ({ children, initialState }) => {
	const store = createReduxStore(initialState as IStateSchema);

	return <Provider store={store}>{children}</Provider>;
};
