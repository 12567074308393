import React, { ErrorInfo, ReactNode } from 'react';

import { sentryTrace } from '@shared/lib/sentryTrace';

interface ErrorBoundaryProps {
	children: ReactNode;
	fallback: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		sentryTrace('ErrorBoundary', { error, errorInfo });
		console.log(error, errorInfo);
	}

	render() {
		const { hasError } = this.state;
		const { children, fallback } = this.props;

		if (hasError) {
			// You can render any custom fallback UI
			return fallback;
		}

		return children;
	}
}

export default ErrorBoundary;
