import cn from 'classnames';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CloseIcon from '@shared/assets/icons/close.svg?react';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import Button from '@shared/ui/Button/Button';
import IconSvg from '@shared/ui/IconSvg/IconSvg';

import { getEndpointsFilter } from '../../../model/selectors/endpointsSelectors';
import { getHotEarly } from '../../../model/selectors/hotSelectors';
import { endpointsActions } from '../../../model/slices/filters/endpointsSlice/endpointsSlice';
import { hotActions } from '../../../model/slices/filters/hotSlice/hotSlice';
import styles from '../HotFilters.module.scss';
import SunIcon from './sun.svg?react';

export const HotEarlyFilter = () => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const { isMobileAndTablet } = useCustomMediaQuery();
	const { initial } = useSelector(getEndpointsFilter);
	const isHotEarly = useSelector(getHotEarly);

	const isBack = initial.back;

	const onChange = () => {
		dispatch(hotActions.toggleEarly());

		if (isHotEarly) {
			if (initial?.to?.departure) {
				dispatch(
					endpointsActions.setCurrent({
						direction: 'to',
						point: 'departure',
						value: [initial?.to?.departure?.[0], initial?.to?.departure?.[1]],
					}),
				);
			}

			if (isBack && initial?.back?.departure) {
				dispatch(
					endpointsActions.setCurrent({
						direction: 'back',
						point: 'departure',
						value: [initial?.back?.departure?.[0], initial?.back?.departure?.[1]],
					}),
				);
			}
		} else {
			if (initial?.to?.departure) {
				dispatch(
					endpointsActions.setCurrent({
						direction: 'to',
						point: 'departure',
						value: [21640, 43240],
					}),
				);
			}

			if (isBack && initial?.back?.departure) {
				dispatch(
					endpointsActions.setCurrent({
						direction: 'back',
						point: 'departure',
						value: [21640, 43240],
					}),
				);
			}
		}
	};

	return (
		<>
			<Button
				onClick={onChange}
				icon={SunIcon}
				className={cn(styles.button, {
					[styles.active]: isHotEarly,
				})}
				color='c5'
				size={isMobileAndTablet ? 'size_s' : 'size_m'}
				block
			>
				{t('utrennie')}
				{isHotEarly && <IconSvg Svg={CloseIcon} className={styles.close} />}
			</Button>
		</>
	);
};
