import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export interface IBaggageFilter {
	with: boolean;
	without: boolean;
}

const initialState: IBaggageFilter = {
	with: true,
	without: true,
};

export const baggageSlice = createSlice({
	name: 'baggage',
	initialState,
	reducers: {
		toggleValue(state, action: PayloadAction<keyof IBaggageFilter>) {
			state[action.payload] = !state[action.payload];
		},
		reset(state) {
			state.with = true;
			state.without = true;
		},
	},

	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			baggageSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: baggageActions, reducer: baggageReducer } = baggageSlice;
