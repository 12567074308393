import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IOffer } from '@entities/Offer';

import { filterAirports } from '../lib/filters/airports/filterAirports';
import { filterAviacompanies } from '../lib/filters/aviacompanies/filterAviacompanies';
import { filterBaggage } from '../lib/filters/baggage/filterBaggage';
import { filterDuration } from '../lib/filters/duration/filterDuration';
import { filterEndpoints } from '../lib/filters/endpoints/filterEndpoints';
import { filterPrice } from '../lib/filters/price/filterPrice';
import { filterReturnable } from '../lib/filters/returnable/returnable';
import { filterTransfers } from '../lib/filters/transfers/filterTransfers';
import { getFilters } from '../model/selectors/offersListSelectors';

/* Пользовательский хук useFilterOffer, возвращает функцию filterOffer,
 * которая принимает offer и возвращает true, если offer проходит фильтры и
 * false в противном случае
 */
export const useFilterOffer = () => {
	// Получает фильтры из store
	const filters = useSelector(getFilters);

	const filterOffer = useCallback(
		(offer: IOffer) => {
			let pass = true;

			pass = filterBaggage(offer, filters.baggage);

			if (pass) {
				pass = filterPrice(offer, filters.price);
			}

			if (pass) {
				pass = filterDuration(offer, filters.duration);
			}

			if (pass) {
				pass = filterAviacompanies(offer, filters.aviacompany);
			}

			if (pass) {
				pass = filterEndpoints(offer, filters.endpoints);
			}

			if (pass) {
				pass = filterTransfers(offer, filters.transfer);
			}

			if (pass) {
				pass = filterAirports(offer, filters.airports);
			}

			if (pass) {
				pass = filterReturnable(offer, filters.hot);
			}

			return pass;
		},
		[filters],
	);

	return { filterOffer };
};
