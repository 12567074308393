import cn from 'classnames';

import { memo } from 'react';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { ITrip } from '../../model/types/i.offer';
import { Destinations } from '../Destinations/Destinations';
import { DetailsMobile } from '../DetailsMobile/DetailsMobile';
import styles from './FlightModalMobile.module.scss';

interface FlightModalMobileProps {
	className?: string;
	tripTo: ITrip;
	tripBack: ITrip | null;
	penaltyTo: IPenalty;
	penaltyBack: IPenalty;
	baggageTo: IResultBaggageInfo;
	baggageBack: IResultBaggageInfo | null;
}

export const FlightModalMobile = memo((props: FlightModalMobileProps) => {
	const { className, baggageTo, penaltyTo, tripBack, tripTo, baggageBack, penaltyBack } = props;
	return (
		<div className={cn(styles.wrapper, className)}>
			<div className={styles.content}>
				<Destinations className={styles.destinations} withoutDestinationWord={!tripBack} trip={tripTo} />
				<DetailsMobile baggage={baggageTo} penalty={penaltyTo} trip={tripTo} />

				{tripBack && baggageBack && (
					<>
						<Destinations trip={tripBack} isBack />
						<DetailsMobile baggage={baggageBack} penalty={penaltyBack} trip={tripBack} />
					</>
				)}
			</div>
		</div>
	);
});

FlightModalMobile.displayName = 'FlightModalMobile';
