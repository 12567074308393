import { useContext } from 'react';

import { LOCAL_STORAGE_THEME_KEY } from '@shared/const/localstorage';
import { ThemeType } from '@shared/types';

import { ThemeContext } from './ThemeContext';

interface useThemeProps {
	theme?: ThemeType;
	toogleTheme: (theme: ThemeType) => void;
}

export const useTheme = (): useThemeProps => {
	const { theme, setTheme } = useContext(ThemeContext);

	const toogleTheme = (theme: ThemeType): void => {
		setTheme?.(theme ?? 'light');
		localStorage.setItem(LOCAL_STORAGE_THEME_KEY, theme ?? 'light');
	};

	return {
		theme,
		toogleTheme,
	};
};
