import { entries, keys } from 'lodash';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { getTransfer, priceFormat } from '@shared/lib/helpers';
import { Checkbox } from '@shared/ui/Checkbox/Checkbox';
import { ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { getMinTransferPrice, getTransfersFilter } from '../../../model/selectors/transfersSelectors';
import { transferActions } from '../../../model/slices/filters/transferSlice/transferSlice';
import { MobileFilter } from '../MobileFilter/MobileFilter';
import { Trigger } from '../Trigger/Trigger';
import styles from './TransferFilter.module.scss';

export const TransferFilter = memo(() => {
	const { t } = useTranslation('filters');
	const { anyCount, transferCounts, prices } = useSelector(getTransfersFilter);
	const minPrice = useSelector(getMinTransferPrice);
	const dispatch = useAppDispatch();

	const { isMobileAndTablet } = useCustomMediaQuery();

	if (keys(transferCounts).length < 2) {
		return null;
	}

	const handleToggleAll = () => {
		dispatch(transferActions.toggleAnyCount());
	};

	const handleToggleCount = (key: string) => {
		return () => {
			dispatch(transferActions.toggleTransferCount(key));
		};
	};

	const TransfersContent = () => {
		return (
			<div className={styles.wrapper}>
				{entries(transferCounts).map(([key, value]) => (
					<Checkbox
						checked={value}
						key={key}
						text={
							<span className={styles.text}>
								{getTransfer(key)}

								<span className={styles.price}>
									{t('price', {
										ns: 'filters',
										price: priceFormat(prices[key]),
										currency: symbolCurrency[appSettings.appCurrency],
									})}
								</span>
							</span>
						}
						onChange={handleToggleCount(key)}
						withoutHoverBg
					/>
				))}

				<Checkbox
					checked={anyCount}
					key={'anyCount'}
					text={
						<span className={styles.text}>
							{t('lyuboe-kolichestvo')}

							<span className={styles.price}>
								{t('price', {
									ns: 'filters',
									price: priceFormat(minPrice),
									currency: symbolCurrency[appSettings.appCurrency],
								})}
							</span>
						</span>
					}
					onChange={handleToggleAll}
					withoutHoverBg
				/>
			</div>
		);
	};

	if (isMobileAndTablet) {
		return (
			<MobileFilter title={t('peresadki')}>
				<TransfersContent />
			</MobileFilter>
		);
	}

	return (
		<ToggledBlock
			renderContent={TransfersContent}
			renderTrigger={({ isOpen }) => <Trigger text={t('peresadki')} isOpen={isOpen} />}
			closeOtherToogle={false}
		/>
	);
});
