/* eslint-disable */

export const cityadsMainTRM = () => {
	(function (d) {
		var xscr = d.createElement('script');
		xscr.async = true;
		xscr.src = '//x.cnt.my/async/track/?r=' + Math.random();
		var x = d.getElementById('xcntmyAsync');
		x?.parentNode?.insertBefore(xscr, x);
	})(document);
};
