import { useCallback, useEffect, useState } from 'react';

import { isRt } from '../lib/isRt';
import { IOffer, ITrip } from '../model/types/i.offer';

export const useOfferActiveTrips = (offer: IOffer) => {
	const [activeTripTo, setActiveTripTo] = useState<ITrip>(offer.grouped_trips[0].trips[0]);
	const [activeTripBack, setActiveTripBack] = useState<ITrip | null>(offer.grouped_trips[1]?.trips[0] ?? null);

	useEffect(() => {
		setActiveTripTo(offer.grouped_trips[0].trips[0]);
		if (isRt(offer)) {
			setActiveTripBack(offer.grouped_trips[1].trips[0]);
		} else {
			setActiveTripBack(null);
		}
	}, [offer]);

	const onTripChangeTo = useCallback((trip: ITrip) => {
		setActiveTripTo(trip);
	}, []);

	const onTripChangeBack = useCallback((trip: ITrip) => {
		setActiveTripBack(trip);
	}, []);

	return { activeTripBack, activeTripTo, onTripChangeTo, onTripChangeBack };
};
