import cn from 'classnames';

import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';
import { priceFormat } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';

import { getLowerPriceByBaggage } from '../../../lib/filters/baggage/getLowerPrices';
import { getHotBaggage } from '../../../model/selectors/hotSelectors';
import { getInitialOffers } from '../../../model/selectors/offersListSelectors';
import { baggageActions } from '../../../model/slices/filters/baggageSlice/baggageSlice';
import { hotActions } from '../../../model/slices/filters/hotSlice/hotSlice';
import styles from '../HotFilters.module.scss';
import BaggageIcon from './baggage.svg?react';

export const HotDarkBaggageFilter = () => {
	const { t } = useTranslation('filters');
	const dispatch = useAppDispatch();
	const isHotBaggage = useSelector(getHotBaggage);
	const initialOffers = useSelector(getInitialOffers);

	const lowerPriceWithBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: true, without: false });
	}, [initialOffers]);

	const lowerPriceWithoutBaggage = useMemo(() => {
		return getLowerPriceByBaggage(initialOffers, { with: false, without: true });
	}, [initialOffers]);

	const onChange = () => {
		dispatch(baggageActions.reset());

		if (!isHotBaggage) {
			dispatch(baggageActions.toggleValue('without'));
		}

		dispatch(hotActions.toggleBaggage());
	};

	if (!lowerPriceWithBaggage || !lowerPriceWithoutBaggage) {
		return <></>;
	}

	return (
		<Button
			onClick={onChange}
			icon={BaggageIcon}
			className={cn(styles.button, {
				[styles.active]: isHotBaggage,
			})}
			variant='clear'
			color='c5'
			textPosition='space-around'
			size='size_m'
		>
			<div className={styles.description}>
				<span>{t('tolko-s-bagazhom')}</span>
				<span className={styles.price}>
					{t('price', {
						ns: 'filters',
						price: priceFormat(lowerPriceWithBaggage ?? 0),
						currency: symbolCurrency[appSettings.appCurrency],
					})}
				</span>
			</div>
		</Button>
	);
};
