import cn from 'classnames';

import { Fragment, memo } from 'react';

import { useLocale } from '@shared/hooks/useLocale';
import { formatDateByPatternAndLocale, secondsToDhm } from '@shared/lib/helpers';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { ITrip } from '../../model/types/i.offer';
import { BaggageInfo } from '../BaggageInfo/BaggageInfo';
import { SegmentInfo } from '../SegmentInfo/SegmentInfo';
import { Stops } from '../Stops/Stops';
import styles from './DetailsMobile.module.scss';

interface DetailsMobileProps {
	className?: string;
	trip: ITrip;
	penalty: IPenalty;
	baggage: IResultBaggageInfo;
	refundBefore?: string;
}

export const DetailsMobile = memo((props: DetailsMobileProps) => {
	const { className, baggage, penalty, trip, refundBefore } = props;

	const locale = useLocale();

	return (
		<div className={cn(styles.details, className)}>
			{trip.segments.map((segment, s) => {
				return (
					<Fragment key={s}>
						<div className={styles.segment}>
							<div className={styles.header}>
								<SegmentInfo segment={segment} penalty={penalty} refundBefore={refundBefore ?? ''} />
								<BaggageInfo tooltipPlacement='top-end' baggage={baggage} />
							</div>
							<div className={styles.flightPoint}>
								<span className={styles.time}>{segment.departure_time}</span>
								<span
									className={styles.city}
								>{`${segment.departure.city} (${segment.departure.id})`}</span>
								<p className={styles.date}>
									{formatDateByPatternAndLocale(new Date(segment.start), 'dd MMMM, iiiiii', locale)}
								</p>
							</div>
							<p className={styles.duration}>
								{secondsToDhm(segment.duration_in_seconds, { days: 'д', hours: 'ч', minutes: 'м' })}
								<span>время в пути</span>
							</p>
							<div className={styles.flightPoint}>
								<span className={styles.time}>{segment.arrival_time}</span>
								<span className={styles.city}>{`${segment.arrival.city} (${segment.arrival.id})`}</span>
								<p className={styles.date}>
									{formatDateByPatternAndLocale(new Date(segment.end), 'dd MMMM, iiiiii', locale)}
								</p>
							</div>
						</div>
						<Stops className={styles.stopSegment} segment={segment} trip={trip} index={s} />
					</Fragment>
				);
			})}
		</div>
	);
});

DetailsMobile.displayName = 'DetailsMobile';
