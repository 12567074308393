import { ITrip } from '../model/types/i.offer';

export const getStopsInfo = (trip: ITrip) => {
	let isAnotherAirport = false;
	let isTrain = false;
	let isAuto = false;

	if (trip.segments.length > 1) {
		trip.segments.map((segment, s) => {
			if (trip.segments[s + 1] !== undefined) {
				if (segment.arrival.id !== trip.segments[s + 1].departure.id) {
					isAnotherAirport = true;
				}
			}

			if (
				segment.airline.oak_name &&
				segment.airline.oak_name !== '' &&
				segment.airline.oak !== segment.airline.id
			) {
				if (segment.airline.oak_name.toLowerCase().indexOf('железнодорож') > -1) {
					isTrain = true;
				}

				if (segment.airline.oak_name.toLowerCase().indexOf('авто') > -1) {
					isAuto = true;
				}
			}
		});
	}

	return { isAnotherAirport, isAuto, isTrain };
};
