import cn from 'classnames';

import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { appSettings } from '@shared/appSettings';
import { symbolCurrency } from '@shared/const/dictionary';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { priceFormat } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';
import { Expand } from '@shared/ui/Expand/Expand';
import { ToggleRenderFunction, ToggledBlock } from '@shared/ui/ToggledBlock/ToggledBlock';

import { ReturnLazyGetSelectedOffer } from '../../hooks/useLazyGetSelectedOffer';
import { useOfferActiveTrips } from '../../hooks/useOfferActiveTrips';
import { useResetData } from '../../hooks/useResetData';
import { getBaggage } from '../../lib/getBaggage';
import { getPenalty } from '../../lib/getPenalty';
import { getWarningText } from '../../lib/getWarningText';
import { isRt } from '../../lib/isRt';
import { IOffer } from '../../model/types/i.offer';
import { FlightModalMobile } from '../FlightModalMobile/FlightModalMobile';
import { GroupedTrips } from '../GroupedTrips/GroupedTrips';
import { ModalMobile } from '../ModalMobile/ModalMobile';
import styles from './Offer.module.scss';

interface OfferProps {
	offer: IOffer;
	selectOffer: ReturnLazyGetSelectedOffer['getSelectOfferLazy'];
}

export const Offer = memo((props: OfferProps) => {
	const { t } = useTranslation('filters');
	const { offer, selectOffer } = props;
	const [openModal, setIsOpenModal] = useState(false);

	const resetData = useResetData();
	const navigate = useNavigate();
	const location = useLocation();
	const { isMobileAndTablet } = useCustomMediaQuery();
	const { activeTripBack, activeTripTo, onTripChangeBack, onTripChangeTo } = useOfferActiveTrips(offer);

	const { seatsLeft, transitRequired } = getWarningText(activeTripTo, activeTripBack, offer.transit_visa_required);
	const refundBefore = offer.refund_before ?? '';

	const requestOffer = async () => {
		const response = await selectOffer({
			flightToId: activeTripTo?.flight_id ?? '',
			flightBackId: activeTripBack?.flight_id,
		});

		if (response.data?.ok) {
			navigate(`${location.pathname}?step=3`);
		}
	};

	const toggleModalOpen = () => {
		setIsOpenModal((prev) => !prev);
	};

	const onButtonPriceMobile = () => {
		resetData();
		requestOffer();
	};

	const onButtonPrice = async () => {
		if (isMobileAndTablet) {
			setIsOpenModal(true);
			return;
		}

		resetData();
		requestOffer();
	};

	const renderContent: ToggleRenderFunction = ({ isOpen }) => {
		return (
			<div className={cn(styles.offer)}>
				{/* Левая часть карточки */}
				<div className={cn(styles.wrapper)}>
					<GroupedTrips
						onTripChange={onTripChangeTo}
						activeTrip={activeTripTo}
						groupedTrips={offer.grouped_trips[0]}
						isOpen={isOpen}
						penalty={getPenalty(offer)}
						refundBefore={refundBefore}
						baggage={getBaggage(offer)}
						isRt={isRt(offer)}
					/>

					{isRt(offer) && activeTripBack && (
						<>
							{!isMobileAndTablet && <span className={styles.divider} />}
							<GroupedTrips
								onTripChange={onTripChangeBack}
								activeTrip={activeTripBack}
								groupedTrips={offer.grouped_trips[1]}
								penalty={getPenalty(offer, true)}
								refundBefore={refundBefore}
								baggage={getBaggage(offer, true)}
								isRt={isRt(offer)}
								isOpen={isOpen}
								isBack={true}
							/>
						</>
					)}
				</div>

				{/* Правая часть карточки */}
				<div className={styles.rightZone}>
					<div className={styles.warning}>
						{seatsLeft < 6 && <p>{`${t('ostalos-mest')}: ${seatsLeft}`}</p>}
						{transitRequired && <p>{t('nuzhna-tranzitnaya-viza')}</p>}
					</div>

					<Button
						className={styles.btn}
						onClick={onButtonPrice}
						variant='background'
						textPosition='center'
						color='green'
						block
						size={isMobileAndTablet ? 'size_s' : 'size_m'}
					>
						{`${priceFormat(offer.price)} ${symbolCurrency[appSettings.appCurrency]}`}
					</Button>
				</div>
			</div>
		);
	};

	if (isMobileAndTablet) {
		return (
			<>
				<ModalMobile
					isOpen={openModal}
					onClose={toggleModalOpen}
					title={<p className={styles.flightTitle}>{t('podrobnosti-perelyota')}</p>}
					className={styles.flightModal}
				>
					<FlightModalMobile
						tripTo={activeTripTo}
						tripBack={activeTripBack}
						penaltyTo={getPenalty(offer)}
						penaltyBack={isRt(offer) ? getPenalty(offer, true) : null}
						baggageTo={getBaggage(offer)}
						baggageBack={isRt(offer) ? getBaggage(offer, true) : null}
					/>

					<Button onClick={onButtonPriceMobile} variant='background' textPosition='center' block>
						{t('zabronirovat-za')}
						<span className={styles.flightBtnPrice}>{` ${priceFormat(offer.price)} ${
							symbolCurrency[appSettings.appCurrency]
						}`}</span>
					</Button>
				</ModalMobile>
				{renderContent({ isOpen: undefined })}
			</>
		);
	}

	return (
		<ToggledBlock
			classNameTrigger={styles.toogle}
			renderTrigger={({ isOpen }) => <Expand isOpen={isOpen} />}
			renderContent={renderContent}
			closeOtherToogle={false}
			staticContent
		/>
	);
});
