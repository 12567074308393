import { useLocation } from 'react-router-dom';

import { setAppLidFromUtmSource } from '@shared/appSettings';
import {
	SESSION_STORAGE_IS_BOOK,
	SESSION_STORAGE_OFFER_BACK,
	SESSION_STORAGE_OFFER_TO,
} from '@shared/const/localstorage';
import { FinishStep } from '@steps/FinishStep';
import { PaymentStep } from '@steps/PaymentStep';
import { PersonalStep } from '@steps/PersonalStep';
import { ResultsStep } from '@steps/ResultsStep';
import { SearchStep } from '@steps/SearchStep';

const AppRouter = (): JSX.Element => {
	const { search } = useLocation();
	const params = new URLSearchParams(search);
	const isBook = sessionStorage.getItem(SESSION_STORAGE_IS_BOOK);
	const utmSource = params?.get('utm_source');

	// Устанавливаем appSettings.appLid на основе utm_source
	setAppLidFromUtmSource(utmSource, params);

	// Вспомогательный класс для скрытия контента
	if (!document.body.classList.contains('hidden-content')) {
		document.body.classList.add('hidden-content');
	}

	if (params?.toString()?.includes('from')) {
		return <ResultsStep />;
		// Если ранее был вызван запрос book то пользователь может попасть только на шаг PaymentStep
	} else if (params?.get('step') === '3') {
		// window.location.href = window.location.href.split('?')[0] + '?step=4';
		return isBook ? <PaymentStep /> : <PersonalStep />;
	} else if (params?.get('message')) {
		// Если пользователь ранее выбирал рейс, то после перехода по deeplink'у
		// необходимо удалить данные о ранее выбранном рейсе, чтобы запрос select_offer
		// понял, что нужно отправлять запрос с параметрами для deeplink'а
		sessionStorage.removeItem(SESSION_STORAGE_OFFER_TO);
		sessionStorage.removeItem(SESSION_STORAGE_OFFER_BACK);

		// Если ранее был вызван запрос book то пользователь может попасть только на шаг PaymentStep
		return isBook ? <PaymentStep /> : <PersonalStep />;
	} else if (params?.get('step') === '4') {
		return <PaymentStep />;

		// ?order_id=39109415&bill_ticketing&paymethod=ONLINE_SYNC.MAP_FUTURE_TIN&email=o.krasilnikova@vipservice.ru&altdomain=biletix.ru.desktop
	} else if (params?.toString()?.includes('bill_ticketing')) {
		return <FinishStep />;
	}

	return <SearchStep />;
};

export default AppRouter;
