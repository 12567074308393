import { keys, mapValues, uniq } from 'lodash';

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export interface IAviacompanyFilter {
	selectedCompanies: Record<string, boolean>;
	prices: Record<string, number[]>;
}

const initialState: IAviacompanyFilter = {
	selectedCompanies: { all: true },
	prices: {},
};

export const aviacompanySlice = createSlice({
	name: 'aviacompany',
	initialState,
	reducers: {
		setInitialData(state, action: PayloadAction<Record<string, number[]>>) {
			const valueUniq = mapValues(action.payload, (value) => uniq(value));

			state.prices = valueUniq;

			const companies = keys(valueUniq);
			state.selectedCompanies = { all: true };

			for (const ac of companies) {
				state.selectedCompanies[ac] = true;
			}
		},

		toggleCompany(state, action: PayloadAction<keyof IAviacompanyFilter['selectedCompanies']>) {
			const tmp = state.selectedCompanies[action.payload];

			if (action.payload === 'all') {
				for (const key in state.selectedCompanies) state.selectedCompanies[key] = !tmp;
				return;
			}

			state.selectedCompanies[action.payload] = !tmp;

			for (const key in state.selectedCompanies) {
				if (!state.selectedCompanies[key] && key !== 'all') {
					state.selectedCompanies['all'] = false;
					return;
				}

				state.selectedCompanies['all'] = true;
			}
		},

		reset(state) {
			for (const key in state.selectedCompanies) state.selectedCompanies[key] = true;
		},
	},
	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			aviacompanySlice.caseReducers.reset(state);
		});
	},
});

export const { actions: aviacompanyActions, reducer: aviacompanyReducer } = aviacompanySlice;
