import {
	COOKIE_VIP_ACTIONPAY,
	COOKIE_VIP_CITYADS_CLICK_ID,
	COOKIE_VIP_META_MARKER,
	COOKIE_VIP_UTM_SOURCE,
} from '../../const/cookie';
import { SESSION_STORAGE_TEST_ALTDOMAIN } from '../../const/localstorage';
import { getCookie, setCookie } from '../../lib/helpers';
import { appSettings } from '../appSettings';
import { Altdomain, Lid, MapAltdomain, TMapAltdomain } from '../model/types/i.altdomain';

export const setAppLidFromUtmSource = (utmSource: string | null, params: URLSearchParams): void => {
	const cookieUtmSource = getCookie(COOKIE_VIP_UTM_SOURCE);
	// const testLid = sessionStorage.getItem(SESSION_STORAGE_TEST_LID) ?? '';

	// Необходимо удалить маркер в случае, если lid пришел не по deeplink
	const removeMarker = () => setCookie(COOKIE_VIP_META_MARKER, '', -1);

	// Если utm_source передан то блок пропускает этот шаг, тк происходит проверка по deeplink
	// и lid будет устанавливаться через условия ниже
	// ---
	// Если в sessionStorage есть тестовые значение lid или altDomain
	// то ранее они были установлены в appSettings
	if (__IS_DEV__) {
		if (params?.get('mode') === 'test') {
			// const lid = params?.get('lid') as Lid;

			// if (lid) {
			// 	sessionStorage.setItem(SESSION_STORAGE_TEST_LID, lid);
			// 	appSettings.appLid = lid;
			// }

			const altdomain = params?.get('altdomain') as Altdomain;

			if (altdomain) {
				sessionStorage.setItem(SESSION_STORAGE_TEST_ALTDOMAIN, altdomain);
				location.search = '';
			}
		}
	}

	const checkUtmSource = (utmSource: string) =>
		cookieUtmSource !== utmSource ? setCookie(COOKIE_VIP_UTM_SOURCE, utmSource) : null;

	// Если utm_source передан в get параметрах
	if (utmSource) {
		removeMarker();

		switch (utmSource) {
			case Altdomain.CA:
				checkUtmSource(Altdomain.CA);
				appSettings.appLid = Lid.CITYADS;
				break;

			case Altdomain.CITYADS:
				if (params?.get('click_id')) {
					checkUtmSource(Altdomain.CITYADS);
					setCookie(COOKIE_VIP_CITYADS_CLICK_ID, params?.get('click_id') ?? '');
					appSettings.appLid = Lid.CITYADS;
				}
				break;

			case Altdomain.AVIASALES:
				checkUtmSource(Altdomain.AVIASALES);
				appSettings.appLid = Lid.AVIASALES;

				if (params?.get('data')) {
					setCookie(COOKIE_VIP_META_MARKER, params?.get('data') ?? '');
				}
				break;

			case Altdomain.BILETIX_AVIASALES_KZ:
				checkUtmSource(Altdomain.BILETIX_AVIASALES_KZ);
				appSettings.appLid = Lid.BILETIX_AVIASALES_KZ;

				if (params?.get('data')) {
					setCookie(COOKIE_VIP_META_MARKER, params?.get('data') ?? '');
				}
				break;

			case Altdomain.ACTIONPAY:
				if (params?.get('actionpay')) {
					checkUtmSource(Altdomain.ACTIONPAY);
					setCookie(COOKIE_VIP_ACTIONPAY, params?.get('actionpay') ?? '');
					appSettings.appLid = Lid.ACTIONPAY;
				}
				break;

			case Altdomain.YANDEX:
				checkUtmSource(Altdomain.YANDEX);
				appSettings.appLid = Lid.YANDEX;
				break;

			case Altdomain.YANDEX_RU:
				checkUtmSource(Altdomain.YANDEX_RU);
				appSettings.appLid = Lid.YANDEX_RU;

				if (params?.get('marker')) {
					setCookie(COOKIE_VIP_META_MARKER, params?.get('marker') ?? '');
				}
				break;

			case Altdomain.HOMECREDITBANK:
				checkUtmSource(Altdomain.HOMECREDITBANK);
				appSettings.appLid = Lid.HOMECREDITBANK;
				break;

			case Altdomain.BILETYPLUS:
				checkUtmSource(Altdomain.BILETYPLUS);
				appSettings.appLid = Lid.BILETYPLUS;
				break;

			case Altdomain.RASSILKAES:
				checkUtmSource(Altdomain.RASSILKAES);
				appSettings.appLid = Lid.RASSILKAES;
				break;

			case Altdomain.PORTRETIX:
				checkUtmSource(Altdomain.PORTRETIX);
				appSettings.appLid = Lid.PORTRETIX;
				break;
		}
		// лид ry(rassilkaes) может прейти через utm_medium=Email
	} else if (params?.get('utm_medium') === 'Email') {
		removeMarker();

		checkUtmSource(Altdomain.RASSILKAES);
		appSettings.appLid = Lid.RASSILKAES;

		// Переход из https://biletix.ru/news/posts/ будет содержать frombiletixblogsearch
	} else if (params.toString().includes('frombiletixblogsearch')) {
		removeMarker();

		checkUtmSource(Altdomain.BILETIXBLOG);
		appSettings.appLid = Lid.BILETIXBLOG;

		// Если utm_source не передан в get параметрах но utm_source храниться в куках
	} else if (params.toString().includes('fromaviacity')) {
		removeMarker();

		checkUtmSource(Altdomain.BILETIX_RU_AVIA_CITY);
		appSettings.appLid = Lid.BILETIX_RU_AVIA_CITY;

		// Если utm_source не передан в get параметрах но utm_source храниться в куках
	} else if (cookieUtmSource) {
		appSettings.appLid = MapAltdomain[cookieUtmSource as keyof TMapAltdomain] ?? '';
	}
};
