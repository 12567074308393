import { useSelector } from 'react-redux';

import { PopupError, getPopupErrorCode } from '@features/PopupError';
import { appSettings } from '@shared/appSettings';
import Helper from '@shared/ui/Helper/Helper';
import { SearchForm } from '@widgets/Search';

export const SearchStep = (): JSX.Element => {
	const popupError = useSelector(getPopupErrorCode);

	if (document.body.classList.contains('hidden-content')) {
		document.body.classList.remove('hidden-content');
	}

	return (
		<>
			{popupError.code && <PopupError error={popupError} />}

			<SearchForm />

			{appSettings.appSupport && <Helper />}
		</>
	);
};
