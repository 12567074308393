import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Analytics } from '@app/analytics';
import { AppRouter } from '@app/providers/router';
import { LangSwitcher } from '@features/LangSwitcher';
import { TestPanel } from '@features/TestPanel';
import { ThemeSwitcher, useTheme } from '@features/ThemeSwitcher';
import { isBiletix } from '@shared/const';
import { useLocale } from '@shared/hooks/useLocale';

const App = (): JSX.Element => {
	const { theme } = useTheme();
	const lang = useLocale();
	const { i18n } = useTranslation();

	useEffect(() => {
		i18n.changeLanguage(lang).catch(() => {
			console.log(`Failed to change language to ${lang}`);
		});
	}, [i18n, lang]);

	useEffect(() => {
		if (theme) {
			document.body.setAttribute('data-theme', theme);

			return () => {
				document.body.removeAttribute(theme);
			};
		}
	}, [theme]);

	return (
		<div className='wl-app'>
			{__IS_DEV__ && (
				<div className='test-switch'>
					<ThemeSwitcher />
					<LangSwitcher />
					<TestPanel />
				</div>
			)}

			{!__IS_DEV__ && isBiletix && <Analytics />}

			<Suspense fallback=''>
				<AppRouter />
			</Suspense>
		</div>
	);
};

export default App;
