import { Altdomain } from '../appSettings';
import { getCookie } from '../lib/helpers';
import { COOKIE_VIP_ACTIONPAY, COOKIE_VIP_CITYADS_CLICK_ID, COOKIE_VIP_UTM_SOURCE } from './cookie';

export const isBiletixRU = window.location.host.includes('biletix.ru');
export const isBiletixKZ = window.location.host.includes('biletix.kz');
export const isBiletix = isBiletixRU || isBiletixKZ;

export const isActionpayCookie =
	getCookie(COOKIE_VIP_UTM_SOURCE) === Altdomain.ACTIONPAY && getCookie(COOKIE_VIP_ACTIONPAY);

export const isCityadsCookie =
	(getCookie(COOKIE_VIP_UTM_SOURCE) === Altdomain.CA || getCookie(COOKIE_VIP_UTM_SOURCE) === Altdomain.CITYADS) &&
	getCookie(COOKIE_VIP_CITYADS_CLICK_ID);
