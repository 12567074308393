import { keys, pickBy } from 'lodash';

import { IOffer, ITrip, isRt } from '@entities/Offer';

import { ITransferFilter } from './../../../model/slices/filters/transferSlice/transferSlice';

const filterTrips = (trips: ITrip[], transfers: number[]) => {
	for (const trip of trips) {
		const segmentsLength = trip.segments.length;

		if (transfers.includes(segmentsLength - 1)) {
			return true;
		}
	}

	return false;
};

export const filterTransfers = (offer: IOffer, filter: ITransferFilter) => {
	const transfers = keys(pickBy(filter.transferCounts, Boolean)).map(Number);

	const passTo = filterTrips(offer.grouped_trips[0].trips, transfers);

	if (!isRt(offer)) {
		return passTo;
	}

	const passBack = filterTrips(offer.grouped_trips[1].trips, transfers);

	return passBack || passTo;
};
