import { BILETIX_STATIC, FUTURA_TRAVEL_STATIC, PS_BILETIX_URL } from '../const/api';
import { CurrencyType, ThemeType } from '../types';
import { Altdomain, Lid } from './model/types/i.altdomain';

export interface IAppSettings {
	appAltDomain: Altdomain;
	appTheme: ThemeType;
	appCurrency: CurrencyType;
	appLid: Lid;
	appHost: string;
	appUrl: string;
	appSupport: string;
	appSupportPhone: string;
	appFrom: string;
	appTo: string;
	appRedirect: string;
}

const app = document.getElementById('app-wl-avia');
const attributeFrom = app?.getAttribute('data-from');
const attributeTo = app?.getAttribute('data-to');
const attributeAltDomain = (app?.getAttribute('data-altdomain') || app?.getAttribute('altdomain')) as Altdomain;
const attributeLid = (app?.getAttribute('data-lid') || app?.getAttribute('lid')) as Lid;
const attributeCurrency = (app?.getAttribute('data-currency') || app?.getAttribute('currency')) as CurrencyType;
const attributeHost = app?.getAttribute('data-host');
const attributeTheme = app?.getAttribute('data-theme') as ThemeType;
const attributeButtonsupport = app?.getAttribute('data-buttonsupport') || app?.getAttribute('buttonsupport');
const attributeSupportPhone = app?.getAttribute('data-tel') || app?.getAttribute('tel');
const attributeRedirect = app?.getAttribute('data-redirect');

const appAltDomain =
	attributeAltDomain ??
	(__APP_ALTDOMAIN__ && __APP_ALTDOMAIN__ !== 'None' ? __APP_ALTDOMAIN__ : Altdomain.BILETIX_DESKTOP);
const appLid = attributeLid ?? (__APP_LID__ && __APP_LID__ !== 'None' ? __APP_LID__ : Lid.BILETIX_DESKTOP);
const appCurrency = attributeCurrency ?? (__APP_CURRENCY__ && __APP_CURRENCY__ !== 'None' ? __APP_CURRENCY__ : 'RUR');
const appHost = attributeHost ?? (__APP_ALT_HOST__ && __APP_ALT_HOST__ !== 'None' ? __APP_ALT_HOST__ : PS_BILETIX_URL);
const appUrl = appAltDomain === Altdomain.FUTURA_TRAVEL ? FUTURA_TRAVEL_STATIC : BILETIX_STATIC;
const appTheme = attributeTheme ?? (__APP_LITE_MODE__ && __APP_LITE_MODE__ !== 'None' ? __APP_LITE_MODE__ : 'dark');
const appSupport = attributeButtonsupport ?? '';
const appSupportPhone = attributeSupportPhone ?? '';
const appFrom = attributeFrom ?? '';
const appTo = attributeTo ?? '';
const appRedirect = attributeRedirect ?? '';

// Настройки по умолчанию
export const appSettings: IAppSettings = {
	appAltDomain: appAltDomain,
	appLid: appLid,
	appCurrency: appCurrency,
	appHost: appHost,
	appUrl: appUrl,
	appTheme: appTheme,
	appSupport: appSupport,
	appSupportPhone: appSupportPhone,
	appFrom: appFrom,
	appTo: appTo,
	appRedirect: appRedirect,
};
