import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export type TPrice = [number, number];

export interface IPriceFilter {
	initialPrice: TPrice | null;
	currentPrice: TPrice | null;
}

const initialState: IPriceFilter = {
	currentPrice: null,
	initialPrice: null,
};

export const priceSlice = createSlice({
	name: 'price',
	initialState,
	reducers: {
		setInitialPrice(state, action: PayloadAction<number[]>) {
			const parsed: TPrice = [Math.min(...action.payload), Math.max(...action.payload)];

			state.initialPrice = parsed;
			state.currentPrice = parsed;
		},

		setCurrentPrice(state, action: PayloadAction<TPrice>) {
			state.currentPrice = action.payload;
		},

		reset(state) {
			state.currentPrice = state.initialPrice;
		},
	},
	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			priceSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: priceActions, reducer: priceReducer } = priceSlice;
