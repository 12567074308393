import { orderServicesActions } from '@entities/OrderServices';
import { extraBaggageActions, extraSeatActions } from '@entities/Personal';
import { promocodeActions } from '@entities/Promocode';
import {
	SESSION_STORAGE_DEEPLINK,
	SESSION_STORAGE_HIDDEN_PROMOCODE,
	SESSION_STORAGE_HIDDEN_SERVICE,
	SESSION_STORAGE_IS_BOOK,
	SESSION_STORAGE_IS_RELOAD_PAGE_PAYMENT,
	SESSION_STORAGE_OFFER_BACK,
	SESSION_STORAGE_OFFER_TO,
	SESSION_STORAGE_ORDER_ID,
	SESSION_STORAGE_SBP_PAYMENT_ID,
} from '@shared/const/localstorage';
import { useAppDispatch } from '@shared/hooks/useAppDispatch';

// Если пользователь ранее был на 3-4 шаге, то при возвращении на шаг 2
// необходимо удалить все дополнительные выбранные ранее сервисы, order_id, deeplink.
// Это необходимо, чтобы при выборе нового рейса, услуги старого рейса небыли добавлены

export const useResetData = () => {
	const dispatch = useAppDispatch();
	const resetData = () => {
		dispatch(extraSeatActions.resetExtraSeat());
		dispatch(extraBaggageActions.resetExtraBaggage());
		dispatch(orderServicesActions.resetOrderService());
		dispatch(promocodeActions.resetPromo());
		sessionStorage.removeItem(SESSION_STORAGE_ORDER_ID);
		sessionStorage.removeItem(SESSION_STORAGE_OFFER_TO);
		sessionStorage.removeItem(SESSION_STORAGE_OFFER_BACK);
		sessionStorage.removeItem(SESSION_STORAGE_IS_BOOK);
		sessionStorage.removeItem(SESSION_STORAGE_IS_RELOAD_PAGE_PAYMENT);
		sessionStorage.removeItem(SESSION_STORAGE_HIDDEN_SERVICE);
		sessionStorage.removeItem(SESSION_STORAGE_HIDDEN_PROMOCODE);
		sessionStorage.removeItem(SESSION_STORAGE_DEEPLINK);
		sessionStorage.removeItem(SESSION_STORAGE_SBP_PAYMENT_ID);
	};

	return resetData;
};
