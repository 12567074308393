import { keys, pickBy } from 'lodash';

import { IOffer, isRt } from '@entities/Offer';

import { IAviacompanyFilter } from '../../../model/slices/filters/aviacompanySlice/aviacompanySlice';

export const filterAviacompanies = (offer: IOffer, filter: IAviacompanyFilter) => {
	let pass = false;

	const companies = keys(pickBy(filter.selectedCompanies, Boolean));

	for (const trip of offer.grouped_trips[0].trips) {
		for (const segment of trip.segments) {
			if (companies.includes(segment.airline.name)) {
				pass = true;
			}
		}
	}

	if (isRt(offer)) {
		for (const trip of offer.grouped_trips[1].trips) {
			for (const segment of trip.segments) {
				if (companies.includes(segment.airline.name)) {
					pass = true;
				}
			}
		}
	}

	return pass;
};
