import { memo } from 'react';

import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { IGroupedTrip, ITrip } from '../../model/types/i.offer';
import { BaggageInfo } from '../BaggageInfo/BaggageInfo';
import { Destinations } from '../Destinations/Destinations';
import { ExtraTimeTrip } from '../ExtraTimeTrip/ExtraTimeTrip';
import { Penalty } from '../Penalty/Penalty';
import { Trip } from '../Trip/Trip';
import { TripMobile } from '../TripMobile/TripMobile';
import styles from './GroupedTrips.module.scss';

interface GroupedTripsProps {
	onTripChange: (trip: ITrip) => void;
	isOpen?: boolean;
	groupedTrips: IGroupedTrip;
	activeTrip: ITrip;
	penalty: IPenalty;
	refundBefore: string;
	baggage: IResultBaggageInfo;
	isBack?: boolean;
	isRt: boolean;
}

export const GroupedTrips = memo((props: GroupedTripsProps) => {
	const { isOpen, penalty, baggage, isBack, onTripChange, isRt, groupedTrips, activeTrip, refundBefore } = props;

	const { isMobileAndTablet } = useCustomMediaQuery();

	if (isMobileAndTablet) {
		return (
			<>
				<TripMobile
					isBack={isBack}
					penalty={penalty}
					baggage={baggage}
					trip={activeTrip}
					refundBefore={refundBefore}
				/>

				{groupedTrips.trips.length > 1 && (
					<ExtraTimeTrip activeTrip={activeTrip} onTripClick={onTripChange} groupedTrips={groupedTrips} />
				)}
			</>
		);
	}

	return (
		<>
			{!isOpen && (
				<div className={styles.header}>
					<Penalty penalty={penalty} refundBefore={refundBefore} />
					<BaggageInfo className={styles.baggage} baggage={baggage} />
				</div>
			)}

			{isRt && isOpen && <Destinations className={styles.destinations} isBack={isBack} trip={activeTrip} />}

			<Trip
				baggage={baggage}
				penalty={penalty}
				refundBefore={refundBefore}
				isOpen={isOpen}
				extraTimeTrip={
					groupedTrips.trips.length > 1 ? (
						<ExtraTimeTrip activeTrip={activeTrip} onTripClick={onTripChange} groupedTrips={groupedTrips} />
					) : undefined
				}
				trip={activeTrip}
			/>
		</>
	);
});
