import i18next from 'i18next';

export const timeRangeToString = (secs: [number, number]) => {
	const [start, end] = secs;
	const convertSecsToHHMM = (num: number) => {
		return [Math.floor(num / 3600), Math.floor((num % 3600) / 60)].map((num) => num.toString());
	};

	const time = convertSecsToHHMM(start);
	const time1 = convertSecsToHHMM(end);
	const hours = i18next.t('ch', { ns: 'filters' });
	const minutes = i18next.t('m', { ns: 'filters' });

	const row1 = `${time[0].padStart(2, '0')}${hours} ${time[1].padStart(2, '0')}${minutes}`;
	const row2 = `${time1[0].padStart(2, '0')}${hours} ${time1[1].padStart(2, '0')}${minutes}`;

	return `${row1} - ${row2}`;
};
