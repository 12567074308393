import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { offersListActions } from '../../offerListSlice';

export type TTime = [number, number];

export type TEndpoint = {
	departure: TTime;
	arrival: TTime;
};

export interface IEndpointsFilter {
	initial: {
		to: TEndpoint | null;
		back: TEndpoint | null;
	};
	current: {
		to: TEndpoint | null;
		back: TEndpoint | null;
	};
}

const initialState: IEndpointsFilter = {
	initial: {
		to: null,
		back: null,
	},
	current: {
		to: null,
		back: null,
	},
};

type TInitialPayloadArgs = {
	to: Record<keyof TEndpoint, number[]>;
	back: Record<keyof TEndpoint, number[]> | null;
};

export type TSetCurrentPayload = {
	value: [number, number];
	direction: keyof IEndpointsFilter['current'];
	point: keyof TEndpoint;
};

export const endpointsSlice = createSlice({
	name: 'endpoints',
	initialState,
	reducers: {
		setInitialEndpoints(state, action: PayloadAction<TInitialPayloadArgs>) {
			const { back, to } = action.payload;

			const parsed: IEndpointsFilter['initial'] = {
				to: {
					departure: [Math.min(...to.departure), Math.max(...to.departure)],
					arrival: [Math.min(...to.arrival), Math.max(...to.arrival)],
				},
				back: back
					? {
							departure: [Math.min(...back.departure), Math.max(...back.departure)],
							arrival: [Math.min(...back.arrival), Math.max(...back.arrival)],
					  }
					: null,
			};

			state.initial = parsed;
			state.current = parsed;
		},

		setCurrent(state, action: PayloadAction<TSetCurrentPayload>) {
			const { direction, point, value } = action.payload;

			// Без tmp не работает типизация
			const tmp = state.current[direction];

			if (!tmp) {
				return;
			}

			tmp[point] = value;
		},

		reset(state) {
			state.current = state.initial;
		},
	},

	extraReducers(builder) {
		builder.addCase(offersListActions.resetFilters, (state) => {
			endpointsSlice.caseReducers.reset(state);
		});
	},
});

export const { actions: endpointsActions, reducer: endpointsReducer } = endpointsSlice;
