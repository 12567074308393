import cn from 'classnames';

import { useCallback, useEffect, useRef, useState } from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';

import styles from './ToggledBlock.module.scss';

export type ToggleRenderFunction = ({ isOpen, extraData }: { isOpen?: boolean; extraData?: string }) => JSX.Element;

interface ToggledBlockProps {
	className?: string;
	classNameTrigger?: string;
	renderTrigger: ToggleRenderFunction;
	renderContent: ToggleRenderFunction;
	staticContent?: boolean;
	reversed?: boolean;
	closeOtherToogle?: boolean;
	extraData?: string;
}

export const ToggledBlock = (props: ToggledBlockProps) => {
	const {
		className,
		classNameTrigger,
		renderContent,
		renderTrigger,
		staticContent,
		reversed,
		closeOtherToogle = true,
		extraData,
	} = props;
	const [mounted, setMounted] = useState(false);
	const ref = useRef(null);

	useEffect(() => {
		setMounted(true);
	}, []);

	const closeOtherAccordions = useCallback(() => {
		// Найти все кнопки с открытым состоянием
		const buttons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(
			'button[data-headlessui-state="open"]',
		);

		// Нажать на каждую кнопку, чтобы закрыть, кроме текущей кнопки
		buttons.forEach((button) => {
			if (ref.current !== button) {
				button.click();
			}
		});
	}, []);

	return (
		<Disclosure as='div' className={cn(styles.wrapper, className, { [styles.reversed]: reversed })}>
			{({ open }) => (
				<>
					<DisclosureButton
						ref={ref}
						className={cn(styles.trigger, classNameTrigger)}
						onMouseDown={() => {
							if (mounted && closeOtherToogle) {
								closeOtherAccordions();
							}
						}}
					>
						{renderTrigger({ isOpen: open })}
					</DisclosureButton>

					<DisclosurePanel static={staticContent}>
						{renderContent({ isOpen: open, extraData: extraData })}
					</DisclosurePanel>
				</>
			)}
		</Disclosure>
	);
};
