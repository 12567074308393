import { useTheme } from '@features/ThemeSwitcher';

import { HotBaggageFilter } from './HotBaggageFilter/HotBaggageFilter';
import { HotDarkBaggageFilter } from './HotDarkBaggageFilter/HotDarkBaggageFilter';
import { HotDarkTransferFilter } from './HotDarkTransferFilter/HotDarkTransferFilter';
import { HotEarlyFilter } from './HotEarlyFilter /HotEarlyFilter';
import { HotEveningFilter } from './HotEveningFilter/HotEveningFilter';
import styles from './HotFilters.module.scss';
import { HotReturnableFilter } from './HotReturnableFilter/HotReturnableFilter';

export const HotFilters = () => {
	const { theme } = useTheme();

	if (theme === 'dark' || theme === 'sigma') {
		return (
			<div className={styles.wrapper}>
				<HotDarkBaggageFilter />
				<HotDarkTransferFilter />
			</div>
		);
	}

	return (
		<div className={styles.wrapperLight}>
			<HotBaggageFilter />
			<HotEveningFilter />
			<HotEarlyFilter />
			<HotReturnableFilter />
		</div>
	);
};
