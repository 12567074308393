import cn from 'classnames';

import { memo, useMemo } from 'react';

import { useLocale } from '@shared/hooks/useLocale';
import { useCustomMediaQuery } from '@shared/hooks/useMediaQuery';
import { formatDateByPatternAndLocale } from '@shared/lib/helpers';
import { Aviacompany } from '@shared/ui/Aviacompany/Aviacompany';

import { IResultBaggageInfo } from '../../lib/getBaggage';
import { IPenalty } from '../../lib/getPenalty';
import { ITrip } from '../../model/types/i.offer';
import { Details } from '../Details/Details';
import { TripTrack } from '../TripTrack/TripTrack';
import styles from './Trip.module.scss';

interface TripProps {
	className?: string;
	isOpen?: boolean;
	extraTimeTrip?: JSX.Element;
	trip: ITrip;
	penalty: IPenalty;
	refundBefore: string;
	baggage: IResultBaggageInfo;
}

export const Trip = memo((props: TripProps) => {
	const { className, penalty, extraTimeTrip, baggage, isOpen, trip, refundBefore } = props;

	const locale = useLocale();
	const { isMobileAndTablet } = useCustomMediaQuery();

	const { segmentEnd, segmentStart } = useMemo(
		() => ({
			segmentStart: trip.segments[0],
			segmentEnd: trip.segments.at(-1),
		}),
		[trip],
	);
	return (
		<div className={cn(styles.big, { [styles.tripOpen]: isOpen }, className)}>
			<div className={styles.inner}>
				<Aviacompany airline={{ airlineInfo: segmentStart.airline, airlineNumber: segmentStart.number }} />

				<div className={styles.info}>
					<div className={styles.flightPoint}>
						<p className={styles.time}>{segmentStart.departure_time}</p>
						<p className={styles.city}>{segmentStart.departure.city_name}</p>
						<p className={styles.date}>
							{formatDateByPatternAndLocale(new Date(segmentStart.start), 'dd MMMM yyyy', locale)}
						</p>
					</div>

					<TripTrack className={styles.track} trip={trip} />

					{segmentEnd && (
						<div className={cn(styles.flightPoint, styles.right)}>
							<p className={styles.time}>{segmentEnd.arrival_time}</p>
							<p className={styles.city}>{segmentEnd.arrival.city_name}</p>
							<p className={styles.date}>
								{formatDateByPatternAndLocale(new Date(segmentEnd.end), 'dd MMMM yyyy', locale)}
							</p>
						</div>
					)}
				</div>
			</div>

			{extraTimeTrip}
			{isOpen && !isMobileAndTablet && (
				<Details baggage={baggage} penalty={penalty} trip={trip} refundBefore={refundBefore} />
			)}
		</div>
	);
});
