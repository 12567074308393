import i18next from 'i18next';

import { IOffer, PenaltyName } from '../model/types/i.offer';

type PenaltyText = 'charter' | 'specialСonditions' | PenaltyName;
type PenaltyTip = 'customCharterTip' | 'charterTip' | 'noTooltip';

export type IPenalty = [PenaltyText, PenaltyTip | string] | null;

export const getPenalty = (offer: IOffer, isBack = false): IPenalty => {
	const segment = isBack ? offer.grouped_trips[1].trips[0].segments[0] : offer.grouped_trips[0].trips[0].segments[0];

	if (segment.charter_sign === 'Y') {
		if (segment.airline.vak === '0Ж' && segment.airline.id === 'SU') {
			// Дополнительные условия для чартера (https://jira.vipservice.ru/browse/AWL-679)
			return ['specialСonditions', ''];
		} else if (segment.charter_label) {
			return ['charter', i18next.t('penalty-charter-label', { ns: 'filters' })];
		} else {
			return ['charter', i18next.t('penalty-charter', { ns: 'filters' })];
		}
	}

	// Если не нашли в baggageInfo, то достаем из офера
	const result = offer.penalty as PenaltyText;

	return [result, 'noTooltip'];
};
