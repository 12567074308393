import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AppSettingsProvider } from '@app/providers/AppSettingsProvider';
import { ErrorBoundary } from '@app/providers/ErrorBoundary';
import { StoreProvider } from '@app/providers/StoreProvider';
import '@app/sentry';
import '@app/styles/index.scss';
import { PageError } from '@features/PageError';
import { ThemeProvider } from '@features/ThemeSwitcher';
import '@shared/config/i18n/i18n';

import App from './App';

const container = document.getElementById('app-wl-avia');

async function enableMocking() {
	if (!__APP_MOCK__) {
		return;
	}

	const { worker } = await import('../src/5-shared/mock/browser');

	// `worker.start()` returns a Promise that resolves
	// once the Service Worker is up and ready to intercept requests.
	return worker.start();
}

enableMocking().then(() => {
	if (container !== null) {
		const root = ReactDOMClient.createRoot(container);

		root.render(
			<React.StrictMode>
				<StoreProvider>
					<BrowserRouter>
						<ErrorBoundary fallback={<PageError />}>
							<ThemeProvider>
								<AppSettingsProvider>
									<App />
								</AppSettingsProvider>
							</ThemeProvider>
						</ErrorBoundary>
					</BrowserRouter>
				</StoreProvider>
			</React.StrictMode>,
		);
	}
});
