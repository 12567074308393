import { type FC, type ReactNode, useMemo, useState } from 'react';

import { appSettings } from '@shared/appSettings';
import { LOCAL_STORAGE_THEME_KEY } from '@shared/const/localstorage';
import { ThemeType } from '@shared/types';

import { ThemeContext } from '../lib/ThemeContext';

// Приоритет для выбора темы
// 1) Если в appSettings настроен 'data-theme'
// 2) Если appSettings не настроен, то смотрим LOCAL_STORAGE_THEME_KEY
// 3) Если LOCAL_STORAGE_THEME_KEY не настроен, то назначается тема по умолчанию 'light_theme'
const { appTheme } = appSettings;
const defaultTheme = appTheme
	? appTheme
	: (localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ThemeType) ?? appSettings.appTheme;

interface ThemeProviderProps {
	initialTheme?: ThemeType;
	children: ReactNode;
}

const ThemeProvider: FC<ThemeProviderProps> = ({ initialTheme, children }) => {
	const [theme, setTheme] = useState<ThemeType>(initialTheme ?? defaultTheme);

	const defaultProps = useMemo(
		() => ({
			theme,
			setTheme,
		}),
		[theme],
	);

	return <ThemeContext.Provider value={defaultProps}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
