export const BagDoubleIcon = ({ number }: { number: number | null }) => (
	<svg width='17' height='23' viewBox='0 0 17 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='4.5' y='0.5' width='5' height='3' rx='1.5' fill='white' stroke='#394753' />
		<rect
			x='10.5'
			y='3.5'
			width='15'
			height='10'
			rx='1.5'
			transform='rotate(90 10.5 3.5)'
			fill='white'
			stroke='#394753'
		/>
		<rect x='7.5' y='2.5' width='5' height='3' rx='1.5' fill='white' stroke='#394753' />
		<rect x='6' y='20' width='3' height='3' rx='1.5' fill='#394753' />
		<rect x='11' y='20' width='3' height='3' rx='1.5' fill='#394753' />
		<rect
			x='16.5'
			y='5.5'
			width='15'
			height='13'
			rx='1.5'
			transform='rotate(90 16.5 5.5)'
			fill='white'
			stroke='#394753'
		/>
		<text x='60%' y='60%'>
			{number ?? ''}
		</text>
	</svg>
);
