import cn from 'classnames';

import { ReactNode, memo } from 'react';

import styles from './MobileFilter.module.scss';

interface MobileFilterProps {
	title: string;
	children: ReactNode;
}

export const MobileFilter = memo((props: MobileFilterProps) => {
	const { title, children } = props;
	return (
		<div className={cn(styles.filter)}>
			<p className={styles.title}>{title}</p>
			{children}
		</div>
	);
});
