import cn from 'classnames';
import { debounce, isEmpty } from 'lodash';

import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import SwitchIcon from '@shared/assets/icons/switch.svg?react';
import { useLocale } from '@shared/hooks/useLocale';
import useComponentVisible from '@shared/hooks/useOutsideAlerter';
import { firstCharacterUp } from '@shared/lib/helpers';
import Button from '@shared/ui/Button/Button';
import { TextField } from '@shared/ui/TextField/TextField';

import { useLazyGetCitiesQuery } from '../../api/searchDirectionApi';
import { ICity } from '../../model/types/ISearchDirection';
import { SearchFormType } from '../../model/types/i.searchForm';
import styles from './SearchDirection.module.scss';

interface SearchDirectionProps {
	city: SearchFormType.FROM | SearchFormType.TO;
	nextRef?: SearchFormType.TO | SearchFormType.DATE_FROM;
	label?: string | null;
	switchStyles?: string;
}

export const SearchDirection = ({ city, nextRef, label, switchStyles }: SearchDirectionProps) => {
	const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
	const [getCities, { data: cities = [] }] = useLazyGetCitiesQuery();
	const lang = useLocale();
	const { t } = useTranslation('search');
	const {
		control,
		getValues,
		setValue,
		clearErrors,
		setFocus,
		formState: { errors },
	} = useFormContext();
	const getCitiesDebounce = debounce((city: string, lang: string) => getCities({ q: city, lang }), 300);

	const onFocus = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const q = event.currentTarget?.value.toString() ?? '';
		const currentCity = getValues(city);

		// через split получаем значение введенное пользователем или cityName для отправки на сервер
		// ---
		// q может быть пустой строкой , тогда ''
		// q может быть строкой с value(введенное пользователем) , тогда 'значение'
		// q может cityName = "Москва, MOW"(выбранное из списка), тогда 'Москва'
		const cityName = q?.split(',')[0];
		setIsComponentVisible(false);

		if (q || !currentCity) {
			getCitiesDebounce(cityName, lang);
		}

		setIsComponentVisible(true);
	};

	const onBlur = () => {
		clearErrors(city);
		const citiesData = cities[0];

		if (citiesData) {
			setValue(city, `${citiesData.city_name}, ${citiesData.airport_code}`);
		} else {
			setValue(city, '');
		}
	};

	const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const q = event.currentTarget?.value.toString() ?? '';

		getCitiesDebounce(q, lang);

		if (!isComponentVisible) {
			setIsComponentVisible(true);
		}

		return q;
	};

	const onSuggestionClick = (event: React.MouseEvent<HTMLLIElement>) => {
		update(event.currentTarget.id);
	};

	const onSuggestionKeyDown = (event: React.KeyboardEvent<HTMLLIElement>) => {
		if (event.key === 'Enter') {
			update(event.currentTarget.id);
		}
	};

	const switchDirection = () => {
		const currentCityNameFrom = getValues(SearchFormType.FROM);
		const currentCityNameTo = getValues(SearchFormType.TO);

		setValue(SearchFormType.FROM, currentCityNameTo);
		setValue(SearchFormType.TO, currentCityNameFrom);

		clearErrors(SearchFormType.TO);
		clearErrors(SearchFormType.FROM);
	};

	const update = (id: string) => {
		// id example:
		// MOW - airportCode
		// MOW VKO - airportCodeExtra
		const [airportCode, airportCodeExtra] = id.split(' ');
		const currentCity = cities?.find((el: ICity) => el.airport_code === airportCode);

		if (currentCity) {
			if (airportCodeExtra) {
				setValue(city, `${firstCharacterUp(currentCity.airports[airportCodeExtra])}, ${airportCodeExtra}`);
			} else {
				setValue(city, `${currentCity.city_name}, ${currentCity.airport_code}`);
			}

			clearErrors(city);
			setIsComponentVisible(false);
			if (setFocus && nextRef) {
				setFocus(nextRef);
			}
		}
	};

	const renderCities = (el: ICity) => {
		return (
			<React.Fragment key={el.airport_code}>
				{/* city */}
				<li
					id={el.airport_code}
					onClick={onSuggestionClick}
					onKeyDown={onSuggestionKeyDown}
					className={styles.itemCities}
					role='option'
					aria-selected='false'
					tabIndex={0}
				>
					<div>
						<span className={styles.cityName}>{el.name}</span>
						<span>{`, ${el.parent_city_name}`}</span>
					</div>

					<span className={styles.airportName}>{el.airport_code}</span>
				</li>

				{/* airports */}
				{Object.entries(el.airports)?.map(([airports, value]) => (
					<li
						id={`${el.airport_code} ${airports}`}
						onClick={onSuggestionClick}
						onKeyDown={onSuggestionKeyDown}
						className={styles.itemAirports}
						role='option'
						aria-selected='false'
						tabIndex={0}
						key={airports}
					>
						<div className={styles.itemAirportsWrap}>
							<span className={styles.airportName}>{value.toLowerCase()}</span>
							<div>
								<span className={styles.cityName}>{el.name}</span>
								<span>{`, ${el.parent_city_name}`}</span>
							</div>
						</div>

						<span className={styles.airportName}>{airports}</span>
					</li>
				))}
			</React.Fragment>
		);
	};

	return (
		<div
			className={cn(styles.field, {
				[styles[city]]: true,
			})}
			ref={ref}
		>
			<Controller
				name={city}
				control={control}
				rules={{ required: t('obyazatelnoe-pole') }}
				render={({ field: { name, value, ref, onChange } }) => {
					return (
						<TextField
							theme='transparent'
							ref={ref}
							onBlur={onBlur}
							onChange={(e) => onChange(onChangeSearch(e))}
							name={name}
							label={label}
							onFocus={onFocus}
							errors={errors}
							value={value}
							errorLabelMessage
						/>
					);
				}}
			/>

			{isComponentVisible && !isEmpty(cities) && (
				<ul className={cn(styles.listCities)}>{cities.map((el) => renderCities(el))}</ul>
			)}

			{city === SearchFormType.FROM && (
				<Button
					variant='clear'
					className={cn(styles.btnSwitch, switchStyles)}
					textPosition='center'
					onClick={switchDirection}
				>
					<SwitchIcon />
				</Button>
			)}
		</div>
	);
};
