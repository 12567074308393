import i18next from 'i18next';
import { isArray } from 'lodash';

import { IOffer } from '../model/types/i.offer';

interface IBaggage {
	kgs: number | null;
	desc: string;
	places: null;
}

interface IBaggageWithPlaces {
	kgs: number | null;
	desc: string;
	places: number;
}

type IResultBaggageInfoKey = 'handLuggageKg' | 'handLuggagePlaces' | 'baggageKg' | 'baggagePlaces';
type IResultBaggageValue = IBaggageWithPlaces | IBaggage;

export type IResultBaggageInfo = Record<IResultBaggageInfoKey, IResultBaggageValue | null>;
const transformHtmlSymbol = (str: string) => {
	return str?.replace(/&#215;/g, 'x');
};

export const getBaggage = (offer: IOffer, isBack = false) => {
	if (isReturnFromSIG(offer, isBack)) {
		return returnBagFromSIG(offer, isBack);
	}
	const bagInfo: IResultBaggageInfo = {
		handLuggageKg: null,
		handLuggagePlaces: null,
		baggageKg: null,
		baggagePlaces: null,
	};

	const baggage = isBack ? offer.baggage_info_revert_trip : offer.baggage_info;
	const info = isArray(baggage) ? baggage[0] : baggage;
	const regex = /(мест|места)/i;

	for (const key in info) {
		const lowerKey = key.toLowerCase();
		if (lowerKey.includes('кладь')) {
			if (regex.test(lowerKey)) {
				bagInfo.handLuggagePlaces = {
					places: getPlacefromStr(key),
					desc: transformHtmlSymbol(key),
					kgs: getKgfromStr(key),
				};
			} else {
				bagInfo.handLuggageKg = {
					kgs: getKgfromStr(key),
					desc: transformHtmlSymbol(key),
					places: null,
				};
			}
		}

		if (lowerKey.includes('багаж')) {
			if (info[key] === 'free') {
				if (regex.test(lowerKey)) {
					bagInfo.baggagePlaces = {
						desc: key,
						kgs: getKgfromStr(key),
						places: getPlacefromStr(key),
					};
				} else {
					bagInfo.baggageKg = {
						kgs: getKgfromStr(key),
						desc: key,
						places: null,
					};
				}
			}
		}
	}
	return bagInfo;
};

// достаем багаж из первого сегмента
const returnBagFromSIG = (offer: IOffer, isBack: boolean) => {
	const baggageInfo: IResultBaggageInfo = {
		handLuggageKg: null,
		handLuggagePlaces: null,
		baggageKg: null,
		baggagePlaces: null,
	};

	const baggage = isBack ? offer.baggage_info_revert_trip : offer.baggage_info;
	const info = isArray(baggage) ? baggage[0] : baggage;

	const bagSegment = isBack
		? offer.grouped_trips[1].trips[0].segments[0]
		: offer.grouped_trips[0].trips[0].segments[0];

	if (info) {
		for (const key in info) {
			if (key.includes('чная')) {
				baggageInfo.handLuggageKg = {
					places: null,
					desc: transformHtmlSymbol(key),
					kgs: getKgfromStr(key),
				};
			}
		}
	}

	if (Number(bagSegment.bagallowance)) {
		if (bagSegment.bagtype === 'PC') {
			const number = Number(bagSegment.bagallowance);
			baggageInfo.baggagePlaces = {
				places: number,
				desc: i18next.t('bagazh-mesto', { ns: 'filters', number: number }),
				kgs: null,
			};
		}

		if (bagSegment.bagtype === 'KG') {
			const number = Number(bagSegment.bagallowance);
			baggageInfo.baggageKg = {
				places: null,
				desc: i18next.t('bagazh-number', { ns: 'filters', number: number }),
				kgs: number,
			};
		}
	}

	return baggageInfo;
};

const getKgfromStr = (str: string): number | null => {
	const splited = str.split(' ');
	const kgIndex = splited.findIndex((el) => el === 'кг');

	if (kgIndex === -1) {
		return null;
	}

	return Number(splited[kgIndex - 1]);
};

const getPlacefromStr = (str: string): number => {
	const splited = str.split(' ');
	const regex = /(мест|места|место)/i;
	const placeIndex = splited.findIndex((el) => regex.test(el.toLowerCase()));

	return Number(splited[placeIndex - 1]);
};

const isReturnFromSIG = (offer: IOffer, isBack: boolean) => {
	if (offer.charter_sign === 'Y') {
		return true;
	}

	const baggageInfo = isBack ? offer.baggage_info_revert_trip : offer.baggage_info;
	const baggage = isArray(baggageInfo) ? baggageInfo[0] : baggageInfo;

	if (!baggage) return true;

	if ('Багаж' in baggage && !baggage['Багаж']) {
		return true;
	}

	if (!Object.keys(baggage).join('').includes('агаж')) {
		return true;
	}

	let bagNull = false;

	for (const key in baggage) {
		if (key.includes('агаж') && !baggage[key]) {
			bagNull = true;
		}
	}

	return bagNull;
};
