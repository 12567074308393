import cn from 'classnames';

import { memo } from 'react';

import styles from './Trigger.module.scss';

interface TriggerProps {
	isOpen?: boolean;
	text: string;
}

export const Trigger = memo((props: TriggerProps) => {
	const { isOpen, text } = props;
	return (
		<span className={cn(styles.trigger, { [styles.opened]: isOpen })}>
			<span className={styles.arrow} />
			<span className={styles.text}>{text}</span>
		</span>
	);
});
