import { useCallback, useState } from 'react';

import { ObserverOptions, useMutationObserver } from './useMutationObserver';

export const useLocale = (): string => {
	const [lang, setLang] = useState(document.documentElement.lang);

	// Изменяет lang для html устанавливая значение из LS
	// useEffect(() => {
	// 	if (localStorage.getItem(LOCAL_STORAGE_LANG)) {
	// 		document.documentElement.lang = localStorage.getItem(LOCAL_STORAGE_LANG) as string;
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const options: ObserverOptions = {
		attributes: true,
		callback: useCallback(
			(mutationList) => {
				mutationList.forEach((mutation) => {
					if (mutation.type !== 'attributes' || mutation.attributeName !== 'lang') return;

					setLang(document.documentElement.lang);
				});
			},
			[setLang],
		),
	};

	useMutationObserver('html', options, options.callback);

	return lang;
};
