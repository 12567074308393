import cn from 'classnames';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { ITrip } from '../../model/types/i.offer';
import styles from './Destinations.module.scss';
import PlaneIcon from './plane.svg?react';

interface DestinationsProps {
	className?: string;
	trip: ITrip;
	isBack?: boolean;
	withoutDestinationWord?: boolean;
}

export const Destinations = memo((props: DestinationsProps) => {
	const { className, trip, withoutDestinationWord, isBack } = props;
	const { t } = useTranslation('filters');
	const segmentStart = trip.segments[0];
	const segmentEnd = trip.segments.at(-1);

	return (
		<div className={cn(styles.destinations, className)}>
			<PlaneIcon className={cn(styles.plane, { [styles.backPlane]: isBack })} />
			<p className={styles.text}>
				{!withoutDestinationWord && <>{isBack ? `${t('obratno')}: ` : `${t('tuda')}: `}</>}
				{segmentStart.departure.city_name || segmentStart.departure.id} -{' '}
				{segmentEnd?.arrival.city_name || segmentEnd?.arrival.id}
			</p>
		</div>
	);
});

Destinations.displayName = 'Destinations';
