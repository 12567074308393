import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

export const sentryTrace = (tag: string, capture: unknown, transactionName?: string, level?: SeverityLevel) => {
	return Sentry.withScope(function (scope) {
		scope.setTag(tag, true);
		scope.setTransactionName(transactionName ?? 'unknown');
		scope.setLevel(level ?? 'error');
		Sentry.captureException(capture);
	});
};
