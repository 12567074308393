import { keys, pickBy } from 'lodash';

import { IOffer, ITrip } from '@entities/Offer';

import {
	IStateAirportsFilter,
	TStateDirectionVariants,
} from './../../../model/slices/filters/airportsFilter/airportsFilter';

const filterPoint = (direction: TStateDirectionVariants) => {
	return keys(pickBy(direction, Boolean));
};

type FilterDirectionArgs = {
	arrivalList: string[];
	departureList: string[];
	transfersAirports: TStateDirectionVariants;
	trips: ITrip[];
};

export const filterAirports = (offer: IOffer, filter: IStateAirportsFilter) => {
	const { departure, arrival, transfers } = filter;

	const departureListTo = filterPoint(departure.to);
	const arrivalListTo = filterPoint(arrival.to);
	const transfersListTo = transfers.airports;

	const passTo = filterDirection({
		arrivalList: arrivalListTo,
		departureList: departureListTo,
		transfersAirports: transfersListTo,
		trips: offer.grouped_trips[0].trips,
	});

	// Если обратного рейса нет
	if (!arrival.back || !departure.back) {
		return passTo;
	}

	const arrivalListBack = filterPoint(arrival.back);
	const departureListBack = filterPoint(departure.back);
	const transfersListBack = transfers.airports;

	const passBack = filterDirection({
		arrivalList: arrivalListBack,
		departureList: departureListBack,
		transfersAirports: transfersListBack,
		trips: offer.grouped_trips[1].trips,
	});

	return passTo || passBack;
};

const filterDirection = (params: FilterDirectionArgs) => {
	const { arrivalList, departureList, transfersAirports, trips } = params;
	const transfersList = filterPoint(transfersAirports);
	const isTransferActive = transfersAirports['all'];

	for (const trip of trips) {
		const firstSegment = trip.segments[0];
		const lastSegment = trip.segments.at(-1);

		const passPoints =
			departureList.includes(firstSegment.departure.city) &&
			arrivalList.includes(lastSegment?.arrival.city ?? '');

		// Если нет транзита
		if (isTransferActive) {
			if (passPoints) {
				return true;
			}

			return false;
		}

		if (!isTransferActive) {
			for (const [index, segment] of trip.segments.entries()) {
				// Первый элемент не содержит транзитного рейса
				if (index == 0) {
					continue;
				}

				const hasInList = transfersList.includes(segment.departure.city);

				if (hasInList) {
					return true;
				}
			}
		}
	}

	return false;
};
