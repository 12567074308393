/* eslint-disable */
import { IOffersResp, ISelectOfferResp } from '@entities/Offer';
import { ISearchParams } from '@entities/Search';
import { isBiletix } from '@shared/const';

import { ITwiketOrderInfo } from '../types/i.twiket-order-info';

// Шаг1: поиск (кнопка Найти)
export const setDataLayerSearch = () => {
	if (isBiletix && !__IS_DEV__) {
		try {
			window.dataLayer?.push({
				event: 'virtualPageView',
				virtualUrl: '/avia/search/',
			});
		} catch (e) {
			console.log('Error in DataLayerSearch');
		}
	}
};

// Шаг2: результат поиска
export const setDataLayerSuccessSearch = (data: IOffersResp) => {
	if (isBiletix && !__IS_DEV__) {
		try {
			const iatafrom = data.data[0].grouped_trips[0].trips[0].segments[0].departure.id;
			const datefrom = data.data[0].grouped_trips[0].trips[0].segments[0].start;
			const iatatto = data.data[0].grouped_trips[0].trips[0].segments.at(-1)?.arrival.id ?? '';
			const virtualUrl = Object.assign({ virtualUrl: `/avia/searchresults/${iatafrom}-${iatatto}` });

			window.dataLayer?.push({
				event: 'virtualPageView',
				...virtualUrl,
			});
		} catch (e) {
			console.log('Error in DataLayerSuccessSearch');
		}
	}
};

// Шаг2: выбор рейса
export const setDataLayerClickOffer = () => {
	if (isBiletix && !__IS_DEV__) {
		try {
			window.dataLayer?.push({
				event: 'virtualPageView',
				virtualUrl: '/avia/wait1/',
			});
		} catch (e) {
			console.log('Error in setDataLayerClickOffer');
		}
	}
};

// Шаг3: получение данных о выбранном рейсе
export const setDataLayerFlightDetails = (data: ISelectOfferResp) => {
	if (isBiletix && !__IS_DEV__) {
		try {
			const { adults, infants, children, segments_to, segments_from } = data;
			const cityFrom = segments_to[0].departure.city_name;
			const cityTo = segments_to[0].arrival.city_name;
			const iataFrom = segments_to[0].departure.id;
			const iataTo = segments_to[0].arrival.id;
			const dateTo = segments_to[0]?.start?.split('T')[0];
			const dateBack = segments_from[0]?.start?.split('T')[0];
			const ak = segments_to[0].airline.oak;
			const serviceClass = segments_to[0]?.cabin.id === 'ECONOMY' ? 'э' : 'б';

			const commonData = {
				departCity: cityFrom,
				arrCity: cityTo,
				dateTo: dateTo,
				dateBack: dateBack ?? '',
				version: '2',
				flight_depdate: `${cityFrom}/${adults}-${children}-${infants}`,
				flight_destid: iataFrom,
				flight_originid: iataTo,
				flight_adults: adults,
				flight_childs: children,
				flight_infants: infants,
				flight_class: serviceClass,
			};

			const virtualUrl = Object.assign(
				{
					virtualUrl: `/avia/select/${iataFrom}-${iataTo}/${dateTo}/${adults}-${children}-${infants}`,
					order_amount: Number(data.total_price),
					order_id: '',
					ecommerce: {
						detail: {
							products: [
								{
									id: null,
									name: `${cityFrom} - ${cityTo}`,
									price: data.total_price,
									brand: ak,
									// category: '����������',
									variant: `/avia/select/${iataFrom}-${iataTo}/${dateTo}/${adults}-${children}-${infants}`,
								},
							],
						},
					},
				},
				commonData,
			);

			window.dataLayer?.push({
				event: 'virtualPageView',
				...virtualUrl,
			});
		} catch (e) {
			console.log('Error in setDataLayerFlightDetails');
		}
	}
};

// Шаг3: переход на шаг оплаты
export const setDataLayerGoToPayment = () => {
	if (isBiletix && !__IS_DEV__) {
		try {
			// const { dateBack } = commonData;

			const virtualUrl = Object.assign(
				{
					virtualUrl: '/avia/wait2/',
					// order_id: '',
					// order_amount: Number(data.total_price),
					// flight_returndate: dateBack ? dateBack : '',
				},
				// commonData,
			);

			window.dataLayer?.push({
				event: 'virtualPageView',
				...virtualUrl,
			});
		} catch (e) {
			console.log('Error in setDataLayerGoToPayment');
		}
	}
};

// Шаг4: загрузка всех данных для шага Оплаты
export const setDataLayerPaymentPageLoaded = (orderId: string, orderPrice?: string, search?: ISearchParams) => {
	if (isBiletix && !__IS_DEV__) {
		try {
			const virtualUrl = Object.assign(
				{
					virtualUrl: `/avia/pay/${search?.from}-${search?.to}/${
						search?.date_to ? `${search?.date_to}/` : ''
					}${search?.adults}-${search?.children}-${search?.infants}`,
					order_amount: orderPrice,
					order_id: orderId,
					flight_returndate: search?.date_from ? search.date_from : '',
					ecommerce: {
						add: {
							products: [
								{
									id: orderId,
									name: `${search?.from}-${search?.to}`,
									// brand: '',
									// category: '����������',
									virtualUrl: `/avia/pay/${search?.from}-${search?.to}/${
										search?.date_to ? `${search?.date_to}/` : ''
									}${search?.adults}-${search?.children}-${search?.infants}`,
									// quantity: 1,
								},
							],
						},
					},
				},
				// commonData,
			);

			window.dataLayer?.push({
				event: 'virtualPageView',
				...virtualUrl,
			});
		} catch (e) {
			console.log('Error in setDataLayerPaymentPageLoaded');
		}
	}
};

// Финиш
export const setDataLayerFinish = (orderId: string, orderInfo: ITwiketOrderInfo) => {
	if (isBiletix && !__IS_DEV__) {
		try {
			const datefrom = orderInfo.ORDER_SEGMENTS[0].DEPTIME.split(' ')[0];
			const adults = Object.values(orderInfo.ORDER_TICKETS || {}).filter(
				(ticket) => ticket.passenger.TYPE === 'adult',
			).length;
			const children = Object.values(orderInfo.ORDER_TICKETS || {}).filter(
				(ticket) => ticket.passenger.TYPE === 'child',
			).length;
			const infants = Object.values(orderInfo.ORDER_TICKETS || {}).filter(
				(ticket) => ticket.passenger.TYPE === 'infant',
			).length;
			const iatafrom = orderInfo.ORDER_ARRAY.DEPCITY_ARRAY.IATACODE;
			const iatatto = orderInfo.ORDER_ARRAY.ARRCITY_ARRAY.IATACODE;
			const totlaprice = parseInt(orderInfo.ORDER_ARRAY.PRICE);

			const data = {
				event: 'virtualPageView',
				virtualUrl:
					'/avia/done/' +
					iatafrom +
					'-' +
					iatatto +
					'/' +
					datefrom +
					'/' +
					adults +
					'-' +
					children +
					'-' +
					infants,
				order_amount: totlaprice + '',
				order_id: orderId,
				order_tickets: '1',
				transactionShipping: '0',
				transactionAffiliation: 'nb',
				transactionTotal: totlaprice,
				eventlabel: orderInfo.ORDER_ARRAY.PAY_SYSTEM_ID,
				transactionTax: totlaprice,
				transactionId: orderId,
				transactionProducts: [],

				version: '2',
				departCity: orderInfo.ORDER_ARRAY.DEPCITY,
				arrCity: orderInfo.ORDER_ARRAY.ARRCITY,
				dateTo: datefrom + '/' + adults + '-' + children + '-' + infants,
				flight_depdate: datefrom + '/' + adults + '-' + children + '-' + infants,
				adult: adults,
				flight_destid: iatafrom,
				flight_originid: iatatto,
				flight_adults: adults,
				flight_childs: children,
				flight_infants: infants,
				flight_class: orderInfo.ORDER_SEGMENTS[0].CLASS,
			};

			window.dataLayer?.push({
				...data,
			});
		} catch (e) {
			console.log('Error in setDataLayerFinish');
		}
	}
};
